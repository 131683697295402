import { useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import {
  PutTomogramDeformationProps,
  tomogramDeformation,
} from '../helpers/tomogramAPI';

export const useTomogramDeformation = () => {
  const [tomogramDeformationData, setTomogramDeformation] =
    useState<string>('');

  const {
    mutate: callPutTomogramDeformation,
    isLoading: isTomogramDeformationLoading,
    data: putTomogramDeformationResponse,
    error: putTomogramDeformationError,
  } = useMutation(tomogramDeformation);

  const deformation = useCallback(
    ({
      sliceDescription,
      slicePoints,
      tomogramPoints,
      tomogramUrl,
      tomogramNorthPoint,
    }: PutTomogramDeformationProps) => {
      setTomogramDeformation('');
      callPutTomogramDeformation(
        {
          sliceDescription,
          slicePoints,
          tomogramPoints,
          tomogramUrl,
          tomogramNorthPoint,
        },
        {
          onSuccess: ({ data }) => setTomogramDeformation(JSON.parse(data).url),
        },
      );
    },
    [callPutTomogramDeformation],
  );

  return {
    deformation,
    isTomogramDeformationLoading,
    putTomogramDeformationResponse,
    putTomogramDeformationError,
    tomogramDeformationData,
  };
};
