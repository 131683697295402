import { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { css } from 'aphrodite';
import HeaderIcon from '../forgotPassword/HeaderIcon';
import { styles } from '../forgotPassword/ForgotPassword.styles';
import { useCurrentLanguage } from '../../translations/languageSelector';
import { useDispatch } from 'react-redux';
import { logOut } from '../../store/general/actions';

export const ConfirmEmail = () => {
  const activeLang = useCurrentLanguage();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logOut());
  }, [dispatch]);

  return (
    <Grid container className={css(styles.container)}>
      <Grid className={css(styles.innerContainer)}>
        <Grid>
          <HeaderIcon
            className={css(styles.iconWrap)}
            iconClassName={css(styles.icon)}
          />
          <h4 className={css(styles.heading)}>
            {activeLang.confirmEmail.heading}
          </h4>
        </Grid>
      </Grid>
    </Grid>
  );
};
