export interface DefaultMapValues {
  zoom: number;
  latitude: number;
  longitude: number;
}

// Czech republic as default coordinates
export const defaultValues: DefaultMapValues = {
  zoom: 8,
  latitude: 48.971527,
  longitude: 15.898574,
};
