import { FC, useEffect, useMemo, useState } from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from 'antd';
import { LoadingState } from '../../interfaces/enums';

type AppSnackbarProps = {
  description?: string;
  requestState?: LoadingState;
  message?: string;
  error?: boolean;
  type: 'error' | 'success';
};

export const AppSnackbar: FC<AppSnackbarProps> = ({
  description,
  requestState,
  message,
  type,
}) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (requestState === LoadingState.Success) {
      setIsSuccess(true);
    }

    if (requestState === LoadingState.Failure) {
      setIsError(true);
    }
  }, [requestState]);

  const memoOpen = useMemo(() => {
    return type === 'error' ? isError : isSuccess;
  }, [type, isError, isSuccess]);

  const handleClose = () => {
    if (type === 'error') setIsError(false);
    if (type === 'success') setIsSuccess(false);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={memoOpen}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert message={message} description={description} type={type} showIcon />
    </Snackbar>
  );
};

export const AppErrorSnackbar: FC<AppSnackbarProps> = ({
  description,
  message,
  type,
  error = false,
}) => {
  const [isError, setIsError] = useState(error);

  useEffect(() => {
    if (error) {
      setIsError(error);
    }
  }, [error]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={isError}
      autoHideDuration={5000}
      onClose={() => setIsError(false)}
    >
      <Alert message={message} description={description} type={type} showIcon />
    </Snackbar>
  );
};
