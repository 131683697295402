import { StyleSheet } from 'aphrodite';
import { colors } from '../../../styles/colors';

const imageHeight = 330;

export const styles = StyleSheet.create({
  contentContainer: {
    marginTop: 24,
  },
  photosContainer: {
    maxWidth: '100%',
    overflowX: 'auto',
    display: 'flex',
  },
  singlePhotoContainer: {
    margin: '0 1em',
    display: 'flex',
    flexDirection: 'column',
  },
  photo: {
    height: '30vh',
    width: 'auto',
    display: 'block',
  },
  activePhoto: {
    width: '100%',
    height: '100%',
  },
  photoDeleteButton: {
    marginTop: '1em',
  },
  activePhotoCard: {
    marginTop: '2em',
    padding: '1em',
    minHeight: '8em',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    maxWidth: '500px',
  },
  submitBtn: {},
  scrollbar: {
    overflowY: 'auto',
    flex: 1,
    maxWidth: '100%',
    position: 'relative',
    overflowX: 'hidden',
    minHeight: imageHeight,
  },
  copyWrap: {
    width: 700,
    height: 700,
    display: 'flex',
    alignItems: 'flex-start',
  },
  copy: {
    maxHeight: '100%',
    maxWidth: '100%',
  },
  refuseButton: {
    backgroundColor: colors.redPrimary,
    color: colors.white,
    marginRight: 10,
  },
  buttonsWrap: { marginTop: 20 },
  modalBody: {
    backgroundColor: 'white',
    minWidth: 300,
    borderRadius: 8,
    padding: 20,
  },
  modal: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
});
