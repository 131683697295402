import { colors } from './colors';
import { stylingVariables } from './variables';

export const tableStylingMixin = {
  background: colors.white,
  boxShadow: stylingVariables.defaultElevation,
  padding: '10px 30px',
  border: 10,
};

export const bodyPadding = {
  padding: '18px 30px 18px 30px',
};
