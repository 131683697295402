import { TomogramImageProps } from '../components/treeDetailPage/Tomograms/TomogramsPreview';
import useImage from 'use-image';

export const useTomogramImages = (
  activeTomogram: TomogramImageProps | undefined,
  trunkSlice: string,
) => {
  const [image] = useImage(
    (() => {
      if (activeTomogram?.image && typeof activeTomogram.image === 'string') {
        return activeTomogram.image;
      }

      return '';
    })(),
  );

  const [sliceImage] = useImage(
    (() => {
      if (trunkSlice) {
        return trunkSlice;
      }

      return '';
    })(),
  );

  return {
    image,
    sliceImage,
  };
};
