import axios from 'axios';
import { ErrorType } from '../hooks/useSendData/types';

export const parseError = (error: unknown): ErrorType => {
  if (axios.isAxiosError(error)) {
    const errorData = error.response?.data;
    const code = error.response?.status || 404;
    if (typeof errorData !== 'string') {
      if (errorData?.message && errorData.message[0].messages) {
        let err: string = '';
        errorData.message[0].messages.forEach((el: any) => {
          err += `${el.message}\n`;
        });
        return { message: err, code };
      }
    } else {
      return { message: errorData, code };
    }
  }
  return { message: 'Něco se nepovedlo', code: 404 };
};
