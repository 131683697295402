import {
  Container,
  Typography,
  List,
  ListItem,
  Divider,
  Grid,
} from '@material-ui/core';
import { css, StyleSheet } from 'aphrodite';
import { useQuery } from 'react-query';
import format from 'date-fns/format';
import { Header } from '../../components/shared/Header';
import { NoDataTab } from '../../components/treeDetailPageNew/components/NoDataTab/NoDataTab';
import { instance } from '../../helpers/apiHelper';
import { colors } from '../../styles/colors';
import { useCurrentLanguage } from '../../translations/languageSelector';
import { useCurrentUser } from '../../hooks/useCurrentUser';

type Order = {
  id: number;
  status: string;
  createdAt: string;
};

const getFinished = async () => {
  const { data } = await instance.get('tree-scanner-data/processed');
  return data.map((order: Order) => ({
    id: order.id,
    status: order.status,
    createdAt: order.createdAt,
  }));
};

export const FinishedOrderListPage = () => {
  const { data: finishedOrders } = useQuery(['finishedOrders'], getFinished);
  const loggedUser = useCurrentUser();
  const activeLang = useCurrentLanguage();

  return (
    <>
      <Header
        loggedUser={loggedUser}
        breadcrumbs={[
          {
            label: activeLang.summaryPage.headerTitle,
            link: '/',
          },
        ]}
      />
      {finishedOrders ? (
        <Container maxWidth="md" style={{ marginTop: 30 }}>
          <Typography variant="h5">{activeLang.finished.orders}</Typography>

          <List>
            {finishedOrders.map((order: Order) => (
              <Grid key={order.id}>
                <ListItem
                  button
                  component="a"
                  href={`/finished/${order.id}`}
                  className={css(styles.order)}
                >
                  <Grid container justifyContent="space-between">
                    <Grid item className={css(styles.info)}>
                      <Typography>{order.id}</Typography>
                      <Typography className={css(styles.date)}>
                        {format(new Date(order.createdAt), 'dd.MM.YYY')}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography>{order.status}</Typography>
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider />
              </Grid>
            ))}
          </List>
        </Container>
      ) : (
        <NoDataTab title="No orders yet" />
      )}
    </>
  );
};

export const styles = StyleSheet.create({
  order: {
    ':hover': {
      color: colors.greenPrimary,
    },
  },
  date: {
    fontWeight: 200,
    color: '#737373',
  },
  info: {
    display: 'flex',
    columnGap: '25px',
  },
});
