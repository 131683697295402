import { StyleSheet } from 'aphrodite';
import createTheme from '@material-ui/core/styles/createTheme';

export const MENU_HEIGHT = 54;
export const LOGO_HEADER_HEIGHT = 32;

export const stylingVariables = {
  defaultElevation: '0 3px 2px rgba(0,0,0,0.2)',
};

export const fontsWeights = StyleSheet.create({
  bold: {
    fontWeight: 600,
  },
  normal: {
    fontWeight: 400,
  },
  hardBold: {
    fontWeight: 700,
  },
});

export const fontSize = StyleSheet.create({
  caption: {
    fontSize: 12,
  },
});

export const projectTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1100,
      lg: 1280,
      xl: 1920,
    },
  },
});
