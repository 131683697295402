import { Button, CircularProgress, makeStyles, Theme } from '@material-ui/core';
import { colors } from '../../../styles/colors';

export interface StyleProps {
  isButtonError: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  selectButton: {
    marginTop: '1em',
    width: '100%',
    backgroundColor: ({ isButtonError }) =>
      `${isButtonError ? colors.redPrimary : colors.greenPrimary}`,
    color: `${colors.white}`,
  },
}));

interface TomogramButtonProps {
  onClick: any;
  title: string;
  disabled?: boolean;
  loading?: boolean;
  error?: unknown;
}

export const TomogramButton = ({
  onClick,
  title,
  disabled,
  loading,
  error,
}: TomogramButtonProps) => {
  const stylesProps = {
    isButtonError: Boolean(error),
  };
  const classes = useStyles(stylesProps);
  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <Button
          variant="contained"
          className={classes.selectButton}
          onClick={() => onClick()}
          disabled={disabled}
        >
          <div>{title}</div>
        </Button>
      )}
    </>
  );
};
