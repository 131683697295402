import { createTheme } from '@material-ui/core/styles';
import { buttonsColor } from './colors';

export const defaultTheme = createTheme({
  palette: {
    primary: buttonsColor.defaultButton,
    secondary: buttonsColor.secondaryButton,
    error: buttonsColor.error,

    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});
