import { combineReducers } from 'redux';

import * as trees from './trees/reducers';
import * as projects from './projects/reducers';
import * as general from './general/reducers';

export interface State {
  trees: trees.State;
  projects: projects.State;
  general: general.State;
}

export const initialState: State = {
  trees: trees.initialState,
  projects: projects.initialState,
  general: general.initialState,
};

export const reducer = combineReducers<State>({
  trees: trees.treeReducer,
  projects: projects.projectReducer,
  general: general.generalReducer,
});
