import { StyleSheet } from 'aphrodite';
import { colors } from '../../../../styles/colors';

export const styles = StyleSheet.create({
  container: { display: 'flex' },
  editorContainer: {
    padding: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  editor: {
    width: '700px',
    height: '700px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  editorButtonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    maxWidth: '700px',
  },
  brightWrap: {
    display: 'flex',
    width: 300,
    alignItems: 'center',
    padding: '0 10px',
    border: `1px solid ${colors.greenPrimary}`,
    borderRadius: 2,
  },
  brightIcon: {
    color: colors.greenPrimary,
  },
  brightSlider: {
    width: 250,
  },
  btn: {
    marginRight: 4,
  },
  photoForm: {
    marginLeft: '2em',
    marginTop: '3em',
  },
  photoFormItem: {
    minWidth: '16em',
  },
});
