import { colors } from './colors';

export const trunkPhotoLineConfig: google.maps.PolylineOptions = {
  strokeColor: colors.mapElements.typeTrunk,
  strokeOpacity: 1,
  strokeWeight: 1,
};

export const crownPhotoLineConfig: google.maps.PolylineOptions = {
  strokeColor: colors.mapElements.typeCrown,
  strokeOpacity: 1,
  strokeWeight: 1,
};

export const loadLineConfig: google.maps.PolylineOptions = {
  strokeColor: colors.mapElements.loadDirection,
  strokeOpacity: 1,
  strokeWeight: 1,
};

export const structureLineConfig: google.maps.PolylineOptions = {
  strokeColor: colors.mapElements.structureLine,
  strokeOpacity: 1,
  strokeWeight: 1,
};

export const treePointConfig: google.maps.CircleOptions = {
  fillColor: colors.greenPrimary,
  fillOpacity: 1,
  radius: 0.5,
  strokeOpacity: 0,
};

export const structurePointConfig: google.maps.CircleOptions = {
  fillColor: colors.mapElements.structurePoint,
  fillOpacity: 1,
  radius: 0.5,
  strokeOpacity: 0,
};

export const loadPointConfig: google.maps.CircleOptions = {
  fillColor: colors.mapElements.loadDirectionPoint,
  fillOpacity: 1,
  radius: 0.5,
  strokeOpacity: 0,
};

export const trunkPointConfig: google.maps.CircleOptions = {
  fillColor: colors.mapElements.trunkPoint,
  fillOpacity: 1,
  radius: 0.5,
  strokeOpacity: 0,
};

export const crownPointConfig: google.maps.CircleOptions = {
  fillColor: colors.mapElements.crownPoint,
  fillOpacity: 1,
  radius: 0.5,
  strokeOpacity: 0,
};
