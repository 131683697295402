import bgImage from '../../assets/images/background.jpg';
import { StyleSheet } from 'aphrodite';
import { colors } from '../../styles/colors';

export const styles = StyleSheet.create({
  container: {
    background: `url(${bgImage}) no-repeat fixed center`,
    backgroundSize: 'cover',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
  },
  formContainer: {
    width: '40%',
    height: '40%',
    marginTop: '12%',
  },
  form: {
    backgroundColor: colors.containerBackground,
    borderRadius: '0px 0px 10px 10px',
    padding: '30px 70px 30px 70px',
  },
  radius: {
    borderRadius: '10px 10px 0px 0px',
  },
  icon: {
    color: 'rgba(0,0,0,.25)',
  },
});
