import { Fragment, FC } from 'react';
import { useRole } from '../../../../hooks/useRole';
import { ErrorType } from '../../../../hooks/useSendData/types';
import { colors } from '../../../../styles/colors';
import { useCurrentLanguage } from '../../../../translations/languageSelector';
import AppAlert from '../Form/AppAlert/AppAlert';

type TreeDetailFormAlertsProps = {
  isRefuseError: boolean;
  isRefuseData: boolean;
  isDone: boolean;
  isDoneError: boolean;
  isFormDataResponse: boolean;
  formDataError: ErrorType | null;
};

const TreeDetailFormAlerts: FC<TreeDetailFormAlertsProps> = ({
  isRefuseError,
  isRefuseData,
  isDone,
  isDoneError,
  isFormDataResponse,
  formDataError,
}) => {
  const { isAdmin } = useRole();
  const activeLang = useCurrentLanguage();

  return (
    <Fragment>
      <AppAlert
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={isRefuseError}
        message={activeLang.treeDetailPage.formAlerts.refuseError}
        autoHideDuration={1000}
        color={colors.redPrimary}
        severity="error"
      />
      <AppAlert
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={isRefuseData}
        message={activeLang.treeDetailPage.formAlerts.refuseSuccess}
        autoHideDuration={1000}
        color={colors.redPrimary}
        severity="success"
      />
      <AppAlert
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={isDoneError}
        message={
          isAdmin
            ? activeLang.treeDetailPage.formAlerts.doneErrorAdmin
            : activeLang.treeDetailPage.formAlerts.doneError
        }
        autoHideDuration={1000}
        color={colors.redPrimary}
        severity="error"
      />
      <AppAlert
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={isDone}
        message={
          isAdmin
            ? activeLang.treeDetailPage.formAlerts.doneSuccessAdmin
            : activeLang.treeDetailPage.formAlerts.doneSuccess
        }
        autoHideDuration={1000}
        color={colors.redPrimary}
        severity="success"
      />
      <AppAlert
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={!!formDataError}
        message={formDataError?.message}
        autoHideDuration={1000}
        color={colors.redPrimary}
        severity="error"
      />
      <AppAlert
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={isFormDataResponse}
        message={activeLang.treeDetailPage.formAlert.savedInfo}
        autoHideDuration={1000}
        color={colors.greenPrim}
        severity="success"
      />
    </Fragment>
  );
};

export default TreeDetailFormAlerts;
