import { styles } from './TreeDetailPageForm.styles';
import { css } from 'aphrodite';
import { fontsWeights } from '../../../styles/variables';
import { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useCurrentLanguage } from '../../../translations/languageSelector';

export interface RejectProps {
  rejectMessage?: string;
}

export const RejectNotice: FC<RejectProps> = ({ rejectMessage }) => {
  const activeLang = useCurrentLanguage();

  return (
    <Grid container className={css(styles.containerNotice)}>
      <Grid item xs={12}>
        <Typography
          variant="subtitle1"
          className={css(fontsWeights.bold, styles.rejectTitile)}
        >
          {activeLang.rejectNotice.title}
        </Typography>
      </Grid>
      {rejectMessage && (
        <Grid item xs={12}>
          <Typography
            className={css(fontsWeights.normal, styles.rejectMessage)}
          >
            {activeLang.rejectNotice.subtext} {rejectMessage}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
