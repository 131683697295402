import { SvgIcon } from '@material-ui/core';

export const Tree = () => {
  return (
    <SvgIcon
      style={{ width: '40%', height: '100%' }}
      viewBox="0 0 128 489"
      fill="none"
    >
      <path
        d="M64 221C28.6373 221 0 197.379 0 168.211V52.7895C0 23.6211 28.6373 0 64 0C99.3627 0 128 23.6211 128 52.7895V168.211C127.892 197.379 99.2543 221 64 221Z"
        fill="#8FBA23"
      />
      <path
        d="M128 52.7895V168.211C128 197.379 99.2653 221 63.7823 221C58.3401 221 53.1156 220.463 48 219.389C75.8639 213.574 96.4354 192.905 96.4354 168.211V52.7895C96.4354 38.2053 89.2517 24.9632 77.6054 15.479C69.551 8.8579 59.3197 4.02632 48 1.61053C53.0068 0.536845 58.3401 0 63.7823 0C81.5238 0 97.5238 5.90527 109.17 15.479C120.816 24.9632 128 38.2053 128 52.7895Z"
        fill="#7DA32B"
      />
      <path
        d="M67 101C67 97.6863 64.3137 95 61 95C57.6863 95 55 97.6863 55 101L67 101ZM55 483C55 486.314 57.6863 489 61 489C64.3137 489 67 486.314 67 483L55 483ZM55 101L55 483L67 483L67 101L55 101Z"
        fill="#D87B0F"
      />
      <path
        d="M57.902 187.89C55.7841 190.438 56.1333 194.221 58.6819 196.339C61.2304 198.457 65.0134 198.108 67.1312 195.559L57.902 187.89ZM89.8401 168.232C91.958 165.684 91.6088 161.901 89.0602 159.783C86.5117 157.665 82.7288 158.014 80.6109 160.563L89.8401 168.232ZM67.1312 195.559L89.8401 168.232L80.6109 160.563L57.902 187.89L67.1312 195.559Z"
        fill="#D87B0F"
      />
      <path
        d="M56.8084 158.58C59.1515 160.923 62.9505 160.923 65.2937 158.58C67.6368 156.237 67.6368 152.438 65.2937 150.095L56.8084 158.58ZM35.2336 120.034C32.8905 117.691 29.0915 117.691 26.7483 120.034C24.4052 122.378 24.4052 126.177 26.7483 128.52L35.2336 120.034ZM65.2937 150.095L35.2336 120.034L26.7483 128.52L56.8084 158.58L65.2937 150.095Z"
        fill="#D87B0F"
      />
    </SvgIcon>
  );
};
