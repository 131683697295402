import { StyleSheet } from 'aphrodite';
import { colors } from '../../../styles/colors';

export const styles = StyleSheet.create({
  contentContainer: {
    marginTop: 8,
  },
  formWrap: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
  },
  titleTop: {
    padding: 12,
  },
  card: {
    margin: 12,
    border: '1px solid #F0F0F0',
    padding: 42,
  },
  trunScanFile: {
    display: 'flex',
    justifyContent: 'center',
    gap: 5,
    paddingBottom: 20,
    fontWeight: 200,
  },
  trunkScanGrid: {
    display: 'flex',
    justifyContent: 'center',
    gap: 10,
  },
  button: {
    height: 40,
  },
  trunkGrid: {
    display: 'flex',
    flexDirection: 'column',
  },
  photo: {
    height: 'auto',
    width: '100%',
    display: 'block',
  },
});

export const downloadBtnStyle = (isActive: boolean) =>
  StyleSheet.create({
    downloadBtn: {
      color: isActive ? colors.textDark : colors.grey,
    },
  });
