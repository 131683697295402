import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ChangeEvent, FC, useCallback, useState } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { useTensilTestTaxon } from '../hooks/useTensilTestTaxon';

const inputName = 'taxon';

export const AppTensileTestsTaxonSelect: FC<{
  disabled?: boolean;
  rules?: RegisterOptions;
  showMessage?: boolean;
}> = ({ disabled, rules, showMessage }) => {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const { taxons, setTaxonQuery, isTaxonsLoading } = useTensilTestTaxon(
    getValues(inputName),
  );
  const error = errors[inputName];
  const [isOpen, setIsOpen] = useState(false);

  const onTextInputChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const inputValue = e.target.value;

      if (!inputValue) {
        setIsOpen(false);
        setTaxonQuery('');
        return;
      }

      setIsOpen(true);
      setTaxonQuery(inputValue);
    },
    [setTaxonQuery],
  );

  const onAutocompleteChange = useCallback(
    (_e: ChangeEvent<{}>, value: string) => {
      console.log({ value });
      setValue('taxon', value);
    },
    [setValue],
  );

  return (
    <Controller
      control={control}
      name={inputName}
      rules={rules}
      render={({ field: { value, name } }) => {
        return (
          <Autocomplete
            disabled={disabled}
            renderInput={params => (
              <TextField
                {...params}
                name={name}
                label={'Species'}
                onChange={onTextInputChange}
                variant="outlined"
                helperText={showMessage ? error?.message : undefined}
                error={!!error?.message}
              />
            )}
            loading={isTaxonsLoading}
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            onChange={onAutocompleteChange}
            onInputChange={(_e, _v, reason) =>
              reason === 'clear' && setTaxonQuery('')
            }
            value={value}
            size="small"
            options={taxons}
            fullWidth
          />
        );
      }}
    />
  );
};
