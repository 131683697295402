import { createSelector } from 'reselect';

import { State } from '../combinedReducers';
import { Tree, AnnouncementType } from '../../interfaces/tree';

export const treesSelector = (state: State) => state.trees.trees;
export const activeTreeId = (state: State) => state.trees.activeTreeId;
export const filtredTaxonsSelector = (state: State) => state.trees.taxons;
export const searchedTaxon = (state: State) => state.trees.searchedTaxon;
export const treeAnalysis = (state: State) => state.trees.analysisResults;
export const tensilTestsSaveStateSelector = (state: State) =>
  state.trees.saveTensilTestState;
export const photoSaveState = (state: State) => state.trees.savePhotoState;

/**
 * Returns active tree using URL id
 * Tree is extended for analysis results for specific operator
 */
export const getActiveTreeById = createSelector(
  treesSelector,
  activeTreeId,
  treeAnalysis,
  (trees, id, results) => {
    const originalTree = trees.find(t => t.id === id);
    // last analysis performed by user is chosen in ACTION,
    // here is selected one object from array
    // need to be replaced to choose analysis by operatorId
    // results.find(r => r.operatorId === getOperatorId)
    const result = results[results.length - 1];

    if (originalTree) {
      const origTree: Tree = {
        ...originalTree,
        tree_scanner_analysis_data: {
          id: originalTree.tree_scanner_analysis_data?.id,
          announcement: originalTree.announcement || AnnouncementType.Detail,
          condition: originalTree.tree_scanner_analysis_data?.condition || 1,
          mistletoe: originalTree.tree_scanner_analysis_data?.mistletoe || 1,
          dbh:
            originalTree?.tree_scanner_analysis_data?.dbh &&
            +originalTree.tree_scanner_analysis_data.dbh,
          diameter: originalTree.tree_scanner_analysis_data?.diameter,
          height: originalTree.tree_scanner_analysis_data?.height || 0,
          taxon: originalTree.taxon,
          treeTopHeight: originalTree.tree_scanner_analysis_data?.treeTopHeight,
          profile: originalTree.tree_scanner_analysis_data?.profile,
          protectionFactor:
            originalTree.tree_scanner_analysis_data?.protectionFactor,
          project: originalTree.project,
          site: originalTree.site,
          analysisLevel: originalTree.analysisLevel,
          treeId: originalTree.tree_scanner_analysis_data?.treeId,
          trunkScanHeight:
            originalTree.tree_scanner_analysis_data?.trunkScanHeight,
          trunkScanScreen:
            originalTree.tree_scanner_analysis_data?.trunkScanScreen,
        },
      };

      if (result) {
        const photoAnalyse: Tree = {
          ...origTree,
          tree_scanner_images: originalTree.tree_scanner_images.map(p => {
            if (result.analysedTree) {
              const found = result.analysedTree.tree_scanner_images.find(
                ap => ap.url === p.url,
              );
              return found ? found : p;
            }
            return p;
          }),
        };

        if (
          result.analysedTree &&
          result.analysedTree.tree_scanner_analysis_data
        ) {
          const analysis = result.analysedTree.tree_scanner_analysis_data;

          const analysedTree = {
            ...photoAnalyse,
            rejectText: result.analysedTree.rejectText,
            state: result.analysedTree.state,
            analysis: {
              condition: analysis.condition,
              diameter: analysis.diameter,
              mistletoe: analysis.mistletoe,
              treeTopHeight: analysis.treeTopHeight,
              height: analysis.height,
              dbh: analysis.dbh,
              taxon: analysis.taxon,
              announcement: analysis.announcement,
              profile: analysis.profile,
              protectionFactor: analysis.protectionFactor,
              project: analysis.project,
              site: analysis.site,
            },
          };
          return analysedTree;
        }
        return photoAnalyse;
      } else {
        return origTree;
      }
    }
  },
);

export const filtrTaxons = createSelector(
  filtredTaxonsSelector,
  searchedTaxon,
  (taxons, searchedWord) => {
    const maxLength = 10;
    if (searchedWord === '' || searchedTaxon === undefined) {
      return taxons.slice(0, maxLength);
    } else {
      const taxonWords = searchedWord.toLowerCase().split(' ');
      const result = taxons
        .filter((taxon: string) => {
          const words = taxon.toLowerCase().split(' ');
          return taxonWords.every((taxonWord: string) =>
            words.some((word: string) =>
              word.startsWith(taxonWord.toLowerCase()),
            ),
          );
        })
        .slice(0, maxLength);

      return result.length > 0 ? result : taxons.slice(0, maxLength);
    }
  },
);
