import React, { useState, useCallback, ChangeEvent, useEffect } from 'react';
import { Grid, TextField, Button } from '@material-ui/core';
import { css } from 'aphrodite';
import { useResetPassword } from '../../hooks/useResetPassword';
import HeaderIcon from './HeaderIcon';
import { styles } from './ForgotPassword.styles';
import { AppSnackbar } from '../appSnackbars/AppSnackbars';
import { useCurrentLanguage } from '../../translations/languageSelector';
import { useDispatch } from 'react-redux';
import { logOut } from '../../store/general/actions';
import CheckIcon from '@material-ui/icons/Check';

export const ForgotPassword = ({ match }) => {
  const activeLang = useCurrentLanguage();
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const [isValid, setIsValid] = useState(false);
  const { token } = match.params;
  const { resetPassword, error, isSuccess } = useResetPassword();

  useEffect(() => {
    dispatch(logOut());
  }, [dispatch]);

  const validate = useCallback((pw: string) => {
    const regex = /^(?=.*?[A-Z0-9])(?=.*?[a-z]).{6,}$/;
    const valid = pw.match(regex);
    setIsValid(!!valid);
  }, []);

  const onPwChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      validate(e.target.value);
      setPassword(e.target.value);
    },
    [validate],
  );

  const isFirstValid = password.length >= 6;
  const isSecondValid = /[A-Z]/.test(password) || /\d/.test(password);

  if (isSuccess) {
    return (
      <Grid className={css(styles.container)}>
        <Grid className={css(styles.success)}>
          <p className={css(styles.success)}>
            {activeLang.forgotPassword.successfull}
          </p>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container className={css(styles.container)}>
      <Grid className={css(styles.innerContainer)}>
        <AppSnackbar
          type="error"
          error={!!error}
          description={activeLang.forgotPassword.error}
        />
        <Grid>
          <HeaderIcon
            className={css(styles.iconWrap)}
            iconClassName={css(styles.icon)}
          />
          <h4 className={css(styles.heading)}>
            {activeLang.forgotPassword.heading}
          </h4>
        </Grid>
        <Grid>
          <Grid className={css(styles.rules)}>
            <h5 className={css(styles.rulesHeader)}>
              {activeLang.forgotPassword.pwTerms}
            </h5>
            <ul>
              <li>
                <div className={css(styles.rule)}>
                  <span>{activeLang.forgotPassword.pwTerm1}</span>
                  {isFirstValid && <CheckIcon fontSize="small" />}
                </div>
              </li>
              <li>
                <div className={css(styles.rule)}>
                  <span>{activeLang.forgotPassword.pwTerm2}</span>
                  {isSecondValid && <CheckIcon fontSize="small" />}
                </div>
              </li>
            </ul>
          </Grid>
          <Grid className={css(styles.inputPart)}>
            <TextField
              label={activeLang.forgotPassword.choosePw}
              type="password"
              color="primary"
              onChange={onPwChange}
            />
            <Button
              disabled={!isValid}
              variant="contained"
              className={css([
                styles.btn,
                isValid ? styles.submitBtn : styles.disabledBtn,
              ])}
              onClick={() => resetPassword(password, password, token)}
            >
              {activeLang.forgotPassword.continue}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
