import Konva from 'konva';
import { KonvaEventObject } from 'konva/types/Node';
import { FC, useRef } from 'react';
import { Circle, Group } from 'react-konva';
import { Position } from '../../../../../interfaces/canvas';
import { scalePoint } from '../../../../../styles/canvasStyles';

type CenterPointProps = {
  position?: Position;
  zoom: number;
  setIsDraggingPoint: (isDraggingPoint: boolean) => void;
  dragScalePoint: (point: Position) => void;
  draggable?: boolean;
};

export const CenterPoint: FC<CenterPointProps> = ({
  zoom,
  setIsDraggingPoint,
  dragScalePoint,
  draggable,
  position,
}) => {
  const canterPointRef = useRef<Konva.Circle>(null);

  const onDragPoint = (evt: KonvaEventObject<DragEvent>) => {
    if (canterPointRef.current) {
      const parent = canterPointRef.current.parent?.parent;

      if (canterPointRef.current.x() <= 0) {
        canterPointRef.current.setPosition({
          x: 0,
          y: canterPointRef.current.y(),
          id: +canterPointRef.current.id(),
        });
      } else if (parent && canterPointRef.current.x() >= parent.width()) {
        canterPointRef.current.setPosition({
          x: parent.width(),
          y: canterPointRef.current.y(),
          id: +canterPointRef.current.id(),
        });
      }
      if (canterPointRef.current.y() <= 0) {
        canterPointRef.current.setPosition({
          x: canterPointRef.current.x(),
          y: 0,
          id: +canterPointRef.current.id(),
        });
      } else if (parent && canterPointRef.current.y() >= parent.height()) {
        canterPointRef.current.setPosition({
          x: canterPointRef.current.x(),
          y: parent.height(),
          id: +canterPointRef.current.id(),
        });
      }
      dragScalePoint({
        x: canterPointRef.current.x(),
        y: canterPointRef.current.y(),
        id: +canterPointRef.current.id(),
      });
    }
  };

  return (
    <Group>
      {position && (
        <Circle
          ref={canterPointRef}
          draggable={draggable}
          onDragStart={() => setIsDraggingPoint(true)}
          onDragMove={onDragPoint}
          onDragEnd={() => setIsDraggingPoint(false)}
          x={position.x}
          y={position.y}
          {...scalePoint}
          fill={scalePoint.fillColor}
          radius={scalePoint.radius / zoom}
          strokeWidth={scalePoint.strokeWidth / zoom}
        />
      )}
    </Group>
  );
};
