import React, { useState, useCallback, useContext, BaseSyntheticEvent } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Typography, Grid, Badge } from '@material-ui/core';
import { StyleSheet, css } from 'aphrodite';
import {
  MENU_HEIGHT,
  LOGO_HEADER_HEIGHT,
  stylingVariables,
  fontsWeights,
} from '../../styles/variables';
import { textColors, colors } from '../../styles/colors';
import { UserDTO } from '../../interfaces/user';
import smallLogo from '../../assets/images/logo-header.png';
import bigLogo from '../../assets/images/logo-half.png';
import { Breadcrumb, Button } from 'antd';
import { HeaderLink } from '../../interfaces/headerLink';
import { NotificationDialog } from '../notificationsDialog/NotificationsDialog';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { UnreadNotificationsCountContext } from '../../contexts/UnreadNotificationsCountContext';
import { useRole } from '../../hooks/useRole';
import { useCurrentLanguage } from '../../translations/languageSelector';
import { logOut as logOutAction } from '../../store/general/actions';
import { useCurrentUser } from '../../hooks/useCurrentUser';

interface HeaderProps {
  wholeLogo?: boolean;
  breadcrumbs?: HeaderLink[];
  navButtons?: HeaderLink[];
  loggedUser?: UserDTO;
  logOut?: () => void;
}

export const Header: React.FC<HeaderProps> = props => {
  const { wholeLogo, breadcrumbs, navButtons } = props;
  const history = useHistory();
  const [notificationEl, setNotificationEl] = useState(null);
  const { isAdmin } = useRole();


  const activeLang = useCurrentLanguage();
  const loggedUser = useCurrentUser();
  const dispatch = useDispatch();
  const logOut = props.logOut ?? (() => dispatch(logOutAction));

  const openNotifications = Boolean(notificationEl);

  const { unreadNotificationsCount } = useContext(
    UnreadNotificationsCountContext,
  );

  const handleNotificationOpen = useCallback((event: BaseSyntheticEvent) => {
    setNotificationEl(event.currentTarget);
  }, []);

  const handleNotificationClose = useCallback(() => {
    setNotificationEl(null);
  }, []);

  return (
    <Grid
      container
      className={css(styles.header)}
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item>
        <Grid container alignItems="center">
          <Grid item>
            <img
              src={wholeLogo === true ? smallLogo : bigLogo}
              alt="logo-half"
              className={css(styles.logo)}
            />
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle2"
              className={css(
                textColors.primaryGreen,
                fontsWeights.bold,
                styles.title,
              )}
            >
              {breadcrumbs && (
                <Breadcrumb>
                  {breadcrumbs.map((breadcrumb, index) => (
                    <Breadcrumb.Item key={index} onClick={breadcrumb.onClick}>
                      {breadcrumb.link ? (
                        <Link to={breadcrumb.link}>{breadcrumb.label}</Link>
                      ) : (
                        <React.Fragment>{breadcrumb.label}</React.Fragment>
                      )}
                    </Breadcrumb.Item>
                  ))}
                </Breadcrumb>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {navButtons && (
        <Grid item>
          {navButtons.map((navBtn, index) => (
            <Button
              key={index}
              type={navBtn.active === true ? 'primary' : 'link'}
              className={css(
                styles.navBtn,
                navBtn.active !== true && textColors.primaryGreen,
              )}
              onClick={() => {
                if (navBtn.onClick) {
                  navBtn.onClick();
                }
                if (navBtn.link) {
                  history.push(navBtn.link);
                }
              }}
            >
              {navBtn.label}
            </Button>
          ))}
        </Grid>
      )}
      <Grid
        item
        className={css(
          textColors.primaryGreen,
          fontsWeights.bold,
          styles.name,
          styles.navIcons,
        )}
      >
        {loggedUser && (
          <>
            {isAdmin && (
              <Link to={'/finished'} className={css(styles.finishedOrders)}>
                {activeLang.finished.finishedOrders}
              </Link>
            )}
            {unreadNotificationsCount > 0 ? (
              <Grid className={css(styles.notificationBtn)}>
                <Badge
                  color="error"
                  badgeContent={unreadNotificationsCount}
                  max={99}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                  <NotificationsIcon
                    className={css(styles.icon)}
                    onClick={handleNotificationOpen}
                  />
                </Badge>
              </Grid>
            ) : (
              <NotificationsIcon
                className={css(styles.notificationBtn, styles.icon)}
                onClick={handleNotificationOpen}
              />
            )}

            <NotificationDialog
              open={openNotifications}
              onClose={handleNotificationClose}
            />
            <span>{loggedUser.username}</span>
            <Link to={`/`} className={css(textColors.primaryGreen)}>
              <ExitToAppIcon
                className={css(styles.title, styles.icon, styles.navIcons)}
                onClick={logOut}
              />
            </Link>
          </>
        )}
      </Grid>
    </Grid>
  );
};

const styles = StyleSheet.create({
  header: {
    boxShadow: stylingVariables.defaultElevation,
    height: MENU_HEIGHT,
    backgroundColor: colors.white,
    borderRadius: '10px 10px 0px 0px',
    position: 'relative',
    zIndex: 9,
  },
  title: {
    marginLeft: 12,
  },
  logo: {
    marginLeft: 24,
    height: LOGO_HEADER_HEIGHT,
  },
  name: {
    marginRight: 20,
  },
  icon: {
    fontSize: 25,
    cursor: 'pointer',
  },
  navBtn: {
    marginLeft: 6,
    marginRight: 6,
  },
  notificationBtn: {
    marginRight: 12,
  },
  navIcons: {
    display: 'flex',
    alignItems: 'center',
  },
  finishedOrders: {
    color: colors.greenPrimary,
    paddingRight: 6,
    fontSize: 14,
  },
});
