import { FC } from 'react';
import {
  Badge,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import { css } from 'aphrodite';
import { useCurrentLanguage } from '../../../../translations/languageSelector';
import { dynamicStyles, getMeterBgColor, styles } from './TensilTests.styles';
import { CALCULATIONS } from '../../../../interfaces/tenstilTest';
import { useTensileTest } from '../../../../contexts/TensilTestsContext';
import { CalculatedElastometerProtocolData, InclinometerCalculationDto } from '../../../../helpers/apiHelper';
import AppButton from '../Form/AppButton/AppButton';
import { SaveOutlined } from '@material-ui/icons';
import { LoadingOutlined } from '@ant-design/icons';


const tableItemsIds = [
  'measurementPosition',
  'bendingMomentAtBase',
  'bendingMomentAtMeasure',
  'criticalBendingMoment',
  'resistanceToFracture',
  'resistanceToUprooting',
  'relativeDeformation',
  'safetyFactor',
] as const;

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#ECF4F2',
    },
  },
}))(TableRow);



export const TensileTestResultsTable = () => {
  const activeLang = useCurrentLanguage();
  // TODO: add error messages from Form Status
  // const { handleSubmit } = useFormContext();

  const {
    common: {
      activeTensileTest,
    },
    computation: {
      form: {
        getValues,
      },
      results: {
        precalculations,
        calculations,
      },
      isLoading,
      saveCalculatedProtocolValues,
    },
    safetyCoeficientTable: {
      protocol,
    }
  } = useTensileTest();

  if (!calculations) {
    return null;
  }

  return (
    <>
      <Typography variant="h4" style={{ fontWeight: 700, margin: 40 }}>
        {activeLang.treeDetailPage.tensilTest.resultValuesOfMeasurement}
      </Typography>

      <PullingTestElastometersResultsTable
        calculationValues={calculations.elastometers}
      />

      <PullingTestInclinometersResultsTable
        calculationValues={calculations.inclinometers}
      />

      <Grid container direction="row-reverse" style={{ padding: 10 }}>
        <AppButton
          onClick={() => saveCalculatedProtocolValues({
            id: activeTensileTest.id,
            taxon: getValues('taxon') || activeTensileTest.taxon,
            protocol,
            calculations,
          })}
          variant="contained"
          color="primary"
          disabled={isLoading || !precalculations}
          startIcon={isLoading ? <LoadingOutlined /> : <SaveOutlined />}
        >
          {activeLang.treeDetailPage.tensilTest.save}
        </AppButton>
      </Grid>
    </>
  );
};


const PullingTestElastometersResultsTable: FC<
  { calculationValues: CalculatedElastometerProtocolData[] }
> = ({ calculationValues }) => {
  const activeLang = useCurrentLanguage();

  const { safetyCoeficientTable: { availableElastometers } } = useTensileTest();

  const activeElastometer = calculationValues.find(elastometer => elastometer);
  const resultsTableProperties = activeElastometer ? Object.keys(activeElastometer.results) : [];
  const commonValuesTableProperties = activeElastometer ? Object.keys(activeElastometer.commonValues) : [];

  if (!resultsTableProperties.length && !commonValuesTableProperties.length) {
    return null;
  }

  return (
    <Grid item className={css(styles.tensilTestCard)}>

      <Grid container justifyContent="space-between" style={{ padding: '5px 10px' }}>
        <Grid item>
          <Typography variant="h6" style={{ fontWeight: 700, margin: 10 }}>
            {activeLang.treeDetailPage.tensilTest.resultFor}
            {'Elastometers'}
          </Typography>
        </Grid>
      </Grid>


      <Table size="small" padding="none">
        <TableHead>
          <TableRow>
            <TableCell />

            {calculationValues.map((elastometer, index) => {
              const name = availableElastometers[index];
              return (!elastometer) ? null : (
                <TableCell style={{ padding: 5 }} component="th" scope="row">
                  <Badge
                    style={{ backgroundColor: getMeterBgColor(name) }}
                    className={css(dynamicStyles(getMeterBgColor(name)).badge)}
                  >
                    {name}
                  </Badge>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {resultsTableProperties.map(prop => (
            <StyledTableRow key={prop}>
              <TableCell style={{ padding: 5 }} component="th" scope="row">
                {activeLang.treeDetailPage.tensilTest[prop]}
              </TableCell>

              {calculationValues?.map(
                (elastometerValues, elastometerIndex) => (!elastometerValues) ? null : (
                  <TableCell
                    key={`dynamometer-${elastometerIndex}`}
                    className={css(styles.unitCell)}
                    data-unit={CALCULATIONS[prop]?.unit}
                  >
                    {elastometerValues.results[prop]?.toFixed(CALCULATIONS[prop]?.precision)}
                  </TableCell>
                )
              )}
            </StyledTableRow>
          ))}
        </TableBody>

        <TableBody>
          {commonValuesTableProperties.map(prop => (
            <StyledTableRow key={prop}>
              <TableCell style={{ padding: 5 }} component="th" scope="row">
                {activeLang.treeDetailPage.tensilTest[prop]}
              </TableCell>
              {calculationValues?.map(
                (elastometerValues, elastometerIndex) => (!elastometerValues) ? null : (
                  <TableCell
                    key={`dynamometer-${elastometerIndex}`}
                    className={css(styles.unitCell)}
                    data-unit={CALCULATIONS[prop]?.unit}
                  >
                    {elastometerValues.commonValues[prop]?.toFixed(CALCULATIONS[prop]?.precision)}
                  </TableCell>
                )
              )}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>

    </Grid>
  );
};


const PullingTestInclinometersResultsTable: FC<
  { calculationValues: (InclinometerCalculationDto | undefined)[] }
> = ({ calculationValues }) => {
  const activeLang = useCurrentLanguage();

  const { safetyCoeficientTable: { availableInclinometers } } = useTensileTest();

  const activeInclinometer = calculationValues.find(inclinometer => inclinometer);
  const tableProperties = activeInclinometer ? Object.keys(activeInclinometer) : [];

  if (!tableProperties.length) {
    return null;
  }

  return (
    <Grid item className={css(styles.tensilTestCard)}>

      <Grid container justifyContent="space-between" style={{ padding: '5px 10px' }}>
        <Grid item>
          <Typography variant="h6" style={{ fontWeight: 700, margin: 10 }}>
            {activeLang.treeDetailPage.tensilTest.resultFor}
            {'Inclinometers'}
          </Typography>
        </Grid>
      </Grid>


      <Table size="small" padding="none">
        <TableHead>
          <TableRow>
            <TableCell />

            {calculationValues.map((inclinometer, index) => {
              const name = availableInclinometers[index];

              return (!inclinometer) ? null : (
                <TableCell style={{ padding: 5 }} component="th" scope="row">
                  <Badge
                    style={{ backgroundColor: getMeterBgColor(name) }}
                    className={css(dynamicStyles(getMeterBgColor(name)).badge)}
                  >
                    {name}
                  </Badge>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {tableProperties.map(prop => (
            <StyledTableRow key={prop}>
              <TableCell style={{ padding: 5 }} component="th" scope="row">
                {activeLang.treeDetailPage.tensilTest[prop]}
              </TableCell>

              {calculationValues?.map(
                (inclinometerValues, inclinometerIndex) => (!inclinometerValues) ? null : (
                  <TableCell
                    key={`dynamometer-${inclinometerIndex}`}
                    className={css(styles.unitCell)}
                    data-unit={CALCULATIONS[prop]?.unit}
                  >
                    {inclinometerValues[prop]?.toFixed(CALCULATIONS[prop]?.precision)}
                  </TableCell>
                )
              )}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>

    </Grid>
  );
};
