import { StyleSheet } from 'aphrodite';
import { colors } from '../../styles/colors';

export const styles = StyleSheet.create({
  order: {
    margin: '10px 0',
    border: '1px solid grey',
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    ':hover': {
      backgroundColor: colors.grayBtn,
    },
  },
  report: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  container: {
    marginTop: 30,
  },
  cell: {
    flex: 1,
  },
  cellTitle: {
    fontSize: 11,
    color: colors.grayBtn,
  },
  activeButton: {
    ':hover': {
      color: colors.greenPrimary,
      borderColor: colors.greenPrimary,
    },
  },
  statusBadge: {
    color: colors.greenPrim,
  },
  problematicStatusBadge: {
    color: colors.redPrimary,
  },
});
