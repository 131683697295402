import { Grid, makeStyles } from '@material-ui/core';
import { useCallback } from 'react';
import { putTomogramAnalysisData } from '../../../helpers/apiHelper';
import { useTomogramDeformation } from '../../../hooks/useTomogramDeformation';
import { Position } from '../../../interfaces/canvas';
import { Tree } from '../../../interfaces/tree';
import { KonvaContainer } from './KonvaContainer';
import { MiddleButtons } from './MiddleButtons';
import { TomogramButton } from './TomogramButton';
import { MAX_TOMOGRAM_SIZE, TomogramKonvaImage } from './TomogramKonvaImage';
import { TomogramImageProps } from './TomogramsPreview';

interface ActiveTomogramProps {
  activeTomogram: TomogramImageProps;
  activeTree: Tree | undefined;
  image: HTMLImageElement | undefined;
  tomograms: TomogramImageProps[];
  sliceImage: HTMLImageElement | undefined;
  tomogramPoints: Position[];
  slicePoints: Position[];
  setTomogramPoints: React.Dispatch<React.SetStateAction<Position[]>>;
  setSlicePoints: React.Dispatch<React.SetStateAction<Position[]>>;
}

interface SliceDescription {
  height: number;
  modelUrl: string | undefined;
}

const useStyles = makeStyles(theme => ({
  preview: {
    maxWidth: MAX_TOMOGRAM_SIZE,
    maxHeight: MAX_TOMOGRAM_SIZE,
    objectFit: 'scale-down',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export const ActiveTomogram = ({
  activeTomogram,
  activeTree,
  image,
  sliceImage,
  tomograms,
  tomogramPoints,
  slicePoints,
  setTomogramPoints,
  setSlicePoints,
}: ActiveTomogramProps) => {
  const classes = useStyles();

  const {
    deformation,
    tomogramDeformationData,
    isTomogramDeformationLoading,
    putTomogramDeformationError,
  } = useTomogramDeformation();

  const modifyCutPoints = (index: number, newPoint: Position) => {
    setSlicePoints(prevPoints => {
      const newArray = [...prevPoints];
      newArray[index] = newPoint;
      return newArray;
    });
  };

  const handlePreviewClick = () => {
    const tomogramNorthPoint = 0;

    const sliceDescription: SliceDescription = {
      height: activeTomogram.height,
      modelUrl: activeTree?.trunkScanUrl,
    };

    if (sliceDescription.modelUrl && image?.src) {
      deformation({
        sliceDescription: {
          height: sliceDescription.height,
          modelUrl: sliceDescription.modelUrl!,
        },
        slicePoints,
        tomogramPoints,
        tomogramUrl: image.src,
        tomogramNorthPoint,
      });
    }
  };

  const createPoint = useCallback(
    (position: Position) => {
      setTomogramPoints(prevPoints => [...prevPoints, position]);
    },
    [setTomogramPoints],
  );

  const handleBackButtonClick = useCallback(() => {
    setTomogramPoints(prevPoints => prevPoints.slice(0, -1));
    setSlicePoints(prevPoints => prevPoints.slice(0, -1));
  }, [setSlicePoints, setTomogramPoints]);

  const handleRestartClick = useCallback(() => {
    setTomogramPoints([]);
    setSlicePoints([]);
  }, [setSlicePoints, setTomogramPoints]);

  const onTomogramSave = () => {
    // save button is visible only when there is activeTomogram
    // save button is visible only when slice is already stored in DB; thus there is tomogramAnalysisData
    // save button is visible only when there is sliceImage visible
    const data = {
      id: activeTomogram!.tomogramAnalysisData!.id,
      sliceURL: sliceImage!.src,
      slicePoints,
      sliceTomogramResultURL: tomogramDeformationData,
      tomogramPoints,
    };
    putTomogramAnalysisData(data);
  };

  const middleButtonDisabled = tomogramPoints.length === 0;
  const disabledSave = !!(
    tomograms.some(tomogram => !tomogram.height || !tomogram.image) ||
    !tomogramDeformationData ||
    !sliceImage?.src
  );

  return (
    <>
      <Grid item xs={12}>
        <Grid container direction="row" justifyContent="space-around">
          <Grid item xs={5}>
            <KonvaContainer title={'Tomogram'}>
              <TomogramKonvaImage
                image={image}
                points={tomogramPoints}
                createPoint={createPoint}
                clickable
              />
            </KonvaContainer>
          </Grid>
          <Grid item xs={1}>
            <MiddleButtons
              middleButtonDisabled={middleButtonDisabled}
              handleRestartClick={handleRestartClick}
              handleBackButtonClick={handleBackButtonClick}
            />
          </Grid>
          <Grid item xs={5}>
            <KonvaContainer title={'Řez kmenem'}>
              <TomogramKonvaImage
                image={sliceImage}
                points={slicePoints}
                createPoint={createPoint}
                handleDragPoint={modifyCutPoints}
                draggable
              />
            </KonvaContainer>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={5} direction="column">
          <Grid item>
            <TomogramButton
              onClick={handlePreviewClick}
              title={'Preview'}
              loading={isTomogramDeformationLoading}
              error={putTomogramDeformationError}
            />
          </Grid>
          <Grid item>
            <KonvaContainer title={'Aktuální výsledek'}>
              {tomogramDeformationData && (
                <img
                  src={tomogramDeformationData}
                  alt="preview"
                  className={classes.preview}
                />
              )}
            </KonvaContainer>
          </Grid>
          <Grid item>
            <TomogramButton
              onClick={onTomogramSave}
              title={'Save'}
              disabled={disabledSave}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
