import Konva from 'konva';
import { KonvaEventObject } from 'konva/types/Node';
import { FC, useCallback, useRef } from 'react';
import { Circle, Group, Line } from 'react-konva';
import { Position } from '../../../../../interfaces/canvas';
import { baseConfig, basePoint } from '../../../../../styles/canvasStyles';

type BaseProps = {
  base: Position[];
  zoom: number;
  setIsDraggingPoint: (isDraggingPoint: boolean) => void;
  dragBasePoint: (point: Position) => void;
  draggable?: boolean;
};

export const Base: FC<BaseProps> = ({
  base,
  zoom,
  setIsDraggingPoint,
  dragBasePoint,
  draggable,
}) => {
  const basePointRef = useRef<Konva.Circle[]>([]);

  const onDragPoint = useCallback(
    (evt: KonvaEventObject<DragEvent>) => {
      const draggedPointRef = basePointRef.current.find(
        ref => ref.id() === evt.target.attrs.id,
      );
      if (draggedPointRef) {
        const parent = draggedPointRef.parent?.parent;

        if (draggedPointRef.x() <= 0) {
          draggedPointRef.setPosition({
            x: 0,
            y: draggedPointRef.y(),
            id: +draggedPointRef.id(),
          });
        } else if (parent && draggedPointRef.x() >= parent.width()) {
          draggedPointRef.setPosition({
            x: parent.width(),
            y: draggedPointRef.y(),
            id: +draggedPointRef.id(),
          });
        }

        if (draggedPointRef.y() <= 0) {
          draggedPointRef.setPosition({
            x: draggedPointRef.x(),
            y: 0,
            id: +draggedPointRef.id(),
          });
        } else if (parent && draggedPointRef.y() >= parent.height()) {
          draggedPointRef.setPosition({
            x: draggedPointRef.x(),
            y: parent.height(),
            id: +draggedPointRef.id(),
          });
        }

        draggedPointRef.y(base[0].y);
        dragBasePoint({
          x: draggedPointRef.x(),
          y: draggedPointRef.y(),
          id: +draggedPointRef.id(),
        });
      }
    },
    [base, dragBasePoint],
  );

  return (
    <Group>
      {base.length === 2 && (
        <Line
          points={[base[0].x, base[0].y, base[1].x, base[1].y]}
          {...baseConfig}
          strokeWidth={baseConfig.strokeWidth / zoom}
        />
      )}
      {base.map((item, index) => (
        <Circle
          ref={ref =>
            ref &&
            !basePointRef.current.includes(ref) &&
            basePointRef.current.push(ref!)
          }
          draggable={draggable}
          onDragStart={() => setIsDraggingPoint(true)}
          id={`${item.id}`}
          key={`${item.id}`}
          onDragMove={onDragPoint}
          onDragEnd={() => setIsDraggingPoint(false)}
          x={item.x}
          y={item.y}
          {...basePoint}
          fill={basePoint.fillColor}
          radius={basePoint.radius / zoom}
          strokeWidth={basePoint.strokeWidth / zoom}
        />
      ))}
    </Group>
  );
};
