import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { styles } from './AppRadio.styles';
import { css } from 'aphrodite';
import { AppRadioProps } from './types';

const AppRadio: FC<AppRadioProps> = ({
  name,
  label,
  options,
  rules,
  value,
  required,
  disabled,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = errors[name];

  return (
    <FormControl component="fieldset" className={css(styles.radio)}>
      <FormLabel
        error={!!error?.message}
        htmlFor={name}
        className={css(styles.label)}
        component="legend"
      >
        {`${label}${required ? ' *' : ''}`}
      </FormLabel>
      <Controller
        rules={rules}
        name={name}
        defaultValue={value || ''}
        control={control}
        render={({ field }) => (
          <RadioGroup
            defaultValue={field.value}
            style={{ flexDirection: 'row' }}
            {...field}
          >
            {options.map(option => (
              <FormControlLabel
                key={option.value}
                labelPlacement="top"
                value={option.value}
                control={<Radio size="small" style={{ padding: 0 }} />}
                label={option.label}
                disabled={disabled}
              />
            ))}
          </RadioGroup>
        )}
      />
      {error?.message && (
        <FormHelperText error={!!error?.message}>
          {error.message}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default AppRadio;
