import { RegisterOptions } from 'react-hook-form';

export const requiredRule: RegisterOptions = {
  validate: value => !!value,
  pattern: {
    value: /([^\s])/,
    message: `Tato položka je povinná.`,
  },
  required: `Tato položka je povinná.`,
};

export const greaterThanZeroRule: RegisterOptions = {
  validate: (value: number): string | undefined =>
    value <= 0 ? 'Číslo musí být větší než 0.' : undefined,
};
