import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { useTreeDetailState } from '../components/treeDetailPageNew/TreeDetailProvider/TreeDetailStateProvider';
import { postRejectAnalysis } from '../helpers/apiHelper';

export const useRejectReport = () => {
  const { activeTree, loadTree } = useTreeDetailState();
  const { mutate: onRejectReport, isLoading: formDataLoading } =
    useMutation(postRejectAnalysis);

  const onReject = useCallback(
    payload => {
      if (activeTree) {
        onRejectReport(
          { rejectedReason: payload.rejectedReason, id: payload.id },
          { onSuccess: () => loadTree(payload.id) },
        );
      }
    },
    [activeTree, onRejectReport, loadTree],
  );

  return {
    onReject,
    rejectLoading: formDataLoading,
  };
};
