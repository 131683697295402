import { StyleSheet } from 'aphrodite';
import { colors } from '../../../styles/colors';

export const styles = StyleSheet.create({
  container: {
    margin: '30px 100px',
  },
  table: {
    border: '1px solid #ABAFB7',

    '.ant-spin-nested-loading': {
      height: '100px',
    },
  },
  upload: {
    borderColor: colors.lightBlue,
    color: colors.lightBlue,
    textTransform: 'none',
    borderRadius: 16,
  },
  uploadIcon: { marginRight: 10 },
  show: {
    color: colors.lightBlue,
    border: 'none',
    textTransform: 'none',
    textDecoration: 'underline',
  },
  delete: {
    color: colors.redPrimary,
    textTransform: 'none',
    textDecoration: 'underline',
  },
  download: {
    color: colors.lightBlue,
    border: 'none',
    textTransform: 'none',
    textDecoration: 'underline',
  },
  noteDisabled: {
    color: colors.grey,
  },
});
