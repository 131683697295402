import { useCallback, useState, FC } from 'react';
import { Form, Input, Button, InputNumber, Slider, FormInstance } from 'antd';
import { css } from 'aphrodite';
import { CircularProgress, Grid } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import CropIcon from '@material-ui/icons/Crop';
import BrightnessMediumIcon from '@material-ui/icons/BrightnessMedium';
import { useCurrentLanguage } from '../../../../translations/languageSelector';
import { useEditor } from '../../../../hooks/useEditor';
import { styles } from './ImageEditor.styles';
import { Photo } from '../../../../interfaces/tree';
import { IMAGE_CROP_RATIO } from '../../../../constants/constants';
import { colors } from '../../../../styles/colors';
import AppButton from '../Form/AppButton/AppButton';

type TImageEditor = {
  activePhoto: Photo;
  form: FormInstance<any>;
  callUpdatePhoto: (photo: Photo) => void;
  loading?: boolean;
};

const FormItem = Form.Item;
const { TextArea } = Input;

const ImageEditor: FC<TImageEditor> = ({
  activePhoto,
  form,
  callUpdatePhoto,
  loading,
}) => {
  const activeLang = useCurrentLanguage();
  const photoEditingLabel = activeLang.treeDetailPage.photoEditing;
  const [active, setActive] = useState(false);
  const {
    editorMemo,
    rotate,
    toggleCrop,
    drawingMode,
    applyCrop,
    filter,
    getImage,
    setCropRatio,
  } = useEditor(activePhoto.url, css(styles.editor));

  const onRotate = useCallback(
    (side: 'left' | 'right') => {
      if (side === 'left') {
        rotate(-90);
      }
      if (side === 'right') {
        rotate(90);
      }
    },
    [rotate],
  );

  const onToggleCrop = useCallback(() => {
    toggleCrop();
    if (drawingMode() === 'CROPPER') {
      setActive(true);
      setCropRatio(IMAGE_CROP_RATIO);
    } else {
      setActive(false);
    }
  }, [toggleCrop, drawingMode, setCropRatio]);

  const onBrightnessChange = useCallback(
    (value: number) => {
      filter(value);
    },
    [filter],
  );

  const onApplyCrop = useCallback(() => {
    applyCrop();
    setActive(false);
  }, [applyCrop]);

  const onCancelCrop = useCallback(() => {
    toggleCrop();
    setActive(false);
  }, [toggleCrop]);

  const handleSubmit = useCallback(
    (values: any) => {
      const rImageType = /data:(image\/.+);base64,/;

      const base64 = getImage().replace(rImageType, '');

      callUpdatePhoto({
        ...activePhoto,
        ...values,
        imageCopy: base64,
      });
    },
    [activePhoto, callUpdatePhoto, getImage],
  );

  return (
    <Grid className={css(styles.container)}>
      <Grid className={css(styles.editorContainer)}>
        <Grid className={css(styles.editorButtonsWrapper)}>
          <Button
            type="primary"
            block
            size="large"
            className={css(styles.btn)}
            onClick={() => onRotate('left')}
          >
            <RotateLeftIcon />
          </Button>
          <Button
            type="primary"
            block
            size="large"
            className={css(styles.btn)}
            onClick={() => onRotate('right')}
          >
            <RotateRightIcon />
          </Button>
          <Button
            type="primary"
            block
            size="large"
            className={css(styles.btn)}
            onClick={onToggleCrop}
          >
            <CropIcon />
          </Button>
          <Button
            type="primary"
            block
            size="large"
            className={css(styles.btn)}
            onClick={onApplyCrop}
            disabled={!active}
          >
            <CheckIcon />
          </Button>
          <Button
            type="primary"
            block
            size="large"
            className={css(styles.btn)}
            onClick={onCancelCrop}
            disabled={!active}
          >
            <CloseIcon />
          </Button>
          <Grid className={css(styles.brightWrap)}>
            <BrightnessMediumIcon className={css(styles.brightIcon)} />
            <Slider
              trackStyle={{ background: colors.greenPrimary }}
              handleStyle={{ borderColor: colors.greenPrimary }}
              className={css(styles.brightSlider)}
              defaultValue={0}
              max={100}
              min={-100}
              onChange={onBrightnessChange}
            />
          </Grid>
        </Grid>
        {editorMemo}
      </Grid>

      <Form
        className={css(styles.photoForm)}
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        initialValues={{
          photoNumber: activePhoto.photoNumber,
          description: activePhoto.description,
        }}
      >
        <FormItem name="photoNumber" label={photoEditingLabel.form.photoNumber}>
          <InputNumber className={css(styles.photoFormItem)} size="large" />
        </FormItem>
        <FormItem name="description" label={photoEditingLabel.form.description}>
          <TextArea
            className={css(styles.photoFormItem)}
            size="large"
            autoSize={{ minRows: 2 }}
          />
        </FormItem>
        <AppButton
          type="submit"
          color="primary"
          variant="contained"
          fullWidth
          disabled={loading}
        >
          {!loading ? (
            photoEditingLabel.form.save
          ) : (
            <CircularProgress size={24} color="primary" />
          )}
        </AppButton>
      </Form>
    </Grid>
  );
};

export default ImageEditor;
