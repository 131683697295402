import { useState, useCallback, FC, useEffect, Fragment, useMemo } from 'react';
import { Form, Button, Card } from 'antd';
import { css } from 'aphrodite';
import { Grid, Modal, Box, CircularProgress } from '@material-ui/core';

import { Photo, TreeScannerDataStatus } from '../../../interfaces/tree';
import { useCurrentLanguage } from '../../../translations/languageSelector';
import { styles } from './PhotoEditingPage.styles';
import ImageEditor from '../components/ImageEditor/ImageEditor';
import { useTreeDetailState } from '../TreeDetailProvider/TreeDetailStateProvider';
import AppAlert from '../components/Form/AppAlert/AppAlert';
import { colors } from '../../../styles/colors';
import AppButton from '../components/Form/AppButton/AppButton';
import { useToggle } from '../../../hooks/useToggle';
import { TreeDetailActions } from '../TreeDetailProvider/types';
import { SiteTab } from '../../../interfaces/enums';
import { NoDataTab } from '../components/NoDataTab/NoDataTab';

export const PhotoEditingPage: FC = () => {
  const {
    deletePhoto,
    activeTree,
    editPhoto,
    loadTree,
    photoLoading,
    photoData,
    photoError,
    dispatchTreeDetailState,
  } = useTreeDetailState();
  const [form] = Form.useForm();
  const [activePhoto, setActivePhoto] = useState<Photo | undefined>(undefined);
  const [isModalOpen, toggleModal] = useToggle();
  const activeLang = useCurrentLanguage();

  useEffect(() => {
    if (photoData && activeTree?.id && !photoLoading) {
      loadTree(activeTree.id);
    }
    if (photoData) {
      setActivePhoto(photoData);
    }
  }, [activeTree?.id, loadTree, photoData, photoLoading]);

  useEffect(() => {
    dispatchTreeDetailState({
      type: TreeDetailActions.SET_CURRENT_TAB,
      // @ts-ignore
      payload: SiteTab.Photos,
    });
  }, [dispatchTreeDetailState]);

  const onDeleteClick = useCallback(
    (photo: Photo) => {
      toggleModal();
      setActivePhoto(photo);
    },
    [toggleModal],
  );

  const onDelete = useCallback(() => {
    if (activePhoto) {
      deletePhoto(activePhoto.id);
      setActivePhoto(undefined);
      toggleModal();
    }
  }, [activePhoto, deletePhoto, toggleModal]);

  const onPhotoListItemClick = useCallback(
    (newPhoto: Photo) => {
      setActivePhoto(undefined);
      const processClick = () => {
        setActivePhoto(newPhoto);
        form.setFieldsValue({
          photoNumber: newPhoto.photoNumber,
          description: newPhoto.description,
        });
      };
      processClick();
    },
    [form],
  );

  const onPhotoEdit = useCallback(
    (photo: Photo) => {
      editPhoto(photo);
    },
    [editPhoto],
  );

  const isDisabled = useMemo(() => {
    if (activeTree?.status === TreeScannerDataStatus.FINISHED) {
      return true;
    }
  }, [activeTree]);

  return activeTree?.tree_scanner_images?.length ? (
    <Grid
      container
      className={css(styles.contentContainer)}
      justifyContent="space-between"
    >
      <Modal className={css(styles.modal)} open={isModalOpen}>
        <div className={css(styles.modalBody)}>
          <Box>{activeLang.treeDetailPage.photoEditing.deleteImage}</Box>
          <div className={css(styles.buttonsWrap)}>
            <AppButton
              className={css(styles.refuseButton)}
              onClick={() => toggleModal()}
            >
              {activeLang.treeDetailPage.photoEditing.deleteImageNo}
            </AppButton>
            <AppButton color="primary" variant="contained" onClick={onDelete}>
              {activeLang.treeDetailPage.photoEditing.deleteImageYes}
            </AppButton>
          </div>
        </div>
      </Modal>
      <AppAlert
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={!!photoError}
        message={photoError?.message}
        autoHideDuration={1000}
        color={colors.redPrimary}
        severity="error"
      />
      <AppAlert
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={!!photoData}
        message={activeLang.treeDetailPage.photoEditSuccess}
        autoHideDuration={1000}
        color={colors.greenPrim}
        severity="success"
      />
      <Grid item xs={12}>
        <div className={css(styles.photosContainer)}>
          {activeTree.tree_scanner_images.length > 0 &&
            activeTree.tree_scanner_images.map((p, index) => (
              <Card
                key={index}
                className={css(styles.singlePhotoContainer)}
                onClick={() => onPhotoListItemClick(p)}
              >
                <p>
                  {activeLang.treeDetailPage.photoEditing[p.type] !== undefined
                    ? activeLang.treeDetailPage.photoEditing[p.type]
                    : p.type}
                </p>
                <img className={css(styles.photo)} src={p.url} alt={p.url} />
                <Button
                  className={css(styles.photoDeleteButton)}
                  block
                  danger
                  disabled={photoLoading || isDisabled}
                  onClick={() => onDeleteClick(p)}
                >
                  {activeLang.treeDetailPage.photoEditing.deletePhoto}
                </Button>
              </Card>
            ))}
        </div>
      </Grid>
      <Grid item xs={12}>
        {activePhoto ? (
          <Fragment>
            <ImageEditor
              activePhoto={activePhoto}
              form={form}
              loading={photoLoading}
              callUpdatePhoto={onPhotoEdit}
            />
            {activePhoto?.imageCopy?.url && (
              <Grid item xs={12}>
                {photoLoading ? (
                  <CircularProgress />
                ) : (
                  <Fragment>
                    <h3> {activeLang.treeDetailPage.photoEditing.imageCopy}</h3>
                    <div className={css(styles.copyWrap)}>
                      <img
                        className={css(styles.copy)}
                        src={activePhoto.imageCopy.url}
                        alt="edited"
                      />
                    </div>
                  </Fragment>
                )}
              </Grid>
            )}
          </Fragment>
        ) : (
          <p>{activeLang.treeDetailPage.photoEditing.noActivePhoto}</p>
        )}
      </Grid>
    </Grid>
  ) : (
    <NoDataTab title={activeLang.treeDetailPage.noPhotos} />
  );
};
