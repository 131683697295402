import { Grid, Container } from '@material-ui/core';
import { css } from 'aphrodite';
import { parseTreeToForm } from '../../../helpers/treeHelpers';
import { useRole } from '../../../hooks/useRole';
import { MapComponent } from '../../shared/GoogleMap';
import TreeDetailForm from '../components/TreeDetailForm/TreeDetailForm';
import { useTreeDetailState } from '../TreeDetailProvider/TreeDetailStateProvider';
import { RejectNotice } from './RejectNotice';
import { styles } from './TreeDetailPageForm.styles';
import { AppOperatorSelect } from '../components/AppOperatorSelect/AppOperatorSelect';
import { useMutation, useQuery } from 'react-query';
import { loadOperators, putOperator } from '../../../helpers/apiHelper';
import { useCurrentLanguage } from '../../../translations/languageSelector';

const TreeDetailPageForm = () => {
  const { isAdmin } = useRole();
  const activeLang = useCurrentLanguage();
  const { activeTree } = useTreeDetailState();

  const isVisible = !isAdmin && activeTree?.status === 'REJECTED';

  const { data } = useQuery(['operators'], () => loadOperators());

  const { mutate: onAddOperator, isLoading } = useMutation(putOperator);

  const handleAddOperator = (operatorId: number) => {
    if (operatorId) {
      onAddOperator({
        treeId: activeTree!.id,
        operatorId,
        isAdmin,
      });
    }
  };

  const operators = data?.data.map(operator => {
    return { value: operator.id, label: operator.username };
  });

  return (
    <div>
      {isVisible && (
        <Container className={css(styles.containerReject)}>
          <RejectNotice rejectMessage={activeTree.rejectedReason} />
        </Container>
      )}
      {isAdmin && (
        <Container className={css(styles.selectContainer)} maxWidth="xl">
          <Grid sm={4} className={css(styles.flexHalf)}>
            <AppOperatorSelect
              name="operators"
              options={operators ? operators : []}
              label={activeLang.summaryOfProjects.operator}
              handleOperator={handleAddOperator}
              disabled={isLoading}
              value={activeTree?.operator ? activeTree?.operator : null}
            />
          </Grid>
        </Container>
      )}

      <Container className={css(styles.container)} maxWidth="xl">
        <Grid className={css(styles.flexHalf)} xl={6}>
          {activeTree && (
            <MapComponent
              isMarkerShown
              activeTree={activeTree}
              zoom={20}
              loadingElement={<div style={{ height: `100%` }} />}
              activatedStreetView={false}
            />
          )}
        </Grid>
        <Grid xl={6} className={css(styles.flexHalf)}>
          {activeTree && (
            <TreeDetailForm treeFormData={parseTreeToForm(activeTree)} />
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default TreeDetailPageForm;
