import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from '@material-ui/core';
import { ExpandMoreOutlined } from '@material-ui/icons';
import { FC } from 'react';
import { useTensileTest } from '../../../../contexts/TensilTestsContext';
import { useCurrentLanguage } from '../../../../translations/languageSelector';
import { AppChart } from '../../../AppChart';
import { getMeterBgColor } from './TensilTests.styles';
import { TensilTestInputs } from '../../../../interfaces/tenstilTest';
import { prepareElastometersChartsData, prepareInclinometersChartsData } from '../../../../helpers/tensilTestsHelpers';

type GraphAccordionsProps = {};

export const GraphAccordions: FC<GraphAccordionsProps> = () => {
  const activeLang = useCurrentLanguage();

  const {
    computation: {
      dataRange: {
        min,
        max,
        pickBoundary,
      },
    },
    common: {
      activeTensileTest: tensileTest,
    },
  } = useTensileTest();

  const { availableElastometersList, elastometersData } = prepareElastometersChartsData(tensileTest);
  const {
    availableInclinometersList,
    defaultVisibleInclinometersList,
    inclinometersData,
  } = prepareInclinometersChartsData(tensileTest);

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreOutlined color="primary" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {activeLang.treeDetailPage.tensilTest.force}
        </AccordionSummary>
        <AccordionDetails>
          <Grid>
            <AppChart
              data={tensileTest.data.inputData.map(({ number: num, force }) => ({ number: num, force }))}
              xDataKey="number"
              yDataKeys={['force']}
              yDataUnit={TensilTestInputs.force100.unit}
              selectValue={pickBoundary}
              min={min}
              max={max}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreOutlined color="primary" />}
          aria-controls="deformation-content"
          id="deformation-header"
        >
          {activeLang.treeDetailPage.tensilTest.deformation}
        </AccordionSummary>
        <AccordionDetails>
          <Grid style={{ width: '100%' }}>
            <AppChart
              data={elastometersData}
              xDataKey="number"
              yDataKeys={availableElastometersList}
              yDataUnit={TensilTestInputs.elasto90.unit}
              strokeColorsArr={availableElastometersList.map(getMeterBgColor)}
              selectValue={pickBoundary}
              min={min}
              max={max}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreOutlined color="primary" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {activeLang.treeDetailPage.tensilTest.inclination}
        </AccordionSummary>
        <AccordionDetails>
          <Grid>
            <AppChart
              data={inclinometersData}
              xDataKey="number"
              yDataKeys={availableInclinometersList}
              yDataUnit={TensilTestInputs.inclino80x.unit}
              defaultActiveLines={Object.fromEntries(defaultVisibleInclinometersList)}
              strokeColorsArr={availableInclinometersList.map(getMeterBgColor)}
              selectValue={pickBoundary}
              min={min}
              max={max}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
