/**
 * Get middle coordinate between two points
 */
export const getLineCenter = (
  pointOne: google.maps.LatLngLiteral,
  pointTwo: google.maps.LatLngLiteral,
): google.maps.LatLngLiteral => {
  // according Google Maps this should be working but IS NOT,
  // new code taken from Mobile TreeScanner app
  // const location = new google.maps.LatLng(center.lat(), center.lng());
  const location: google.maps.LatLngLiteral = {
    lat: (pointOne.lat + pointTwo.lat) / 2,
    lng: (pointOne.lng + pointTwo.lng) / 2,
  };
  return location;
};
