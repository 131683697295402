import { Fragment, FC, Dispatch } from 'react';
import { Position } from '../../../../../interfaces/canvas';
import { CanvasEditOption } from '../../../../../interfaces/enums';
import { Base } from '../Base/Base';
import { CenterPoint } from '../CenterPoint/CenterPoint';
import { Crown } from '../Crown/Crown';
import { Range } from '../Range/Range';
import { CanvasActions, CanvasActionTypes } from '../types';

type WholePartProps = {
  zoom: number;
  imageWidth: number;
  base: Position[];
  range: Position[];
  crownPoints: Position[];
  centerPoint?: Position;
  topPoint?: Position;
  activeOption: CanvasEditOption;
  setIsDraggingPoint: (isDraggingPoint: boolean) => void;
  dispatchActions: Dispatch<CanvasActions>;
};

const WholePart: FC<WholePartProps> = ({
  zoom,
  imageWidth,
  base,
  range,
  topPoint,
  crownPoints,
  centerPoint,
  activeOption,
  setIsDraggingPoint,
  dispatchActions,
}) => {
  return (
    <Fragment>
      <Base
        draggable={activeOption === CanvasEditOption.Base}
        base={base}
        zoom={zoom}
        setIsDraggingPoint={setIsDraggingPoint}
        dragBasePoint={point =>
          dispatchActions({
            type: CanvasActionTypes.UPDATE_BASE_POINT,
            payload: point,
          })
        }
      />
      <CenterPoint
        setIsDraggingPoint={setIsDraggingPoint}
        zoom={zoom}
        draggable={activeOption === CanvasEditOption.CenterPoint}
        position={centerPoint}
        dragScalePoint={point =>
          dispatchActions({
            type: CanvasActionTypes.SET_CENTER_POINT,
            payload: point,
          })
        }
      />
      <Range
        draggable={activeOption === CanvasEditOption.Range}
        range={range}
        zoom={zoom}
        setIsDraggingPoint={setIsDraggingPoint}
        dragRangePoint={point =>
          dispatchActions({
            type: CanvasActionTypes.UPDATE_RANGE_POINT,
            payload: point,
          })
        }
      />
      <CenterPoint
        setIsDraggingPoint={setIsDraggingPoint}
        zoom={zoom}
        draggable={activeOption === CanvasEditOption.CenterPoint}
        position={topPoint}
        dragScalePoint={point =>
          dispatchActions({
            type: CanvasActionTypes.SET_TOP_POINT,
            payload: point,
          })
        }
      />

      <Crown
        draggable={activeOption === CanvasEditOption.Crown}
        zoom={zoom}
        crownPoints={crownPoints}
        setIsDraggingPoint={setIsDraggingPoint}
        dragCrownPoint={point =>
          dispatchActions({
            type: CanvasActionTypes.UPDATE_CROWN_POINT,
            payload: point,
          })
        }
      />
    </Fragment>
  );
};

export default WholePart;
