import { useMemo, FC, useState, useCallback, useEffect } from 'react';
import Table from 'antd/lib/table';
import { css } from 'aphrodite';
import {
  Tomogram,
  TomogramTypeSelectionValues,
  TreeScannerDataStatus,
} from '../../../interfaces/tree';
import { styles } from './TomogramTab.styles';
import { Grid, Button } from '@material-ui/core';
import { Upload } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import useTomograms from '../../../hooks/useTomograms';
import { useCurrentLanguage } from '../../../translations/languageSelector';
import ImageModal from './ImageModal';
import { AppErrorSnackbar } from '../../appSnackbars/AppSnackbars';
import Modal from 'antd/lib/modal/Modal';
import { useSelector } from 'react-redux';
import { getActiveTreeById } from '../../../store/trees/selectors';
import { TomogramCut } from './TomogramCut';
import axios from 'axios';
import { TreeDetailActions } from '../../treeDetailPageNew/TreeDetailProvider/types';
import { SiteTab } from '../../../interfaces/enums';
import { useTreeDetailState } from '../../treeDetailPageNew/TreeDetailProvider/TreeDetailStateProvider';
import { NoDataTab } from '../../treeDetailPageNew/components/NoDataTab/NoDataTab';

const TomogramsTab: FC = () => {
  const activeLang = useCurrentLanguage();

  const [activeImage, setActiveImage] = useState<{
    id: number | null;
    url: string | null;
    scaleReal: number | null;
    scaleImage: number | null;
    tomogramType: TomogramTypeSelectionValues | null;
  }>({
    id: null,
    url: null,
    scaleImage: null,
    scaleReal: null,
    tomogramType: null,
  });

  const [activeTomogram, setActiveTomogram] = useState<any>(undefined);
  const activeTree = useSelector(getActiveTreeById);
  const [activeNote, setActiveNote] = useState<string | null>(null);
  const { dispatchTreeDetailState } = useTreeDetailState();

  const {
    tomograms,
    removeTomogramImage,
    sendTomogramImage,
    loading,
    error,
    editTomogramImage,
  } = useTomograms(activeTree?.tree_scanner_tomograms || []);

  useEffect(() => {
    dispatchTreeDetailState({
      type: TreeDetailActions.SET_CURRENT_TAB,
      // @ts-ignore
      payload: SiteTab.Tomograms,
    });
  }, [dispatchTreeDetailState]);

  const tomogramsMemo = useMemo(
    () =>
      tomograms
        ? tomograms.map((tom, index) => ({
          ...tom,
          name: `Tomogram ${index + 1}`,
        }))
        : [],
    [tomograms],
  );

  const downloadTomogramImage = ({ imageUrl }) => {
    axios({
      url: imageUrl,
      method: 'GET',
      responseType: 'blob',
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'tomogram-image.jpg');
      document.body.appendChild(link);
      link.click();
    });
  };

  const columns = useMemo(
    () => [
      { title: 'Tomogram', dataIndex: 'name', key: 'name' },
      {
        title: 'Výšková úroveň',
        dataIndex: 'height',
        key: 'height',
        render: (height: number, tom: Tomogram) => `${height} cm`,
      },
      {
        title: 'Snímek',
        dataIndex: 'image',
        key: 'image',
        render: (image, tomogram: Tomogram) =>
          image ? (
            <Button
              disabled={loading}
              className={css(styles.show)}
              variant="text"
              onClick={() =>
                setActiveImage({
                  url: typeof image === 'string' ? image : image.url,
                  ...tomogram,
                })
              }
            >
              {activeLang.tomograms.showImage}
            </Button>
          ) : (
            <Upload
              accept="image/*"
              customRequest={({ file }) => {
                sendTomogramImage(tomogram.id, file);
              }}
              maxCount={1}
              showUploadList={false}
              disabled={loading}
            >
              <Button
                disabled={loading}
                className={css(styles.upload)}
                variant="outlined"
              >
                <PlusCircleOutlined className={css(styles.uploadIcon)} />
                {activeLang.treeDetailPage.tomograms.uploadImage}
              </Button>
            </Upload>
          ),
      },
      {
        dataIndex: 'image',
        key: 'image',
        render: (image, tom: Tomogram) =>
          image ? (
            <Button
              disabled={loading || isDisabled}
              className={css(styles.download)}
              variant="text"
              onClick={() =>
                downloadTomogramImage({
                  imageUrl: typeof image === 'string' ? image : image.url,
                })
              }
            >
              {activeLang.treeDetailPage.tomograms.downloadImage}
            </Button>
          ) : (
            ''
          ),
      },
      {
        dataIndex: 'image',
        key: 'image',
        render: (image, tom: Tomogram) =>
          image ? (
            <Button
              disabled={loading || isDisabled}
              className={css(styles.delete)}
              variant="text"
              onClick={() => removeTomogramImage(tom.id)}
            >
              {activeLang.treeDetailPage.tomograms.deleteImage}
            </Button>
          ) : (
            ''
          ),
      },

      {
        dataIndex: 'note',
        key: 'note',
        render: (_, tom: Tomogram) => (
          <Button
            disabled={!tom.note}
            className={css(!tom.note ? styles.noteDisabled : styles.show)}
            variant="text"
            onClick={() => setActiveNote(tom.note)}
          >
            {activeLang.tomograms.showNote}
          </Button>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, removeTomogramImage, sendTomogramImage, tomograms],
  );

  const clearActiveImage = useCallback(
    () =>
      setActiveImage({
        id: null,
        url: null,
        scaleImage: null,
        scaleReal: null,
        tomogramType: null,
      }),
    [],
  );

  const onSaveImg = useCallback(
    async (
      img: string,
      scaleI: number,
      scaleR: number,
      tomType: TomogramTypeSelectionValues,
    ) => {
      if (activeImage.id && activeImage.url) {
        const rImageType = /data:(image\/.+);base64,/;

        const base64 = img.replace(rImageType, '');

        editTomogramImage(activeImage.id, base64, scaleI, scaleR, tomType);
        clearActiveImage();
      }
    },
    [activeImage.id, activeImage.url, clearActiveImage, editTomogramImage],
  );

  const onTomogramSelect = (id: number) => {
    const selectedTomogram = tomogramsMemo.find(tomogram => tomogram.id === id);
    setActiveTomogram(selectedTomogram);
  };

  const isDisabled = useMemo(() => {
    if (activeTree?.status === TreeScannerDataStatus.FINISHED) {
      return true;
    }
  }, [activeTree]);

  return (
    <>
      <Grid className={css(styles.container)}>
        <AppErrorSnackbar
          type="error"
          error={error}
          message="Nepovedlo se nahrát tomogram"
        />
        {tomogramsMemo.length > 0 ? (
          <>
            <Table
              rowKey={tom => tom.id}
              dataSource={tomogramsMemo}
              columns={columns}
              pagination={false}
              className={css(styles.table)}
            />
            <TomogramCut
              onTomogramSelect={onTomogramSelect}
              tomograms={tomogramsMemo}
              activeTomogram={activeTomogram}
            />
          </>
        ) : (
          <NoDataTab title={activeLang.treeDetailPage.tomograms.noData} />
        )}
        {activeImage.url && (
          <ImageModal
            tomogramType={activeImage.tomogramType}
            scaleImage={activeImage.scaleImage}
            scaleReal={activeImage.scaleReal}
            url={activeImage.url}
            isVisible={!!activeImage.url}
            saveImg={onSaveImg}
            onClose={clearActiveImage}
          />
        )}
        <Modal
          visible={activeNote !== null}
          onOk={() => setActiveNote(null)}
          onCancel={() => setActiveNote(null)}
        >
          {activeNote}
        </Modal>
      </Grid>
    </>
  );
};

export default TomogramsTab;
