import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useCurrentLanguage } from '../../../../translations/languageSelector';
import { TensileTestPrecalculateElastometerTable, TensileTestPrecalculateInclinometersTable } from './TensileTestsPrecalculateTable';
import { Grid, Typography } from '@material-ui/core';
import AppButton from '../Form/AppButton/AppButton';
import { FunctionsOutlined } from '@material-ui/icons';
import { LoadingOutlined, PushpinFilled, PushpinOutlined } from '@ant-design/icons';
import { useTensileTest } from '../../../../contexts/TensilTestsContext';

type PrecalculateTablesProps = {
  isFormPinned: boolean;
  pinFormCallback: () => void;
};
export const TensileTestPrecalculateTables: FC<PrecalculateTablesProps> = ({ isFormPinned, pinFormCallback }) => {
  const activeLang = useCurrentLanguage();
  const {
    handleSubmit,
    // formState, // TODO: add error messages from Form Status
  } = useFormContext();

  const {
    computation: {
      results: {
        precalculations,
      },
      isLoading,
      calculateProtocolValues,
    },
    safetyCoeficientTable: {
      protocol,
      availableElastometers,
      areProtocolValuesValid,
    },
  } = useTensileTest();

  const treeValues = precalculations?.treeValues;

  let isSubmitDisabled = isLoading /*|| !formState.isValid*/;
  let disabledSubmitButtonTitle = '';
  if (!areProtocolValuesValid(protocol)) {
    isSubmitDisabled = true;
    disabledSubmitButtonTitle = 'Protocol values should be selected first, before trying to calculate values';
  }



  return (
    <>
      <Typography variant="h4" style={{ fontWeight: 700, margin: 40 }}>
        {activeLang.treeDetailPage.tensilTest.precalculations}
      </Typography>

      {precalculations?.elastometers.map(({ commonValues, quartilValues }, index) => (
        <TensileTestPrecalculateElastometerTable
          key={availableElastometers[index]}
          name={availableElastometers[index]}
          treeValues={treeValues!}
          commonValues={commonValues}
          quartilValues={quartilValues}
        />
      ))}

      <TensileTestPrecalculateInclinometersTable quartilValues={precalculations?.inclinometers!} />

      <Grid container style={{ paddingRight: 10, marginTop: 40, }} justifyContent="space-between">
        <Grid item>
          <AppButton
            onClick={pinFormCallback}
            variant="outlined"
            startIcon={isFormPinned ? <PushpinFilled /> : <PushpinOutlined />}
          >
            {isFormPinned ? 'Unpin form' : 'Pin form' /* TODO: translate */}
          </AppButton>
        </Grid>
        <Grid item>
          <AppButton
            onClick={handleSubmit(calculateProtocolValues)}
            variant="contained"
            color="primary"
            disabled={isSubmitDisabled}
            startIcon={isLoading ? <LoadingOutlined /> : <FunctionsOutlined />}
            tooltipTitle={disabledSubmitButtonTitle}
          >
            {activeLang.treeDetailPage.tensilTest.calculateProtocol}
          </AppButton>
        </Grid>
      </Grid>
    </>
  );
};
