import { Position } from '../../../../interfaces/canvas';
import { CanvasEditOption } from '../../../../interfaces/enums';
import { AnalysisLevelType } from '../../../../interfaces/tree';
import { DialogType } from '../../../../interfaces/enums';

export type CanvasState = {
  analysisLevel: AnalysisLevelType;
  base: Position[];
  scale: Position[];
  range: Position[];
  topPoint?: Position;
  crownPoints: Position[];
  trunkPoints: Position[];
  centerPoint?: Position;
  zoom: number;
  scaleLength: number;
  activeOption: CanvasEditOption;
  isTensilTestsIncluded?: boolean;
};

export enum CanvasActionTypes {
  SET_BASE_POINT = 'SET_BASE_POINT',
  SET_RANGE_POINT = 'SET_RANGE_POINT',
  UPDATE_BASE_POINT = 'UPDATE_BASE_POINT',
  SET_CROWN_POINT = 'SET_CROWN_POINT',
  UPDATE_CROWN_POINT = 'UPDATE_CROWN_POINT',
  SET_TRUNK_POINT = 'SET_TRUNK_POINT',
  UPDATE_TRUNK_POINT = 'UPDATE_TRUNK_POINT',
  SET_ZOOM = 'SET_ZOOM',
  SET_ACTIVE_OPTION = 'SET_ACTIVE_OPTION',
  SET_STATE = 'SET_STATE',
  SET_CENTER_POINT = 'SET_CENTER_POINT',
  UPDATE_RANGE_POINT = 'UPDATE_RANGE_POINT',
  SET_TOP_POINT = 'SET_TOP_POINT',
  RESET = 'RESET',
  RESET_ACTIVE_OPTION = 'RESET_ACTIVE_OPTION',
  BACK = 'BACK',
}

export type CanvasActions =
  | { type: CanvasActionTypes.SET_BASE_POINT; payload: Position }
  | { type: CanvasActionTypes.UPDATE_BASE_POINT; payload: Position }
  | { type: CanvasActionTypes.SET_CROWN_POINT; payload: Position }
  | { type: CanvasActionTypes.UPDATE_CROWN_POINT; payload: Position }
  | { type: CanvasActionTypes.SET_TRUNK_POINT; payload: Position }
  | { type: CanvasActionTypes.UPDATE_TRUNK_POINT; payload: Position }
  | { type: CanvasActionTypes.SET_ZOOM; payload: number }
  | { type: CanvasActionTypes.SET_ACTIVE_OPTION; payload: CanvasEditOption }
  | { type: CanvasActionTypes.SET_STATE; payload: CanvasState }
  | { type: CanvasActionTypes.SET_CENTER_POINT; payload: Position }
  | { type: CanvasActionTypes.SET_RANGE_POINT; payload: Position }
  | { type: CanvasActionTypes.UPDATE_RANGE_POINT; payload: Position }
  | { type: CanvasActionTypes.SET_TOP_POINT; payload: Position }
  | { type: CanvasActionTypes.RESET; payload: DialogType }
  | { type: CanvasActionTypes.RESET_ACTIVE_OPTION; payload: DialogType }
  | { type: CanvasActionTypes.BACK; payload: DialogType };
