import { StyleSheet } from 'aphrodite';
import { colors } from '../../../../styles/colors';

const imageHeight = 330;

export const styles = StyleSheet.create({
  breadcrumb: {
    marginLeft: 12,
  },
  breadcrumbItem: {
    border: `1px solid ${colors.grayBtn}`,
    padding: '4px 4px',
    cursor: 'pointer',
  },
  breadcrumbItemActive: {
    border: `1px solid ${colors.grayBtn}`,
    backgroundColor: `${colors.lightBlue}`,
    padding: '4px 4px',
    transition: 'all 0.5s ease-in-out',
  },
  breadcrumbLinkActive: {
    color: `${colors.white}`,
    background: 'none',
    border: 'none',
  },
  breadcrumbLink: {
    color: `${colors.textDark}`,
    background: 'none',
    border: 'none',
    cursor: 'pointer',
  },
  scrollbar: {
    overflowY: 'auto',
    flex: 1,
    maxWidth: '100%',
    position: 'relative',
    overflowX: 'hidden',
    minHeight: imageHeight,
  },
  imageBox: {
    objectFit: 'fit',
    flexDirection: 'column',
    position: 'relative',
    padding: 12,
  },
  fab: {
    position: 'absolute',
    top: 12,
    left: 12,
    zIndex: 9,
  },
});
