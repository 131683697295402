import { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { css } from 'aphrodite';
import { styles } from './NoDataTab.styles';
import { useCurrentLanguage } from '../../../../translations/languageSelector';

type NoDataTabProps = {
  title: string;
};

export const NoDataTab: FC<NoDataTabProps> = ({ title }) => {
  const activeLang = useCurrentLanguage();

  return (
    <Grid container className={css(styles.cover)} alignItems="center">
      <Typography className={css(styles.title)}>
        {activeLang.error.heading}
        <span className={css(styles.span)}>!</span>
      </Typography>
      <Typography className={css(styles.text)}>{title}</Typography>
    </Grid>
  );
};
