import { FC } from 'react';
import { Form, Input, Button, Row } from 'antd';
import {
  UserOutlined,
  LockOutlined,
  RightCircleOutlined,
} from '@ant-design/icons';
import { useCurrentLanguage } from '../../translations/languageSelector';
import { css } from 'aphrodite';
import { Header } from '../shared/Header';
import { LoadingState } from '../../interfaces/enums';
import { UserDTO } from '../../interfaces/user';
import { styles } from './LoginPage.styles';
import { AppSnackbar } from '../appSnackbars/AppSnackbars';
export interface StateProps {
  requestState: LoadingState;
  loggedUser: UserDTO | undefined;
}
export interface DispatchProps {
  checkLogin: (userName: string, password: string) => void;
  logOut: () => void;
}

type LoginProps = DispatchProps & StateProps;

export const LoginPage: FC<LoginProps> = props => {
  const activeLang = useCurrentLanguage();
  const [form] = Form.useForm();

  const handleSubmit = async () => {
    if (!form) {
      return;
    }
    const values = await form.validateFields();
    const { userName, password } = values;
    props.checkLogin(userName, password);
  };

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.formContainer)}>
        <div className={css(styles.radius)}>
          <Header
            wholeLogo={true}
            logOut={props.logOut}
            loggedUser={props.loggedUser}
          />
        </div>
        <div className={css(styles.form)}>
          <Form className="login-form" layout="vertical" form={form}>
            <AppSnackbar
              description={activeLang.loginPage.error.wrong}
              requestState={props.requestState}
              type="error"
            />

            <Form.Item
              label={activeLang.loginPage.user}
              name="userName"
              rules={[
                {
                  required: true,
                  message: activeLang.loginPage.error.userName,
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className={css(styles.icon)} />}
                placeholder="Username"
              />
            </Form.Item>
            <Form.Item
              label={activeLang.loginPage.password}
              name="password"
              rules={[
                {
                  required: true,
                  message: activeLang.loginPage.error.password,
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className={css(styles.icon)} />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item>
              <Row justify="end">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  onClick={() => handleSubmit()}
                  loading={
                    props.requestState === LoadingState.Loading ? true : false
                  }
                >
                  {activeLang.loginPage.button} <RightCircleOutlined />
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
