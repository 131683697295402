import { StyleSheet } from 'aphrodite';
import { colors } from '../../../../styles/colors';

export const styles = StyleSheet.create({
  cover: {
    height: '80vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: {
    fontSize: 40,
    color: colors.greenPrimary,
    paddingBottom: 30,
  },
  text: {
    fontSize: 20,
    color: colors.textDark,
  },
  span: {
    fontSize: 50,
    color: colors.textDark,
  },
});
