import { ButtonGroup } from '@material-ui/core';
import { css } from 'aphrodite';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { requiredRule } from '../../../../helpers/formHelpers';
import { treeImagesPartition } from '../../../../helpers/treeHelpers';
import { useDoneAnalysis } from '../../../../hooks/useDoneAnalysis';
import { useRefuseAnalysis } from '../../../../hooks/useRefuseAnalysis';
import { useRole } from '../../../../hooks/useRole';
import {
  TreeProfile,
  TreeProtectionFactor,
} from '../../../../interfaces/enums';
import { Tree, TreeScannerDataStatus } from '../../../../interfaces/tree';
import { useCurrentUser } from '../../../../hooks/useCurrentUser';
import { useCurrentLanguage } from '../../../../translations/languageSelector';
import { useTreeDetailState } from '../../TreeDetailProvider/TreeDetailStateProvider';
import AppButton from '../Form/AppButton/AppButton';
import AppInput from '../Form/AppInput/AppInput';
import AppRadio from '../Form/AppRadio/AppRadio';
import AppSelect from '../Form/AppSelect/AppSelect';
import { styles } from './TreeDetailForm.styles';
import TreeDetailFormAlerts from './TreeDetailFormAlerts';
import {
  AnalysisLevelSelectOptions,
  TreeFormData,
  TreeFormProps,
  TreeProfileSelectOptions,
  TreeProtectionFactorSelectOptions,
} from './types';

const analysisLevelOptions: AnalysisLevelSelectOptions[] = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
];

// const announcementOptions: AnnouncementSelectOptions[] = [
//   { value: AnnouncementType.Broken, label: AnnouncementText.Broken },
//   { value: AnnouncementType.Chopped, label: AnnouncementText.Chopped },
//   { value: AnnouncementType.Detail, label: AnnouncementText.Detail },
// ];

const conditionOptions: { value: string; label: string }[] = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
];

// const mistletoeOptions: { value: string; label: string }[] = [
//   { value: '1', label: '1' },
//   { value: '2', label: '2' },
//   { value: '3', label: '3' },
//   { value: '4', label: '4' },
//   { value: '5', label: '5' },
// ];

const TreeDetailForm: FC<TreeFormProps> = ({ treeFormData }) => {
  const { isAdmin } = useRole();
  const loggedUser = useCurrentUser();
  const activeLang = useCurrentLanguage();

  const profileOptions: TreeProfileSelectOptions[] = useMemo(
    () => [
      { value: TreeProfile.City, label: activeLang.treeDetailPage.inputs.profile.city },
      { value: TreeProfile.Outskirts, label: activeLang.treeDetailPage.inputs.profile.outskirts },
      { value: TreeProfile.Village, label: activeLang.treeDetailPage.inputs.profile.village },
      { value: TreeProfile.Countryside, label: activeLang.treeDetailPage.inputs.profile.countryside },
    ],
    [activeLang],
  );

  const protectionFactorOptions: TreeProtectionFactorSelectOptions[] = useMemo(() => [
    { value: TreeProtectionFactor.FOREST_STAND, label: activeLang.treeDetailPage.inputs.protectionFactor.forestStand },
    { value: TreeProtectionFactor.ALLEY, label: activeLang.treeDetailPage.inputs.protectionFactor.alley },
    { value: TreeProtectionFactor.SOLITAIRE, label: activeLang.treeDetailPage.inputs.protectionFactor.solitaire },
  ],
    [activeLang],
  );

  const dispatch = useDispatch();
  const {
    activeTree,
    formDataLoading,
    formDataResponse,
    formDataError,
    sendTreeForm,
    loadTree,
  } = useTreeDetailState();

  const { onRefuse, refuseData, refuseError, refuseLoading } =
    useRefuseAnalysis();
  const { onDone, doneData, doneError, doneLoading } = useDoneAnalysis();
  const form = useForm<TreeFormData>({
    mode: 'onBlur',
    defaultValues: { ...treeFormData },
  });

  useEffect(() => {
    if (formDataResponse) {
      const savedTree = formDataResponse.tree_scanner_data as Tree;
      loadTree(savedTree.id);
    }
    if (activeTree?.analysisLevel) {
      form.setValue('analysisLevel', activeTree.analysisLevel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, formDataResponse, loadTree, activeTree?.analysisLevel]);

  const onSubmit = useCallback(
    (values: TreeFormData) => {
      if (loggedUser) {
        const dbhCounted = values.dbhCounted === '' ? null : values.dbhCounted;
        const dbhFromTs = values.dbh === '' ? null : values.dbh;
        sendTreeForm({
          ...values,
          operator: loggedUser.id,
          project: `${values.projectId}`,
          dbh: dbhCounted ? dbhCounted : dbhFromTs,
          treeTopHeight:
            values.treeTopHeight === '' ? null : values.treeTopHeight,
          tree_scanner_data: values.id,
          treeMetadata: activeTree!.treeMetadata.id,
          protectionFactor: !values.protectionFactor
            ? null
            : values.protectionFactor,
          trunkScanScreen: activeTree?.tree_scanner_analysis_data
            ?.trunkScanScreen
            ? activeTree?.tree_scanner_analysis_data?.trunkScanScreen
            : null,
        });
      }
    },
    [sendTreeForm, loggedUser, activeTree],
  );

  const validStatuses = useMemo(
    () => [
      TreeScannerDataStatus.COREPDF,
      TreeScannerDataStatus.READYFORCLIENT,
      TreeScannerDataStatus.FAILED,
    ],
    [],
  );

  const isInputDisabled = useMemo(() => {
    if (validStatuses.some(status => status === activeTree?.status)) {
      return true;
    }
    return false;
  }, [activeTree, validStatuses]);

  // Conditions for disabled "done" button
  const isDoneDisabled = useMemo(() => {
    if (validStatuses.some(status => status === activeTree?.status)) {
      return true;
    }

    // Disable if loading or there are no images in tree
    if (doneLoading || !activeTree?.tree_scanner_images) {
      return true;
    }

    // Disable if form is not valid or there is no analysis
    if (!form.formState.isValid || !activeTree?.tree_scanner_analysis_data) {
      return true;
    }

    // Get valid photos for creating new tree
    const { crownPhotos, trunkPhotos } = treeImagesPartition(
      activeTree.tree_scanner_images,
    );

    // Disable if there are no valid photos
    if (trunkPhotos.length === 0 || crownPhotos.length === 0) {
      return true;
    }

    // Disable if it's analysis level 3 or 4 and there is no trunkScan
    if (
      (activeTree.analysisLevel === '3' || activeTree.analysisLevel === '4') &&
      activeTree.trunkScanUrl === ''
    ) {
      return true;
    }

    // Disable if it's analysis level 4 and there is no tomograms
    if (
      activeTree.analysisLevel === '4' &&
      activeTree.tree_scanner_tomograms?.length === 0
    ) {
      return true;
    }

    // Else enable "done" button
    return false;
  }, [
    validStatuses,
    doneLoading,
    activeTree?.tree_scanner_images,
    activeTree?.tree_scanner_analysis_data,
    activeTree?.analysisLevel,
    activeTree?.trunkScanUrl,
    activeTree?.tree_scanner_tomograms?.length,
    activeTree?.status,
    form.formState.isValid,
  ]);

  const isRejectDisabled = useMemo(() => {
    if (refuseLoading) {
      return true;
    }

    if (!isAdmin) {
      return true;
    }

    if (validStatuses.some(status => status === activeTree?.status)) {
      return true;
    }

    if (activeTree?.status !== TreeScannerDataStatus.ORDERED) {
      return true;
    }

    return false;
  }, [activeTree?.status, isAdmin, refuseLoading, validStatuses]);

  const isSaveDisabled = useMemo(() => {
    if (formDataLoading) {
      return true;
    }

    if (validStatuses.some(status => status === activeTree?.status)) {
      return true;
    }

    return false;
  }, [activeTree?.status, formDataLoading, validStatuses]);

  return (
    <FormProvider {...form}>
      <TreeDetailFormAlerts
        isRefuseData={!!refuseData}
        isRefuseError={!!refuseError}
        isDone={!!doneData}
        isDoneError={!!doneError}
        formDataError={formDataError}
        isFormDataResponse={!!formDataResponse}
      />
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}
      >
        <div className={css(styles.inputGroup)}>
          <AppInput
            name="project"
            textFieldProps={{
              label: activeLang.treeDetailPage.header.project,
              disabled: true,
            }}
            required
          />
          <AppInput
            name="site"
            textFieldProps={{
              label: activeLang.treeDetailPage.site,
              disabled: true,
            }}
            required
          />
        </div>
        <div className={css(styles.inputGroup)}>
          <div className={css(styles.selectGroup)}>
            <AppSelect
              required
              name="analysisLevel"
              options={analysisLevelOptions}
              label={activeLang.treeDetailPage.inputs.analysisLevel}
              disabled={!!activeTree?.analysisLevel}
              rules={{ ...requiredRule }}
            />
            {/* <AppSelect
            required
            name="announcement"
            options={announcementOptions}
            label={activeLang.treeDetailPage.inputs.announcement}
          /> */}
          </div>
          <div className={css(styles.inputGroup)}>
            <AppInput
              name="taxon"
              textFieldProps={{
                label: activeLang.treeDetailPage.inputs.taxon,
                disabled: true,
              }}
            />
          </div>
        </div>
        <div className={css(styles.inputGroup)}>
          <AppInput
            name="treeTopHeight"
            showMessage
            textFieldProps={{
              label: `${activeLang.treeDetailPage.inputs.treeTopHeight.label}`,
              type: 'number',
              disabled: isInputDisabled,
            }}
          />
          <AppInput
            required
            name="height"
            showMessage
            textFieldProps={{
              label: `${activeLang.treeDetailPage.inputs.height.label}`,
              disabled: true,
            }}
          />
        </div>
        <div className={css(styles.inputGroup)}>
          <AppInput
            name="dbh"
            textFieldProps={{
              label: activeLang.treeDetailPage.inputs.dbh.treeScanner.label,
              disabled: true,
            }}
          />
          <AppInput
            name="dbhCounted"
            showMessage
            textFieldProps={{
              label: activeLang.treeDetailPage.inputs.dbh.calculated.label,
              type: 'number',
              disabled: isInputDisabled,
            }}
          />
        </div>
        <div className={css(styles.selectGroup)}>
          <AppSelect
            required
            name="profile"
            rules={{ ...requiredRule }}
            options={profileOptions}
            label={activeLang.treeDetailPage.inputs.profile.label}
            disabled={isInputDisabled}
          />
          <AppSelect
            required
            name="protectionFactor"
            rules={{ ...requiredRule }}
            options={protectionFactorOptions}
            label={activeLang.treeDetailPage.inputs.protectionFactor.label}
            disabled={isInputDisabled}
          />
        </div>
        <div className={css(styles.radioGroup)}>
          <AppRadio
            name="condition"
            rules={{ ...requiredRule }}
            options={conditionOptions}
            label={activeLang.treeDetailPage.inputs.condition}
            disabled={isInputDisabled}
          />
          {/* <AppRadio
            required
            name="mistletoe"
            rules={{ ...requiredRule }}
            options={mistletoeOptions}
            label={activeLang.treeDetailPage.inputs.mistletoe}
          /> */}
        </div>
        <ButtonGroup fullWidth>
          <AppButton
            disabled={isSaveDisabled}
            fullWidth
            color="primary"
            variant="contained"
            type="submit"
          >
            {activeLang.treeDetailPage.inputs.formButtons.save}
          </AppButton>
          <AppButton
            disabled={isDoneDisabled}
            fullWidth
            color="primary"
            variant="contained"
            onClick={onDone}
          >
            {activeLang.treeDetailPage.inputs.formButtons.done}
          </AppButton>
          {isAdmin && (
            <AppButton
              disabled={isRejectDisabled}
              fullWidth
              className={
                isRejectDisabled
                  ? css(styles.disabled)
                  : css(styles.refuseButton)
              }
              variant="contained"
              onClick={() => onRefuse(form.getValues())}
            >
              {activeLang.treeDetailPage.inputs.formButtons.refuse}
            </AppButton>
          )}
        </ButtonGroup>
      </form>
    </FormProvider>
  );
};

export default TreeDetailForm;
