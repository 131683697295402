import { FC, useCallback, useRef } from 'react';
import Konva from 'konva';
import { KonvaEventObject } from 'konva/types/Node';
import { Circle, Group, Text } from 'react-konva';
import { Position } from '../../../interfaces/canvas';
import { scalePoint } from '../../../styles/canvasStyles';

type TompogramPointProps = {
  position?: Position;
  newPoint: (point: Position) => void;
  draggable?: boolean;
  text?: string;
};

export const TomogramPoint: FC<TompogramPointProps> = ({
  newPoint,
  draggable,
  position,
  text,
}) => {
  const pointRef = useRef<Konva.Circle>(null);

  const onDragEnd = useCallback(
    (evt: KonvaEventObject<DragEvent>) => {
      const stage = evt.target.getStage();
      const pointerPosition = stage?.getPointerPosition();
      if (pointerPosition) {
        newPoint({
          x: pointerPosition.x,
          y: pointerPosition.y,
          id: evt.target.attrs.id,
        });
      }
    },
    [newPoint],
  );

  const getTextPosition = useCallback((p: Position) => {
    if (p.y - 25 > 0) {
      return { x: p.x, y: p.y - 15 };
    }

    return { x: p.x + 10, y: p.y - 5 };
  }, []);

  return (
    <Group draggable={draggable} onDragEnd={onDragEnd}>
      {position && (
        <>
          {text && (
            <Text
              text={text}
              fill={scalePoint.fillColor}
              fontFamily="Roboto"
              fontStyle="bold"
              fontSize={10}
              {...getTextPosition(position)}
            />
          )}
          <Circle
            ref={pointRef}
            x={position.x}
            y={position.y}
            {...scalePoint}
            fill={scalePoint.fillColor}
            radius={scalePoint.radius}
            strokeWidth={scalePoint.strokeWidth}
          />
        </>
      )}
    </Group>
  );
};
