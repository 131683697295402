import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { App } from './App';
import { DispatchProps, StateProps } from './App';
import { State } from './store/combinedReducers';
import { checkLogin, logOut, callRefreshUser } from './store/general/actions';

const mapStateToProps = (state: State): StateProps => ({
  loggedUser: state.general.loggedUser,
  requestState: state.general.requestState,
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  ...bindActionCreators(
    {
      checkLogin,
      callRefreshUser,
      logOut,
    },
    dispatch,
  ),
});

export const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);
