import { Badge, Grid } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { Tree } from '../../../../assets/icons/Tree';
import { TrunkCrossSection } from '../../../../assets/icons/TrunkCross';
import { useTensileTest } from '../../../../contexts/TensilTestsContext';
import { colors } from '../../../../styles/colors';
import { TensileTestElastometerRow } from './TensileTestElastometerRow';


export const TensileTestData: FC = () => {
  const { common: { activeTensileTest } } = useTensileTest();
  const directionData = activeTensileTest.directionData;
  const [positions, setPositions] = useState<{ x: number; y: number }[]>([]);
  const [circleWidth, setcircleWidth] = useState(0);

  const setup = () => {
    const width = 220;
    const coef = [
      [0, -1],
      [0.707, -0.707],
      [1, 0],
      [0.707, 0.707],
      [0, 1],
      [-0.707, 0.707],
      [-1, 0],
      [-0.707, -0.707],
    ];
    const center = { x: width / 2 - 10, y: width / 2 - 10 };
    setcircleWidth(width);
    setPositions(
      coef.map(c => {
        return { x: center.x * (1 + c[0]), y: center.y * (1 + c[1]) };
      }),
    );
  };

  useEffect(() => {
    setup();
  }, []);
  return (
    <>
      <Grid
        style={{
          minWidth: circleWidth,
          padding: 6,
          borderBottom: `1px solid ${colors.grey}`,
        }}
      >
        <Grid container style={{ position: 'relative' }}>
          <Grid
            item
            style={{ height: 240, width: '100%' }}
          >
            <Grid
              container
              style={{ height: '100%' }}
              justifyContent="center"
              alignItems="center"
            >
              <Grid style={{ width: 120, height: 120 }} item>
                <TrunkCrossSection />
              </Grid>
            </Grid>
          </Grid>
          <Grid style={{ position: 'absolute', width: '100%', height: 240 }}>
            {positions.map((p, index) => (
              <Grid
                key={`IncPos${p.x}${p.y}`}
                style={{
                  left: p.x,
                  top: p.y,
                  position: 'absolute',
                }}
              >
                <Badge
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: (circleWidth - 100) / 3,
                    height: (circleWidth - 100) / 3,
                    borderRadius: 20,
                    border: `1px solid ${colors.grey}`,
                    backgroundColor: directionData?.inclinometers[index]?.color,
                  }}
                >
                  <Grid>{directionData?.inclinometers[index]?.name}</Grid>
                </Badge>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{
          position: 'relative',
          padding: '12px 0',
        }}
      >
        <Tree />
        <Grid
          container
          style={{
            gap: 4,
            position: 'absolute',
            bottom: 30,
            padding: '0 12px',
          }}
        >
          {directionData?.elastomers
            .slice(0)
            .reverse()
            .map((direction, index) => {
              const newIndex = directionData.elastomers.length - 1 - index;
              return (
                <TensileTestElastometerRow
                  key={`TensileTestDirectionLevelRow${newIndex}`}
                  data={direction}
                />
              );
            })}
        </Grid>
      </Grid>
    </>
  );
};
