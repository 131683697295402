import { StyleSheet } from 'aphrodite';
export const styles = StyleSheet.create({
  options: {
    marginBottom: 18,
    display: 'flex',
    justifyContent: 'center',
  },
  optionsRight: {
    padding: '0 15px 18px 15px',
    display: 'flex',
    justifyContent: 'flex-start',
    gap: 40,
    flexDirection: 'column',
  },
  zoomWrap: { margin: 10 },
  middleButtons: {
    display: 'flex',
    flexDirection: 'row',
  },
});
