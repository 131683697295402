import { StyleSheet } from 'aphrodite';
import { colors } from '../../styles/colors';

export const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 30,
  },
  success: {
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 768,
    width: '100%',
    flexDirection: 'column',
    minHeight: '100vh',
    margin: '0 auto',
  },
  innerContainer: {
    maxWidth: 768,
    width: '100%',
    flexDirection: 'column',
  },
  iconWrap: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    display: 'flex',
  },
  icon: {
    width: '135px',
    height: '135px',
  },
  heading: {
    color: colors.purple,
    fontWeight: 'bold',
    marginTop: 20,
  },
  rules: {
    backgroundColor: colors.peach,
    padding: 10,
    borderRadius: 8,
    fontSize: 12,
    marginTop: 50,
  },
  rulesHeader: {
    fontSize: 14,
  },
  inputPart: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 50,
  },
  btn: {
    color: colors.white,
    width: '100%',
    display: 'block',

    marginTop: 20,
    borderRadius: '100px',
  },
  submitBtn: {
    backgroundColor: colors.greenPrim,
  },
  disabledBtn: {
    backgroundColor: colors.grey,
  },
  rule: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: '5px',
  },
});
