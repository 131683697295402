import {
  ButtonGroup,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  makeStyles,
} from '@material-ui/core';
import { FC } from 'react';
import { useCurrentLanguage } from '../../../../translations/languageSelector';
import { AppTextArea } from '../../../treeDetailPageNew/components/Form/AppTextArea/AppTextArea';
import AppButton from '../../../treeDetailPageNew/components/Form/AppButton/AppButton';
import { styles } from '../CalculationsTab.styles';
import { css } from 'aphrodite';

const useStyles = makeStyles({
  paper: {
    width: 400,
    position: 'fixed',
    margin: 0,
  },
});

type RejectReasonDialogProps = {
  handleClose: () => void;
  isOpen: boolean;
  isRejectDisabled: boolean;
  isOperator: boolean;
};

export const RejectReasonDialog: FC<RejectReasonDialogProps> = ({
  handleClose,
  isOpen,
  isRejectDisabled,
  isOperator,
}) => {
  const activeLang = useCurrentLanguage();
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle>
        {activeLang.treeDetailPage.calculations.rejectedReason}
      </DialogTitle>
      <DialogContent>
        <AppTextArea
          rows={4}
          disabled={isOperator}
          name="rejectedReason"
          required
        />
      </DialogContent>
      <DialogActions>
        <ButtonGroup>
          <AppButton
            fullWidth
            color="primary"
            variant="contained"
            disabled={isRejectDisabled}
            type="button"
            onClick={handleClose}
          >
            {activeLang.treeDetailPage.calculations.cancel}
          </AppButton>
          <AppButton
            fullWidth
            className={
              isRejectDisabled ? css(styles.disabled) : css(styles.rejectButton)
            }
            variant="contained"
            type="submit"
            form="calculations-reject-reason-form"
            onClick={handleClose}
          >
            {activeLang.treeDetailPage.calculations.confirm}
          </AppButton>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
};
