import { RcFile } from 'antd/lib/upload';
import { useCallback, useState, useEffect } from 'react';
import { putTomogramFile, removeTomogramFile } from '../helpers/apiHelper';
import { Tomogram, TomogramTypeSelectionValues } from '../interfaces/tree';
import { useSendData } from './useSendData/useSendData';

const useTomograms = (treeTomograms: Tomogram[]) => {
  const {
    data: responseTomograms,
    putData,
    loading: sendImageLoading,
  } = useSendData<Tomogram>();
  const [tomograms, setTomograms] = useState<Tomogram[]>(treeTomograms);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    setTomograms(treeTomograms);
  }, [treeTomograms]);

  useEffect(() => {
    if (responseTomograms) {
      setTomograms(prev =>
        prev.map(tom => {
          if (tom.id === responseTomograms.id) {
            return {
              ...responseTomograms,
              image:
                typeof responseTomograms.image === 'string'
                  ? responseTomograms.image
                  : responseTomograms.image?.url!,
            };
          }
          return tom;
        }),
      );
    }
  }, [responseTomograms]);

  const sendTomogramImage = useCallback(
    async (id: number, file: string | Blob | RcFile) => {
      setLoading(true);
      const editFile = async () => {
        try {
          const { data } = await putTomogramFile(id, file);
          const newTomograms: Tomogram[] = tomograms.map(tom => {
            if (tom.id === id) {
              return {
                ...data,
              };
            }
            return tom;
          });
          setTomograms(newTomograms);
        } catch (error) {
          setError(error);
        }
        setLoading(false);
      };
      await editFile();
    },
    [tomograms],
  );

  const removeTomogramImage = useCallback(
    async id => {
      setLoading(true);
      const removeFile = async () => {
        try {
          const { data } = await removeTomogramFile(id);
          const newTomograms: Tomogram[] = tomograms.map(tom => {
            if (tom.id === id) {
              return {
                ...data,
              };
            }
            return tom;
          });
          setTomograms(newTomograms);
        } catch (error) {
          setError(error);
        }
        setLoading(false);
      };
      removeFile();
    },
    [tomograms],
  );

  const editTomogramImage = useCallback(
    (
      id: number,
      image: string,
      scaleImage: number,
      scaleReal: number,
      tomogramType: TomogramTypeSelectionValues,
    ) => {
      putData(`/tree-scanner-tomograms/${id}`, {
        image,
        scaleImage,
        scaleReal,
        tomogramType,
      });
    },
    [putData],
  );

  return {
    tomograms,
    removeTomogramImage,
    sendTomogramImage,
    editTomogramImage,
    error,
    loading: loading || sendImageLoading,
  };
};

export default useTomograms;
