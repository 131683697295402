import { DirectionOfLoad, TensilTestsDTO } from './tenstilTest';
import { CanvasResults, Position, TreeAnalysis } from './canvas';
import { TreeProfile, TreeProtectionFactor, TreeState } from './enums';
import { Project } from './project';
import { ReportData, TreeMetadataResponse } from './responses';
import { OperatorDTO, UserDTO } from './user';

/**
 * Typ fotky - kmen, celý strom nebo detail
 */
export enum PhotoType {
  Detail = 'detail',
  Whole = 'whole',
  Trunk = 'trunk',
}

/**
 * Typy hlašení
 */
export enum AnnouncementText {
  Chopped = 'Pokácený',
  Detail = 'Detailně posoudit',
  Broken = 'Havarijní stav',
}

export enum AnnouncementType {
  Chopped = 'Chopped',
  Detail = 'Detail',
  Broken = 'Broken',
  None = '',
}

export enum TomogramTypeSelectionValues {
  NONE = '',
  ONE = 1,
  TWO = 2,
  THREE = 3,
}
export enum TomogramTypeSelectionText {
  FAKKOP = 'Fakkop',
  PICUS = 'Picus',
  ARBOTOM = 'Arbotom',
}

/**
 * Levely analýzy
 */
export enum AnalysisLevelType {
  Two = '2',
  Three = '3',
  Four = '4',
}

/**
 * GPS souřadnice stromu
 */
export interface Location {
  latitude: number;
  longitude: number;
}

/**
 * Jedna fotka stromu
 */
export interface Photo {
  id: number;
  url: string;
  // poloha bodu, z ktorého bola fotka pořízena
  latitude: number;
  longitude: number;
  // kmen, koruna nebo detail
  type: PhotoType;
  // vertikálne balancovanie fotografie (naklonění mobilu)
  zAxis: number;
  analysed?: boolean;
  analysisResults?: CanvasResults;
  photoNumber?: number;
  description?: string;
  calibration?: any;
  exif?: any;
  imageCopy: { url: string };
  distance: number;
  created_at: DateString;
}

/**
 * Metadata o stormu
 */
export interface Metadata {
  analysis?: TreeAnalysis;
}

/**
 * Informace o přístroji, který fotku pořídil
 */
interface DeviceInfo {
  deviceId: string;
  deviceName: string;
  installationId: string;
  platform: string;
}

/**
 * Směr zátěže
 */

export type TomogramAnalysis = {
  id: number;
  sliceURL: string;
  slicePoints: Position[] | null;
  sliceTomogramResultURL: string;
  tomogramPoints: Position[] | null;
};

export interface Tomogram {
  height: number;
  note: string;
  image: string | { url: string; [key: string]: any } | null;
  id: number;
  scaleImage: number;
  scaleReal: number;
  tomogramType: TomogramTypeSelectionValues;
  tomogramAnalysisData?: TomogramAnalysis;
}

export enum TreeScannerDataStatus {
  CREATED = 'CREATED',
  ORDERED = 'ORDERED',
  ANALYZED = 'ANALYZED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  COREPDF = 'COREPDF',
  FINISHED = 'FINISHED',
  READYFORCLIENT = 'READYFORCLIENT',
  FAILED = 'FAILED',
}

/**
 * Celá data, která bude aplikace odesílat
 */
export interface Tree {
  id: number;
  treeNumber: number;
  analysisLevel: AnalysisLevelType;
  // projekt
  project: Project;
  // plocha
  site: string;
  taxon: string;
  // průměr kmene / vicekmenů;
  dbh?: number; // in cm
  height: number; // in meters
  tree_scanner_analysis_data: TreeAnalysis | undefined;
  tree_scanner_images: Photo[];
  tree_scanner_tomograms?: Tomogram[];
  treeMetadata: TreeMetadataResponse;
  // výška meřítka
  scale: number; // in meters
  // poloha stromu na mape
  latitude: number | undefined;
  longitude: number | undefined;
  deviceInfo: DeviceInfo | undefined;
  // směry zátěže
  directionOfLoad: DirectionOfLoad[];
  tensilTests: TensilTestsDTO[];
  // 4 body definujíci nejbližší konfliktní strukturu
  structure: Location[];
  // hlášení o stave stromu z webovky
  announcement: AnnouncementType;
  // důvod zamitnutí stromu
  rejectText?: string;
  // stav stromu - aktivni, neaktivni, schválený (defaultne nonActive)
  state?: TreeState;
  isAnalysed?: boolean | null;
  isApproved?: boolean | null;
  trunkScanUrl: string;
  treeSid?: string;
  protectionFactor: TreeProtectionFactor;
  profile: TreeProfile;
  mistletoe: number | string;
  condition: number | string;
  status: TreeScannerDataStatus | null;
  report: ReportData | null;
  rejectedReason?: string;
  operator: OperatorDTO | null;
  rejectedMessage?: string;
  createdBy: UserDTO;
}

// Analysis results for specific Tree
export interface AnalysisResult {
  analysisId?: number;
  treeId?: number;
  operatorId?: number;
  analysedTree: Tree;
  rejectionMessage?: string;
  status?: string;
}

// Analysis evaluation by admin
export interface AnalysisEvaluation {
  analysisId: number;
  status: AnalysisEvaluationStatus;
  rejectionMsg: string;
}

export enum AnalysisEvaluationStatus {
  rejected = 'rejected',
  accepted = 'accepted',
}

export interface AnalysedTomogram extends Tomogram {
  scaleImage: number;
  scaleReal: number;
  image: { url: string; [key: string]: any };
  tomogramType: TomogramTypeSelectionValues;
}

export type TreeCalculations = {
  areaName: string;
  groupName: string;
  dbh: number;
  taxon: string;
  crownPhotos: TreeCalculationsPhotos[];
  trunkPhotos: TreeCalculationsPhotos[];
  latitude: number;
  longitude: number;
  trunkScanUrl?: string;
  tomograms?: AnalysedTomogram[];
  sid: string;
  number: number;
  announcement: AnnouncementType;
  protectionFactor: TreeProtectionFactor;
  profile: TreeProfile;
  mistletoe: number | string;
  condition: number | string;
  trunkScanHeight: number | null;
};

export type DateString = string;

export type TreeCalculationsPhotos = {
  id: number;
  points: Position[];
  url: string;
  exif: any;
  distance: number;
  location: Location;
  zAxis: number;
  calibration?: any;
  created_at: DateString;
  photoNumber: number;
};

export enum OrderStatus {
  FAILED = 'FAILED',
  FINISHED = 'FINISHED',
  PROCESSING = 'PROCESSING',
  INITIAL = 'INITIAL',
}
