import * as React from 'react';

interface TabProps {
  tabName: string;
  currentTab: string;
}

export const Tab: React.FunctionComponent<TabProps> = ({
  children,
  currentTab,
  tabName,
}) => {
  return <React.Fragment>{tabName === currentTab && children}</React.Fragment>;
};
