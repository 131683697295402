import { FC, useState, useEffect } from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { AppAlertProps } from './types';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AppAlert: FC<AppAlertProps> = ({
  message,
  open,
  severity,
  className,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (open) {
      setIsOpen(open);
    }
  }, [open]);

  return (
    <Snackbar
      {...rest}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className={className}
    >
      <Alert severity={severity}>{message}</Alert>
    </Snackbar>
  );
};

export default AppAlert;
