import { Grid, Typography } from '@material-ui/core';

interface KonvaContainerProps {
  title: string;
  children: any;
}

export const KonvaContainer = ({ title, children }: KonvaContainerProps) => (
  <Grid container direction="column" alignItems="center">
    <Grid item xs={12}>
      <Typography variant="subtitle1">{title}</Typography>
    </Grid>
    <Grid item>{children}</Grid>
  </Grid>
);
