import { StyleSheet } from 'aphrodite';
export const styles = StyleSheet.create({
  editorModal: {
    width: '100%',
    height: '500px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btn: {
    marginRight: 4,
  },
  btnWrap: {
    marginTop: 30,
    display: 'flex',
  },
  modal: {
    width: '70%',
  },
  inputs: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 40,
  },
});
