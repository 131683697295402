import Konva from 'konva';
import { KonvaEventObject } from 'konva/types/Node';
import { Dispatch, FC, useCallback, useEffect, useRef, useState } from 'react';
import { Group, Image, Layer, Stage } from 'react-konva';
import useKeyPress from '../../../../../hooks/useKeyPress';
import { CommandKeyCodes, Position } from '../../../../../interfaces/canvas';
import { CanvasEditOption, DialogType } from '../../../../../interfaces/enums';
import { AnalysisLevelType } from '../../../../../interfaces/tree';
import TrunkPart from '../TrunkPart/TrunkPart';
import { CanvasActions, CanvasActionTypes } from '../types';
import WholePart from '../WholePart/WholePart';

type CanvasStageProps = {
  analysisLevel: AnalysisLevelType;
  canvasSize: number;
  image?: HTMLImageElement;
  zoom: number;
  base: Position[];
  range: Position[];
  crownPoints: Position[];
  trunkPoints: Position[];
  centerPoint?: Position;
  topPoint?: Position;
  dialogType: DialogType;
  activeOption: CanvasEditOption;
  setActiveOption: (option: CanvasEditOption) => void;
  dispatchActions: Dispatch<CanvasActions>;
  isTensilTestsIncluded?: boolean;
};

const CanvasStage: FC<CanvasStageProps> = ({
  analysisLevel,
  canvasSize,
  image,
  base,
  crownPoints,
  trunkPoints,
  centerPoint,
  topPoint,
  zoom,
  range,
  dialogType,
  activeOption,
  setActiveOption,
  dispatchActions,
  isTensilTestsIncluded,
}) => {
  const [isDraggingPoint, setIsDraggingPoint] = useState(false);
  const imageGroupRef = useRef<Konva.Group>(null);
  const { isCmdDown } = useKeyPress([
    CommandKeyCodes.LEFT_COMMAND,
    CommandKeyCodes.RIGHT_COMMAND,
    CommandKeyCodes.WINDOWS_CTRL,
  ]);

  useEffect(() => {
    if (imageGroupRef.current && image) {
      const imgWidth = image.width * zoom;
      const imgHeight = image.height * zoom;

      if (imgWidth < canvasSize) {
        imageGroupRef.current.x((canvasSize - imgWidth) / 2);
      } else {
        imageGroupRef.current.x(imageGroupRef.current.x());
      }
      if (imgHeight < canvasSize) {
        imageGroupRef.current.y((canvasSize - imgHeight) / 2);
      } else {
        if (imageGroupRef.current.y() < 0) {
          imageGroupRef.current.y(imageGroupRef.current.y());
        } else {
          imageGroupRef.current.y(0);
        }
      }
    }
  }, [canvasSize, image, zoom]);

  const onDragMove = useCallback(
    (evt: KonvaEventObject<DragEvent>) => {
      const eventCanvasPosition = evt.target.getAbsolutePosition();
      if (imageGroupRef.current && !isDraggingPoint && image) {
        const imgWidth = image.width * zoom;
        const imgHeight = image.height * zoom;

        if (imgWidth > canvasSize) {
          if (eventCanvasPosition.x > 0) {
            imageGroupRef.current.x(0);
          }
          if (eventCanvasPosition.x < canvasSize - imgWidth) {
            imageGroupRef.current.x(canvasSize - imgWidth);
          }
        } else {
          if (eventCanvasPosition.x < 0) {
            imageGroupRef.current.x(0);
          }
          if (eventCanvasPosition.x > canvasSize - imgWidth) {
            imageGroupRef.current.x(canvasSize - imgWidth);
          }
        }

        if (imgHeight > canvasSize) {
          if (eventCanvasPosition.y > 0) {
            imageGroupRef.current.y(0);
          }
          if (eventCanvasPosition.y < canvasSize - imgHeight) {
            imageGroupRef.current.y(canvasSize - imgHeight);
          }
        } else {
          if (eventCanvasPosition.y < 0) {
            imageGroupRef.current.y(0);
          }
          if (eventCanvasPosition.y > canvasSize - imgHeight) {
            imageGroupRef.current.y(canvasSize - imgHeight);
          }
        }
      }
    },
    [canvasSize, image, isDraggingPoint, zoom],
  );

  const createPoint = useCallback(
    (position: Position) => {
      if (dialogType === DialogType.WHOLE) {
        if (base.length < 2 && activeOption === CanvasEditOption.Base) {
          dispatchActions({
            type: CanvasActionTypes.SET_BASE_POINT,
            payload: { ...position, key: 'base' },
          });
          if (base.length >= 1) {
            setActiveOption(CanvasEditOption.CenterPoint);
          }
        }
        if (!centerPoint && activeOption === CanvasEditOption.CenterPoint) {
          dispatchActions({
            type: CanvasActionTypes.SET_CENTER_POINT,
            payload: { ...position, key: 'centerPoint' },
          });
          if (
            `${analysisLevel}` === AnalysisLevelType.Two &&
            !isTensilTestsIncluded
          ) {
            setActiveOption(CanvasEditOption.Range);
          } else if (
            `${analysisLevel}` === AnalysisLevelType.Three ||
            `${analysisLevel}` === AnalysisLevelType.Four ||
            isTensilTestsIncluded
          ) {
            setActiveOption(CanvasEditOption.Crown);
          }
        }
        if (
          `${analysisLevel}` === AnalysisLevelType.Two &&
          !isTensilTestsIncluded
        ) {
          if (range.length < 2 && activeOption === CanvasEditOption.Range) {
            dispatchActions({
              type: CanvasActionTypes.SET_RANGE_POINT,
              payload: { ...position, key: 'range' },
            });
            if (range.length >= 1) {
              setActiveOption(CanvasEditOption.TopPoint);
            }
          }
          if (!topPoint && activeOption === CanvasEditOption.TopPoint) {
            dispatchActions({
              type: CanvasActionTypes.SET_TOP_POINT,
              payload: { ...position, key: 'topPoint' },
            });
          }
        }
        if (activeOption === CanvasEditOption.Crown) {
          dispatchActions({
            type: CanvasActionTypes.SET_CROWN_POINT,
            payload: position,
          });
        }
      }

      if (dialogType === DialogType.TRUNK) {
        if (activeOption === CanvasEditOption.Trunk) {
          const topPoints = trunkPoints.filter(item => item.key === 'top');

          dispatchActions({
            type: CanvasActionTypes.SET_TRUNK_POINT,
            payload: {
              ...position,
              key: isCmdDown && topPoints.length < 2 ? 'top' : null,
            },
          });
        }
      }
    },
    [
      activeOption,
      analysisLevel,
      base.length,
      centerPoint,
      dialogType,
      dispatchActions,
      isCmdDown,
      isTensilTestsIncluded,
      range.length,
      setActiveOption,
      topPoint,
      trunkPoints,
    ],
  );

  const onLayerClick = useCallback(
    (event: KonvaEventObject<MouseEvent>) => {
      const stage = event.target._getStage();

      if (!stage || !stage.pointerPos || !imageGroupRef.current || !image) {
        return;
      }

      const stageTransform = stage.getAbsoluteTransform().copy();

      const correctedPointerPos = stageTransform.point(stage.pointerPos);

      const newPosition = {
        x: (correctedPointerPos.x - imageGroupRef.current.x()) / zoom,
        y: (correctedPointerPos.y - imageGroupRef.current.y()) / zoom,
        id: new Date().getTime(),
      };

      createPoint(newPosition);
    },
    [createPoint, image, zoom],
  );

  return (
    <Stage width={canvasSize} height={canvasSize}>
      {image && (
        <Layer>
          <Group
            width={image.width}
            height={image.height}
            onClick={onLayerClick}
            onDragMove={onDragMove}
            ref={imageGroupRef}
            draggable={!isDraggingPoint}
            scale={{ x: zoom, y: zoom }}
          >
            <Image
              image={image}
              width={image.width}
              height={image.height}
              stroke="black"
              strokeWidth={2}
            />
            {dialogType === DialogType.WHOLE && (
              <WholePart
                topPoint={topPoint}
                activeOption={activeOption}
                base={base}
                range={range}
                centerPoint={centerPoint}
                crownPoints={crownPoints}
                dispatchActions={dispatchActions}
                imageWidth={image.width}
                setIsDraggingPoint={setIsDraggingPoint}
                zoom={zoom}
              />
            )}
            {dialogType === DialogType.TRUNK && (
              <TrunkPart
                activeOption={activeOption}
                dispatchActions={dispatchActions}
                zoom={zoom}
                trunkPoints={trunkPoints}
                setIsDraggingPoint={setIsDraggingPoint}
              />
            )}
          </Group>
        </Layer>
      )}
    </Stage>
  );
};

export default CanvasStage;
