import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useCurrentLanguage } from '../../../translations/languageSelector';
import { TomogramButton } from './TomogramButton';
import { MAX_TOMOGRAM_SIZE } from './TomogramKonvaImage';

interface MiddleButtonsProps {
  handleRestartClick: () => void;
  handleBackButtonClick: () => void;
  middleButtonDisabled: boolean;
}

const useStyles = makeStyles(theme => ({
  cutContainer: { marginTop: '30px' },
  middleColumn: { height: '100%' },
  centerText: {
    flexGrow: 1,
    textAlign: 'center',
  },
  preview: {
    maxWidth: MAX_TOMOGRAM_SIZE,
    maxHeight: MAX_TOMOGRAM_SIZE,
    objectFit: 'scale-down',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export const MiddleButtons = ({
  middleButtonDisabled,
  handleRestartClick,
  handleBackButtonClick,
}: MiddleButtonsProps) => {
  const activeLang = useCurrentLanguage();
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.middleColumn}
    >
      <Grid item>
        <Typography className={classes.centerText}>
          První bod označuje sever
        </Typography>
      </Grid>
      <Grid item>
        <TomogramButton
          onClick={handleBackButtonClick}
          title={activeLang.treeDetailPage.buttons.back}
          disabled={middleButtonDisabled}
        />
      </Grid>
      <Grid item>
        <TomogramButton
          onClick={handleRestartClick}
          title={activeLang.treeDetailPage.buttons.reset}
          disabled={middleButtonDisabled}
        />
      </Grid>
    </Grid>
  );
};
