import {
  setTrees,
  setActiveTreeId,
  setLoadingState,
  setTaxons,
  setSearchedTaxon,
  setCanvasSaveState,
  setTree,
  setTaxonLoadingState,
  setTreeAnalysisResults,
  updatePhoto,
  deletePhoto,
  setTensilTestsSaveState,
  setSavePhotoState,
  setOrderState,
  setTrunkScan,
  setInitialState,
  setTotal,
} from './actions';
import { on, reducer } from 'ts-action';
import { withObjectUpdated, withItemUpdated } from 'redux-common-helpers';
import {
  Tree,
  AnalysisResult,
  Photo,
  OrderStatus,
} from '../../interfaces/tree';
import { LoadingState } from '../../interfaces/enums';
import { RcFile } from 'antd/lib/upload';

export interface SaveState {
  state: LoadingState;
  errorCode: number;
  msg?: string;
}
export interface State {
  trees: Tree[];
  activeTreeId: number | undefined;
  treeLoadState: LoadingState;
  taxons: string[];
  availableTaxons: string[];
  searchedTaxon: string;
  saveState: SaveState;
  saveTensilTestState: SaveState;
  taxonLoadState: LoadingState;
  analysisId: number | undefined;
  analysisResults: AnalysisResult[];
  savePhotoState: SaveState;
  orderState?: OrderStatus;
  trunkScan?: string | Blob | RcFile;
  total: number;
}

export const initialState: State = {
  trees: [],
  activeTreeId: undefined,
  treeLoadState: LoadingState.Loading,
  taxonLoadState: LoadingState.Initial,
  taxons: [],
  searchedTaxon: '',
  availableTaxons: [],
  saveState: { state: LoadingState.Initial, errorCode: 0 },
  saveTensilTestState: { state: LoadingState.Initial, errorCode: 0 },
  analysisId: undefined,
  analysisResults: [],
  savePhotoState: { state: LoadingState.Initial, errorCode: 0 },
  orderState: OrderStatus.INITIAL,
  trunkScan: undefined,
  total: 0,
};

export const treeReducer = reducer<State>(
  initialState,

  on(setTrees, (state: State, { payload }) =>
    withObjectUpdated(state, {
      trees: payload.trees,
    }),
  ),
  on(setActiveTreeId, (state: State, { payload }) =>
    withObjectUpdated(state, {
      activeTreeId: payload.id,
    }),
  ),
  on(setLoadingState, (state: State, { payload }) =>
    withObjectUpdated(state, {
      treeLoadState: payload.state,
    }),
  ),
  on(setTaxons, (state: State, { payload }) =>
    withObjectUpdated(state, {
      taxons: payload.taxons,
    }),
  ),
  on(setSearchedTaxon, (state: State, { payload }) =>
    withObjectUpdated(state, {
      searchedTaxon: payload.taxon,
    }),
  ),
  on(setCanvasSaveState, (state: State, { payload }) =>
    withObjectUpdated(state, {
      saveState: payload.saveState,
    }),
  ),
  on(setSavePhotoState, (state: State, { payload }) =>
    withObjectUpdated(state, {
      savePhotoState: payload.savePhotoState,
    }),
  ),
  on(setTensilTestsSaveState, (state: State, { payload }) =>
    withObjectUpdated(state, {
      saveTensilTestState: payload.saveTensilTestState,
    }),
  ),
  on(setTreeAnalysisResults, (state: State, { payload }) => {
    return withObjectUpdated(state, {
      analysisResults: payload.results,
    });
  }),
  on(setTree, (state: State, { payload }) =>
    withObjectUpdated(state, {
      trees: withItemUpdated(state.trees, 'id', payload.tree.id, payload.tree),
    }),
  ),
  on(setTaxonLoadingState, (state: State, { payload }) =>
    withObjectUpdated(state, {
      taxonLoadState: payload.state,
    }),
  ),
  on(updatePhoto, (state: State, { payload }) => {
    const activeTree = state.trees.find(tree => tree.id === state.activeTreeId);
    let newTreeScannerImages: Photo[] = [];
    if (activeTree) {
      newTreeScannerImages = activeTree.tree_scanner_images.map(
        (image: Photo) => {
          if (image.id === payload.photo.id) {
            return payload.photo;
          }
          return image;
        },
      );
    }
    return state.activeTreeId
      ? withObjectUpdated(state, {
          trees: withItemUpdated(state.trees, 'id', state.activeTreeId, {
            ...activeTree,
            tree_scanner_images: newTreeScannerImages,
          }),
        })
      : state;
  }),
  on(deletePhoto, (state: State, { payload }) => {
    const activeTree = state.trees.find(tree => tree.id === state.activeTreeId);
    let newTreeScannerImages: Photo[] = [];
    if (activeTree) {
      newTreeScannerImages = activeTree.tree_scanner_images.filter(
        (image: Photo) => image.id !== payload.photoId,
      );
    }
    return state.activeTreeId
      ? withObjectUpdated(state, {
          trees: withItemUpdated(state.trees, 'id', state.activeTreeId, {
            ...activeTree,
            tree_scanner_images: newTreeScannerImages,
          }),
        })
      : state;
  }),
  on(setOrderState, (state: State, { payload }) => ({
    ...state,
    orderState: payload,
  })),
  on(setTrunkScan, (state: State, { payload }) => ({
    ...state,
    trunkScan: payload,
  })),
  on(setInitialState, (state: State) => ({ ...initialState })),
  on(setTotal, (state: State, { payload }) => ({ ...state, total: payload })),
);
