import { CanvasEditOption, TreeProfile, TreeProtectionFactor } from './enums';
import { Project } from './project';
import { AnalysisLevelType, AnnouncementType } from './tree';

export interface Position {
  x: number;
  y: number;
  id: string | number;
  [key: string]: any;
}

export interface Line {
  id: number | string;
  pointOne: Position;
  pointTwo: Position;
}

export interface CanvasAnalysisResults {
  photoId: string;
  base: Position[];
  scale: Position[];
  range: Position[];
  topPoint?: Position;
  centerPoint?: Position;
  scaleSize: number;
  crownPoints?: Position[];
  trunkPoints?: Position[];
  [key: string]: any;
}

export interface CanvasCalculationResult {
  diameter: number | undefined;
  height: number | undefined;
  treeTopHeight: number | undefined;
}

export type CanvasResults = CanvasAnalysisResults;

export interface CanvasResultsWithReset extends CanvasResults {
  reset?: boolean;
}

export interface TreeAnalysis {
  [key: string]: any;
  id: number | undefined;
  announcement?: AnnouncementType;
  analysisLevel: AnalysisLevelType;
  condition?: number;
  treeTopHeight?: number;
  diameter?: number;
  mistletoe?: number;
  taxon: string;
  height: number;
  dbh?: number;
  // profil stromu
  profile?: TreeProfile;
  // ochranný faktor stromu
  protectionFactor?: TreeProtectionFactor;
  project?: Project;
  site: string;
  treeId?: number;
  isAnalysed?: boolean | null;
  isApproved?: boolean | null;
  trunkScanHeight?: number;
  trunkScanScreen?: { url: string; name: string } | null;
}

export interface OptionButton {
  option: CanvasEditOption;
  translation: string;
  stateProp: string;
  isTrunkDialog: boolean;
  isWholeTreeDialog: boolean;
  hint: string;
}

export interface TreeHeights {
  treeHeight: number;
  treeTopHeight: number;
}

export interface TranslationModal {
  title: string;
  type: string;
  typeCrown: string;
  markBase: string;
  hintBase: string;
  markTrunk: string;
  hintTrunk: string;
  markScale: string;
  hintScale: string;
  markCrown: string;
  hintCrown: string;
}

export const options = (translation: TranslationModal): OptionButton[] => {
  return [
    {
      option: CanvasEditOption.Base,
      translation: translation.markBase,
      stateProp: 'baseObject',
      isTrunkDialog: true,
      isWholeTreeDialog: true,
      hint: translation.hintBase,
    },
    {
      option: CanvasEditOption.Trunk,
      translation: translation.markTrunk,
      stateProp: 'trunkObject',
      isTrunkDialog: true,
      isWholeTreeDialog: false,
      hint: translation.hintTrunk,
    },
    {
      option: CanvasEditOption.Crown,
      translation: translation.markCrown,
      stateProp: 'crownObject',
      isTrunkDialog: false,
      isWholeTreeDialog: true,
      hint: translation.hintCrown,
    },
  ];
};

export enum CommandKeyCodes {
  LEFT_COMMAND = 91,
  RIGHT_COMMAND = 97,
  WINDOWS_CTRL = 17,
}
