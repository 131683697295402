import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  Grid,
  Typography,
  Divider,
  ButtonGroup,
  Badge,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FC, useMemo, useEffect, useContext } from 'react';
import AppButton from '../treeDetailPageNew/components/Form/AppButton/AppButton';
import { styles } from './NotificationsDialog.styles';
import { css } from 'aphrodite';
import { useQuery, useQueryClient } from 'react-query';
import { getNotifications } from '../../helpers/apiHelper';
import format from 'date-fns/format';
import { useCurrentLanguage } from '../../translations/languageSelector';
import { projectTheme } from '../../styles/variables';
import { UnreadNotificationsCountContext } from '../../contexts/UnreadNotificationsCountContext';
import { OrderMessage } from '../../interfaces/enums';
import { useNotification } from '../../hooks/useNotification';
import { Link } from 'react-router-dom';
import { NotificationDTOExtended } from '../../interfaces/notification';

interface NotificationsDialogProps {
  open: boolean;
  onClose: (value: boolean) => void;
}

const useStyles = makeStyles({
  root: {
    '& > div': {
      backgroundColor: 'transparent',
    },
  },
  paper: {
    maxWidth: 450,
    maxHeight: 500,
    position: 'fixed',
    margin: 0,
    [projectTheme.breakpoints.down('xl')]: {
      top: 68,
      right: 100,
    },
  },
});

export const NotificationDialog: FC<NotificationsDialogProps> = props => {
  const { open, onClose } = props;
  const classes = useStyles();
  const { update } = useContext(UnreadNotificationsCountContext);
  const activeLang = useCurrentLanguage();
  const queryClient = useQueryClient();
  const {
    readOneNotificationMutation: { mutate: readOne },
    readAllNotificationsMutation: { mutate: readAll },
    deleteAllNotificationsMutation: { mutate: deleteAll },
  } = useNotification();

  const handleClose = () => {
    onClose(true);
  };

  const { data } = useQuery(['notifications'], () => getNotifications(), {
    refetchInterval: 30000,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let notifications: NotificationDTOExtended[] = [];
  if (data) {
    notifications = data.map(notification => {
      const treeId = notification.message.match(/\d+/);
      const messageItems = notification.message.split('.');
      const messageItemsWithNoId = messageItems.slice(
        1,
        messageItems.length - 1,
      );
      const parsedMessage = messageItemsWithNoId.join('.');
      const finalTreeId = Array.isArray(treeId) ? treeId[0] : '';
      return { ...notification, treeId: finalTreeId, parsedMessage };
    });
  }

  const unreadNotificationsCount = notifications?.filter(
    notification => !notification.viewedAt,
  ).length;

  useEffect(() => {
    if (unreadNotificationsCount) {
      update(unreadNotificationsCount);
    }
  }, [unreadNotificationsCount, update]);

  const onReadOne = (notificationId: number) => {
    readOne(notificationId, {
      onSuccess: () => queryClient.invalidateQueries(['notifications']),
    });
  };

  const onDeleteAll = () => {
    deleteAll(notifications, {
      onSuccess: () => queryClient.invalidateQueries(['notifications']),
    });
  };

  const onReadAll = () => {
    readAll(notifications, {
      onSuccess: () => {
        queryClient.invalidateQueries(['notifications']);
        update(0);
      },
    });
  };

  const isRejectDisabled = useMemo(() => {
    if (!notifications?.length) {
      return true;
    }
    return false;
  }, [notifications]);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      classes={{ root: classes.root, paper: classes.paper }}
    >
      <DialogTitle>{activeLang.notifications.title}</DialogTitle>
      <DialogContent classes={{ root: css(styles.content) }}>
        <Grid container direction="column">
          {notifications?.length !== 0 ? (
            <List>
              {notifications?.map(notification => (
                <Link
                  to={`/treeDetail/${notification.treeId}`}
                  key={notification.id}
                >
                  <Grid
                    key={notification.id}
                    className={css(
                      styles.listItem,
                      !!notification.viewedAt ? null : styles.listItemUnread,
                    )}
                    onClick={() => onReadOne(notification.id)}
                  >
                    <ListItem>
                      <Grid item xs className={css(styles.textGrid)}>
                        <Badge
                          color="primary"
                          variant="dot"
                          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                          invisible={!!notification.viewedAt}
                        >
                          <Typography
                            variant="body1"
                            className={css(styles.text)}
                          >
                            {notification.treeId}:{' '}
                            {notification.parsedMessage ===
                              OrderMessage.FAILED &&
                              activeLang.notifications.Failed}
                            {notification.parsedMessage ===
                              OrderMessage.ORDERED &&
                              activeLang.notifications.Ordered}
                            {notification.parsedMessage ===
                              OrderMessage.FINISHED &&
                              activeLang.notifications.Finished}
                          </Typography>
                        </Badge>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography className={css(styles.text, styles.time)}>
                          {format(new Date(notification.createdAt), 'k:mm')}
                        </Typography>
                      </Grid>
                    </ListItem>
                    <Divider />
                  </Grid>
                </Link>
              ))}
            </List>
          ) : (
            <Grid>
              <Typography className={css(styles.emptyList)}>
                {activeLang.notifications.noNotifications}
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonGroup className={css(styles.buttons)}>
          <AppButton
            variant="contained"
            color="primary"
            onClick={onReadAll}
            disabled={isRejectDisabled}
          >
            {activeLang.notifications.readAll}
          </AppButton>
          <AppButton
            variant="contained"
            className={
              isRejectDisabled ? css(styles.disabled) : css(styles.rejectButton)
            }
            onClick={onDeleteAll}
            disabled={isRejectDisabled}
          >
            {activeLang.notifications.deleteAll}
          </AppButton>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
};
