import { useCurrentUser } from '../hooks/useCurrentUser';
import { cs } from './cs';
import { en } from './en';
import { LanguageKeys } from './keys';

const languages = {
  cs,
  en,
};

const defaultLang = 'cs';

export function useCurrentLanguage(): LanguageKeys {
  const loggedUser = useCurrentUser();

  const language = loggedUser?.language || defaultLang;

  if (languages[language]) {
    return languages[language];
  }

  return languages[defaultLang];
}

export const activeLang: LanguageKeys = languages[defaultLang];
