import { useCallback } from 'react';
import { TreeFormData } from '../components/treeDetailPageNew/components/TreeDetailForm/types';
import { useTreeDetailState } from '../components/treeDetailPageNew/TreeDetailProvider/TreeDetailStateProvider';

export const useRefuseAnalysis = () => {
  const { sendTreeForm, formDataError, formDataLoading, formDataResponse } =
    useTreeDetailState();
  const onRefuse = useCallback(
    (formValues: TreeFormData) => {
      sendTreeForm({
        ...formValues,
        project: `${formValues.projectId}`,
        dbh: formValues.dbhCounted ? formValues.dbhCounted : formValues.dbh,
        tree_scanner_data: formValues.id,
        isAnalysed: true,
        isApproved: false,
      });
    },
    [sendTreeForm],
  );

  return {
    onRefuse,
    refuseData: formDataResponse,
    refuseLoading: formDataLoading,
    refuseError: formDataError,
  };
};
