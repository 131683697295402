import { useCallback } from 'react';
import { useTreeDetailState } from '../components/treeDetailPageNew/TreeDetailProvider/TreeDetailStateProvider';
import { useSendData } from './useSendData/useSendData';

export const useDoneAnalysis = () => {
  const { activeTree } = useTreeDetailState();
  const {
    postData,
    loading: formDataLoading,
    data: formDataResponse,
    error: formDataError,
  } = useSendData();

  const onDone = useCallback(() => {
    if (!activeTree) {
      return;
    }

    postData(`/tree-scanner-data/${activeTree.id}/approve`, {});
  }, [activeTree, postData]);

  return {
    onDone,
    doneData: formDataResponse,
    doneLoading: formDataLoading,
    doneError: formDataError,
  };
};
