import { Fragment, FC, Dispatch } from 'react';
import { Position } from '../../../../../interfaces/canvas';
import { CanvasEditOption } from '../../../../../interfaces/enums';
import { Trunk } from '../Trunk/Trunk';
import { CanvasActions, CanvasActionTypes } from '../types';

type TrunkPartProps = {
  zoom: number;
  trunkPoints: Position[];
  activeOption: CanvasEditOption;
  setIsDraggingPoint: (isDraggingPoint: boolean) => void;
  dispatchActions: Dispatch<CanvasActions>;
};

const TrunkPart: FC<TrunkPartProps> = ({
  zoom,
  trunkPoints,
  activeOption,
  setIsDraggingPoint,
  dispatchActions,
}) => {
  return (
    <Fragment>
      <Trunk
        draggable={activeOption === CanvasEditOption.Trunk}
        zoom={zoom}
        trunkPoints={trunkPoints}
        setIsDraggingPoint={setIsDraggingPoint}
        dragTrunkPoint={point =>
          dispatchActions({
            type: CanvasActionTypes.UPDATE_TRUNK_POINT,
            payload: point,
          })
        }
      />
    </Fragment>
  );
};

export default TrunkPart;
