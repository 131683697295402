import { setRequestState, setLoggedUser, logOut, setErrorMsg } from './actions';
import { on, reducer } from 'ts-action';
import { withObjectUpdated } from 'redux-common-helpers';
import { LoadingState } from '../../interfaces/enums';
import { UserDTO } from '../../interfaces/user';
import { COOKIE_NAME } from '../../constants/constants';
import Cookies from 'js-cookie';
import { parseCookieToLoggedUser, setAuthToken } from '../../helpers/apiHelper';

export interface State {
  requestState: LoadingState;
  loggedUser: UserDTO | undefined;
  errorMsg: string;
}

export const initialState: State = {
  requestState: LoadingState.Initial,
  loggedUser: parseCookieToLoggedUser(Cookies.get(COOKIE_NAME)),
  errorMsg: '',
};

export const generalReducer = reducer<State>(
  initialState,
  on(setRequestState, (state: State, { payload }) =>
    withObjectUpdated(state, {
      requestState: payload.state,
    }),
  ),
  on(setLoggedUser, (state: State, { payload }) =>
    withObjectUpdated(state, {
      loggedUser: payload.loggedUser,
    }),
  ),
  on(setErrorMsg, (state: State, { payload }) =>
    withObjectUpdated(state, {
      errorMsg: payload.errorMsg,
    }),
  ),
  on(logOut, (state: State) => {
    Cookies.remove(COOKIE_NAME);
    setAuthToken();
    return withObjectUpdated(state, {
      loggedUser: undefined,
    });
  }),
);
