import { FC, useCallback, useMemo, useReducer } from 'react';
import { Grid } from '@material-ui/core';
import { css } from 'aphrodite';
import useImage from 'use-image';
import { CANVAS_WIDTH_TO_WINDOW_COEF } from '../../../../constants/constants';
import { styles } from './AppCanvas.styles';
import CanvasStage from './CanvasStage/CanvasStage';
import {
  AnalysisLevelType,
  Photo,
  TreeScannerDataStatus,
} from '../../../../interfaces/tree';
import { CanvasEditOption, DialogType } from '../../../../interfaces/enums';
import AppButton from '../Form/AppButton/AppButton';
import { ButtonGroup, Slider } from '@material-ui/core';
import { CanvasActions, CanvasActionTypes, CanvasState } from './types';
import { Check } from '@material-ui/icons';
import { useTreeDetailState } from '../../TreeDetailProvider/TreeDetailStateProvider';
import AppAlert from '../Form/AppAlert/AppAlert';
import { colors } from '../../../../styles/colors';
import { useCurrentLanguage } from '../../../../translations/languageSelector';
import { isBaseAndTop } from '../../../../helpers/canvasHelpers';

const canvasReducer = (state: CanvasState, action: CanvasActions) => {
  switch (action.type) {
    case CanvasActionTypes.SET_STATE:
      return { ...action.payload };
    case CanvasActionTypes.SET_ZOOM:
      return { ...state, zoom: action.payload };
    case CanvasActionTypes.SET_CENTER_POINT:
      return {
        ...state,
        centerPoint: { ...action.payload, key: 'centerPoint' },
      };
    case CanvasActionTypes.SET_TOP_POINT:
      return { ...state, topPoint: { ...action.payload, key: 'topPoint' } };
    case CanvasActionTypes.SET_ACTIVE_OPTION:
      return { ...state, activeOption: action.payload };
    case CanvasActionTypes.SET_BASE_POINT:
      if (state.base.length < 2) {
        return {
          ...state,
          base: [
            ...state.base,
            {
              ...action.payload,
              y: state.base.length === 1 ? state.base[0].y : action.payload.y,
              key: 'base',
            },
          ],
        };
      } else {
        return state;
      }
    case CanvasActionTypes.UPDATE_BASE_POINT:
      const newBase = state.base.map(basePoint => {
        if (basePoint.id === action.payload.id) {
          return { ...action.payload, key: 'base' };
        } else {
          return basePoint;
        }
      });
      return { ...state, base: newBase };
    case CanvasActionTypes.UPDATE_RANGE_POINT:
      const newRange = state.range.map(rangePoint => {
        if (rangePoint.id === action.payload.id) {
          return { ...action.payload, key: 'range' };
        } else {
          return rangePoint;
        }
      });
      return { ...state, range: newRange };
    case CanvasActionTypes.SET_RANGE_POINT:
      if (state.range.length < 2) {
        return {
          ...state,
          range: [...state.range, { ...action.payload, key: 'range' }],
        };
      } else {
        return state;
      }
    case CanvasActionTypes.SET_CROWN_POINT:
      if (
        state.crownPoints.length < 4 ||
        state.isTensilTestsIncluded ||
        `${state.analysisLevel}` === AnalysisLevelType.Three ||
        `${state.analysisLevel}` === AnalysisLevelType.Four
      ) {
        return {
          ...state,
          crownPoints: [
            ...state.crownPoints,
            { ...action.payload, key: 'crownPoint' },
          ],
        };
      } else {
        return state;
      }
    case CanvasActionTypes.UPDATE_CROWN_POINT:
      const newCrown = state.crownPoints.map(crownPoint => {
        if (crownPoint.id === action.payload.id) {
          return { ...action.payload, key: 'crownPoint' };
        } else {
          return crownPoint;
        }
      });
      return { ...state, crownPoints: newCrown };
    case CanvasActionTypes.SET_TRUNK_POINT:
      const topPoints = state.trunkPoints.filter(item => item.key === 'top');
      if (state.trunkPoints.length < 2) {
        return {
          ...state,
          trunkPoints: [
            ...state.trunkPoints,
            {
              ...action.payload,
              y:
                state.trunkPoints.length === 1
                  ? state.trunkPoints[0].y
                  : action.payload.y,
              key: 'base',
            },
          ],
        };
      } else if (
        state.trunkPoints[state.trunkPoints.length - 1].key === 'top'
      ) {
        return {
          ...state,
          trunkPoints: [
            ...state.trunkPoints,
            {
              ...action.payload,
              y:
                topPoints.length < 2
                  ? state.trunkPoints[state.trunkPoints.length - 1].y
                  : action.payload.y,
              key: topPoints.length < 2 ? 'top' : null,
            },
          ],
        };
      }
      return {
        ...state,
        trunkPoints: [...state.trunkPoints, action.payload],
      };
    case CanvasActionTypes.UPDATE_TRUNK_POINT:
      const newTrunk = state.trunkPoints.map(trunkPoint => {
        if (trunkPoint.id === action.payload.id) {
          if (trunkPoint.key === 'base' || trunkPoint.key === 'top') {
            return { ...action.payload, y: trunkPoint.y, key: trunkPoint.key };
          }
          return action.payload;
        } else {
          return trunkPoint;
        }
      });
      return { ...state, trunkPoints: newTrunk };

    case CanvasActionTypes.RESET:
      if (action.payload === DialogType.TRUNK) {
        return { ...state, trunkPoints: [] };
      }
      if (action.payload === DialogType.WHOLE) {
        return {
          ...state,
          base: [],
          centerPoint: undefined,
          crownPoints: [],
          range: [],
          topPoint: undefined,
        };
      }
      return state;

    case CanvasActionTypes.RESET_ACTIVE_OPTION:
      if (action.payload === DialogType.TRUNK) {
        return { ...state, trunkPoints: [] };
      }
      if (action.payload === DialogType.WHOLE) {
        if (state.activeOption === CanvasEditOption.Base) {
          return {
            ...state,
            base: [],
          };
        }
        if (state.activeOption === CanvasEditOption.CenterPoint) {
          return {
            ...state,
            centerPoint: undefined,
          };
        }
        if (state.activeOption === CanvasEditOption.Crown) {
          return {
            ...state,
            crownPoints: [],
          };
        }
        if (state.activeOption === CanvasEditOption.Range) {
          return {
            ...state,
            range: [],
          };
        }
        if (state.activeOption === CanvasEditOption.TopPoint) {
          return {
            ...state,
            topPoint: undefined,
          };
        }
      }
      return state;

    case CanvasActionTypes.BACK:
      if (action.payload === DialogType.TRUNK) {
        return { ...state, trunkPoints: state.trunkPoints.slice(0, -1) };
      }
      if (action.payload === DialogType.WHOLE) {
        if (state.activeOption === CanvasEditOption.Base) {
          return {
            ...state,
            base: state.base.slice(0, -1),
          };
        }
        if (state.activeOption === CanvasEditOption.CenterPoint) {
          return {
            ...state,
            centerPoint: undefined,
          };
        }
        if (state.activeOption === CanvasEditOption.Crown) {
          return {
            ...state,
            crownPoints: state.crownPoints.slice(0, -1),
          };
        }
        if (state.activeOption === CanvasEditOption.Range) {
          return {
            ...state,
            range: state.range.slice(0, -1),
          };
        }
        if (state.activeOption === CanvasEditOption.TopPoint) {
          return {
            ...state,
            topPoint: undefined,
          };
        }
      }
      return state;

    default:
      return state;
  }
};

type AppCanvasProps = {
  photo: Photo;
  dialogType: DialogType;
  analysisLevel: AnalysisLevelType;
  status: TreeScannerDataStatus | null;
  isTensilTestsIncluded: boolean;
};

const AppCanvas: FC<AppCanvasProps> = ({
  photo,
  dialogType,
  analysisLevel,
  status,
  isTensilTestsIncluded,
}) => {
  const activeLang = useCurrentLanguage();

  const {
    savePhoto,
    analysedPhotoData,
    analysedPhotoError,
    analysedPhotoLoading,
  } = useTreeDetailState();

  const initialState = useMemo(() => {
    if (analysedPhotoData) {
      return {
        base: analysedPhotoData.analysisResults?.base || [],
        scale: analysedPhotoData.analysisResults?.scale || [],
        range: analysedPhotoData.analysisResults?.range || [],
        crownPoints: analysedPhotoData.analysisResults?.crownPoints || [],
        trunkPoints: analysedPhotoData.analysisResults?.trunkPoints || [],
        centerPoint: analysedPhotoData.analysisResults?.centerPoint,
        topPoint: analysedPhotoData.analysisResults?.topPoint,
        zoom: 0.25,
        scaleLength: analysedPhotoData.analysisResults?.scaleSize || 1,
        activeOption: CanvasEditOption.Base,
        analysisLevel,
        isTensilTestsIncluded,
      };
    } else {
      return {
        base: photo.analysisResults?.base || [],
        scale: photo.analysisResults?.scale || [],
        range: photo.analysisResults?.range || [],
        crownPoints: photo.analysisResults?.crownPoints || [],
        trunkPoints: photo.analysisResults?.trunkPoints || [],
        centerPoint: photo.analysisResults?.centerPoint,
        topPoint: photo.analysisResults?.topPoint,
        zoom: 0.25,
        scaleLength: photo.analysisResults?.scaleSize || 1,
        activeOption:
          dialogType === DialogType.WHOLE
            ? CanvasEditOption.Base
            : CanvasEditOption.Trunk,
        analysisLevel,
        isTensilTestsIncluded,
      };
    }
  }, [
    analysedPhotoData,
    analysisLevel,
    dialogType,
    isTensilTestsIncluded,
    photo.analysisResults?.base,
    photo.analysisResults?.centerPoint,
    photo.analysisResults?.crownPoints,
    photo.analysisResults?.range,
    photo.analysisResults?.scale,
    photo.analysisResults?.scaleSize,
    photo.analysisResults?.topPoint,
    photo.analysisResults?.trunkPoints,
  ]);

  const [
    {
      base,
      scale,
      crownPoints,
      trunkPoints,
      range,
      zoom,
      scaleLength,
      activeOption,
      centerPoint,
      topPoint,
    },
    dispatch,
  ] = useReducer(canvasReducer, initialState);
  const [image] = useImage(photo.url);

  const setActiveOption = useCallback((option: CanvasEditOption) => {
    dispatch({ type: CanvasActionTypes.SET_ACTIVE_OPTION, payload: option });
  }, []);

  const onSave = useCallback(() => {
    savePhoto({
      base,
      scale,
      scaleSize: scaleLength,
      trunkPoints,
      crownPoints,
      centerPoint,
      topPoint,
      range,
      photoId: photo.url,
      id: photo.id,
    });
  }, [
    base,
    centerPoint,
    crownPoints,
    photo.id,
    photo.url,
    range,
    savePhoto,
    scale,
    scaleLength,
    topPoint,
    trunkPoints,
  ]);

  const saveResetPhoto = useCallback(() => {
    savePhoto({
      base,
      scale,
      range,
      scaleSize: scaleLength,
      photoId: photo.url,
      id: photo.id,
      reset: true,
    });
  }, [base, photo.id, photo.url, range, savePhoto, scale, scaleLength]);

  const isSendDisabled = useMemo(() => {
    if (status === TreeScannerDataStatus.FINISHED) {
      return true;
    }

    if (status !== TreeScannerDataStatus.ORDERED) {
      return true;
    }
    if (dialogType === DialogType.WHOLE) {
      if (base.length < 2) {
        return true;
      }

      if (
        `${analysisLevel}` === AnalysisLevelType.Two &&
        !isTensilTestsIncluded
      ) {
        if (range.length < 2) {
          return true;
        }
        if (!centerPoint) {
          return true;
        }
        if (!topPoint) {
          return true;
        }
      }

      if (
        (`${analysisLevel}` === AnalysisLevelType.Three ||
          `${analysisLevel}` === AnalysisLevelType.Four ||
          isTensilTestsIncluded) &&
        crownPoints.length < 4
      ) {
        return true;
      }
    }

    if (dialogType === DialogType.TRUNK && !isBaseAndTop(trunkPoints)) {
      return true;
    }

    return false;
  }, [
    analysisLevel,
    base.length,
    centerPoint,
    crownPoints.length,
    dialogType,
    isTensilTestsIncluded,
    range.length,
    status,
    topPoint,
    trunkPoints,
  ]);

  return (
    <Grid container direction="row">
      <AppAlert
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={!!analysedPhotoError}
        message={analysedPhotoError?.message}
        autoHideDuration={1000}
        color={colors.redPrimary}
        severity="error"
      />
      <AppAlert
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={!!analysedPhotoData}
        message={activeLang.treeDetailPage.photoSuccess}
        autoHideDuration={1000}
        color={colors.greenPrim}
        severity="success"
      />
      <Grid item xs={12} md={6} className={css(styles.options)}>
        <CanvasStage
          analysisLevel={analysisLevel}
          range={range}
          base={base}
          topPoint={topPoint}
          dispatchActions={dispatch}
          crownPoints={crownPoints}
          trunkPoints={trunkPoints}
          centerPoint={centerPoint}
          canvasSize={window.innerWidth * CANVAS_WIDTH_TO_WINDOW_COEF}
          zoom={zoom}
          image={image}
          activeOption={activeOption}
          dialogType={dialogType}
          setActiveOption={setActiveOption}
          isTensilTestsIncluded={isTensilTestsIncluded}
        />
      </Grid>

      <Grid item xs md xl={4} className={css(styles.optionsRight)}>
        {dialogType === DialogType.WHOLE && (
          <ButtonGroup orientation="vertical" fullWidth>
            <AppButton
              onClick={() =>
                dispatch({
                  type: CanvasActionTypes.SET_ACTIVE_OPTION,
                  payload: CanvasEditOption.Base,
                })
              }
              color="primary"
              variant={
                activeOption === CanvasEditOption.Base
                  ? 'contained'
                  : 'outlined'
              }
              fullWidth
            >
              {activeLang.treeDetailPage.modals.body.markBase}
              {base.length === 2 && <Check />}
            </AppButton>
            <AppButton
              onClick={() =>
                dispatch({
                  type: CanvasActionTypes.SET_ACTIVE_OPTION,
                  payload: CanvasEditOption.CenterPoint,
                })
              }
              color="primary"
              variant={
                activeOption === CanvasEditOption.CenterPoint
                  ? 'contained'
                  : 'outlined'
              }
              fullWidth
            >
              {activeLang.treeDetailPage.modals.body.markCenterPoint}
              {centerPoint && <Check />}
            </AppButton>

            {`${analysisLevel}` === AnalysisLevelType.Two &&
              !isTensilTestsIncluded && (
                <AppButton
                  onClick={() =>
                    dispatch({
                      type: CanvasActionTypes.SET_ACTIVE_OPTION,
                      payload: CanvasEditOption.Range,
                    })
                  }
                  color="primary"
                  variant={
                    activeOption === CanvasEditOption.Range
                      ? 'contained'
                      : 'outlined'
                  }
                  fullWidth
                >
                  {activeLang.treeDetailPage.modals.body.markRange}
                  {range.length === 2 && <Check />}
                </AppButton>
              )}
            {`${analysisLevel}` === AnalysisLevelType.Two &&
              !isTensilTestsIncluded && (
                <AppButton
                  onClick={() =>
                    dispatch({
                      type: CanvasActionTypes.SET_ACTIVE_OPTION,
                      payload: CanvasEditOption.TopPoint,
                    })
                  }
                  color="primary"
                  variant={
                    activeOption === CanvasEditOption.TopPoint
                      ? 'contained'
                      : 'outlined'
                  }
                  fullWidth
                >
                  {activeLang.treeDetailPage.modals.body.markTopPoint}
                  {topPoint && <Check />}
                </AppButton>
              )}
            {(`${analysisLevel}` === AnalysisLevelType.Three ||
              `${analysisLevel}` === AnalysisLevelType.Four ||
              isTensilTestsIncluded) && (
                <AppButton
                  onClick={() =>
                    dispatch({
                      type: CanvasActionTypes.SET_ACTIVE_OPTION,
                      payload: CanvasEditOption.Crown,
                    })
                  }
                  color="primary"
                  variant={
                    activeOption === CanvasEditOption.Crown
                      ? 'contained'
                      : 'outlined'
                  }
                  fullWidth
                >
                  {activeLang.treeDetailPage.modals.body.markCrown}
                  {crownPoints.length > 3 && <Check />}
                </AppButton>
              )}
          </ButtonGroup>
        )}
        {dialogType === DialogType.TRUNK && (
          <ButtonGroup orientation="vertical" fullWidth>
            <AppButton
              onClick={() =>
                dispatch({
                  type: CanvasActionTypes.SET_ACTIVE_OPTION,
                  payload: CanvasEditOption.Trunk,
                })
              }
              color="primary"
              variant={
                activeOption === CanvasEditOption.Trunk
                  ? 'contained'
                  : 'outlined'
              }
              fullWidth
            >
              {activeLang.treeDetailPage.modals.body.markTrunk}
              {trunkPoints.length > 3 && <Check />}
            </AppButton>
          </ButtonGroup>
        )}
        <Grid className={css(styles.zoomWrap)}>
          <span>
            {activeLang.treeDetailPage.modals.zoom} {(zoom * 100).toFixed()}%
          </span>
          <Slider
            value={zoom}
            onChange={(_, newZoom) =>
              dispatch({
                type: CanvasActionTypes.SET_ZOOM,
                payload: +newZoom,
              })
            }
            max={2}
            min={0.01}
            step={0.01}
          />
        </Grid>
        <Grid>
          <ButtonGroup fullWidth>
            <AppButton
              onClick={() => {
                dispatch({
                  type: CanvasActionTypes.BACK,
                  payload: dialogType,
                });
              }}
              color="primary"
              variant="contained"
              fullWidth
            >
              {activeLang.treeDetailPage.buttons.back}
            </AppButton>
            <AppButton
              onClick={() => {
                dispatch({
                  type: CanvasActionTypes.RESET,
                  payload: dialogType,
                });
                if (dialogType === DialogType.WHOLE) {
                  dispatch({
                    type: CanvasActionTypes.SET_ACTIVE_OPTION,
                    payload: CanvasEditOption.Base,
                  });
                }
                saveResetPhoto();
              }}
              color="primary"
              variant="contained"
              fullWidth
            >
              {activeLang.treeDetailPage.buttons.reset}
            </AppButton>
            <AppButton
              onClick={() => {
                dispatch({
                  type: CanvasActionTypes.RESET_ACTIVE_OPTION,
                  payload: dialogType,
                });
              }}
              color="primary"
              variant="contained"
              fullWidth
            >
              {activeLang.treeDetailPage.buttons.reset_active_option}
            </AppButton>
          </ButtonGroup>
        </Grid>
        <Grid>
          <AppButton
            disabled={isSendDisabled || analysedPhotoLoading}
            onClick={onSave}
            color="primary"
            variant="contained"
            fullWidth
          >
            {activeLang.treeDetailPage.modals.save}
          </AppButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default AppCanvas;
