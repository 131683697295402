import { TreeFormData } from '../components/treeDetailPageNew/components/TreeDetailForm/types';
import { Tree } from '../interfaces/tree';
import { useSendData } from './useSendData/useSendData';

export const useTreeDetailFormData = () => {
  const { data, loading, error, postData, putData } = useSendData<
    TreeFormData & { tree_scanner_data?: Tree | number }
  >();

  return {
    formDataLoading: loading,
    formDataError: error,
    formDataResponse: data,
    postAnalysisData: postData,
    putAnalysisData: putData,
  };
};
