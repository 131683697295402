import { TreeAnalysis } from '../interfaces/canvas';
import { useSendData } from './useSendData/useSendData';

export const useTrunkScan = () => {
  const {
    putFile: putTrunkScan,
    data: trunkScanResponse,
    error: trunkScanError,
    loading: trunkScanLoading,
  } = useSendData<{ trunkScan: any }>();

  const {
    putFile: putTrunkScanImage,
    postFile: postTrunkScanImage,
    data: trunkScanImageData,
    error: trunkScanImageError,
    loading: trunkScanImageLoading,
  } = useSendData<TreeAnalysis>();

  return {
    putTrunkScan,
    putTrunkScanImage,
    postTrunkScanImage,
    trunkScanImageData,
    trunkScanImageError,
    trunkScanResponse,
    trunkScanError,
    trunkScanLoading,
    trunkScanImageLoading,
  };
};
