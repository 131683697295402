import { LoadingState } from '../../interfaces/enums';
import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { action, payload } from 'ts-action';
import * as Sentry from '@sentry/browser';
import {
  postLogin,
  setAuthToken,
  refreshTokenFromCookie,
} from '../../helpers/apiHelper';
import { State } from './reducers';
import { UserDTO } from '../../interfaces/user';
import Cookies from 'js-cookie';
import { COOKIE_NAME } from '../../constants/constants';
import { User } from '@sentry/browser';

export enum ActionTypes {
  SET_REQUEST_STATE = '[general] SET_REQUEST_STATE',
  REFRESH_USER = '[general] REFRESH_USER',
  SET_LOGGED_USER = '[genereal] SET_LOGGED_USER',
  SET_ERROR_MSG = '[genereal] SET_ERROR_MESSAGE',
  LOG_OUT = '[general] LOG_OUT',
}

export const setRequestState = action(
  ActionTypes.SET_REQUEST_STATE,
  payload<{ state: LoadingState }>(),
);

export const setLoggedUser = action(
  ActionTypes.SET_LOGGED_USER,
  payload<{ loggedUser: UserDTO }>(),
);

export const setErrorMsg = action(
  ActionTypes.SET_ERROR_MSG,
  payload<{ errorMsg: string }>(),
);

export const refreshUser = action(
  ActionTypes.REFRESH_USER,
  payload<{ user: User }>(),
);

export const logOut = action(ActionTypes.LOG_OUT);

export const RemoveCookies = action(ActionTypes.LOG_OUT, payload<{}>());

export const checkLogin: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> = (userName: string, password: string) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(setRequestState({ state: LoadingState.Loading }));
      const result = await postLogin(userName, password);
      Cookies.set(COOKIE_NAME, JSON.stringify(result.data));
      setAuthToken(result.data.jwt);
      const loggedUser: UserDTO = {
        id: result.data.id,
        ...result.data.user,
      };
      dispatch(setLoggedUser({ loggedUser }));
      dispatch(setRequestState({ state: LoadingState.Success }));
    } catch (err) {
      Sentry.captureException(err);
      dispatch(setRequestState({ state: LoadingState.Failure }));
    }
  };
};

export const callRefreshUser: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> = () => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(setRequestState({ state: LoadingState.Loading }));
      const result = await refreshTokenFromCookie(Cookies.get(COOKIE_NAME));

      if (result) {
        dispatch(setLoggedUser({ loggedUser: result }));
        dispatch(setRequestState({ state: LoadingState.Success }));
      }
      dispatch(setRequestState({ state: LoadingState.Failure }));
    } catch (err) {
      Sentry.captureException(err);
      dispatch(setRequestState({ state: LoadingState.Failure }));
    }
  };
};
