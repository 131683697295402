import { Grid, IconButton, Typography } from '@material-ui/core';
import { FormatAlignJustify } from '@material-ui/icons';
import { LoadingOutlined, PushpinFilled, PushpinOutlined } from '@ant-design/icons';
import { useFormContext } from 'react-hook-form';
import AppInput from '../Form/AppInput/AppInput';
import AppButton from '../Form/AppButton/AppButton';
import { requiredRule } from '../../../../helpers/formHelpers';
import { useCurrentLanguage } from '../../../../translations/languageSelector';
import { AppTensileTestsTaxonSelect } from '../../../AppTensileTestsTaxonSelect';
import { TensileTestSafetyCoefTable } from './TensileTestSafetyCoefTable';
import { TensilTestDirectionData } from '../../../../interfaces/tenstilTest';
import { useTensileTest } from '../../../../contexts/TensilTestsContext';


export type TensileTestFormProps = {
  anchorageHeight: number;
  ropeAngle: number;
  ktDistance: number;
  barkThickness: number;
  taxon: string;
};

export const TensileTestForm = ({ isFormPinned, pinFormCallback }) => {
  const activeLang = useCurrentLanguage();
  const { handleSubmit } = useFormContext();

  const {
    computation: {
      precalculateValues,
      isLoading,
      dataRange,
    },
  } = useTensileTest();

  let isSubmitDisabled = isLoading /*|| !formState.isValid*/;
  let disabledSubmitButtonTitle = '';
  if (dataRange.min < 0 || dataRange.max < 0) {
    isSubmitDisabled = true;
    disabledSubmitButtonTitle = 'Min and Max values should be selected first, before trying to precalculate values';
  }


  return (
    <Grid container style={{ flexDirection: 'row' }}>

      <Grid item style={{ width: '50%' }}>
        <Grid
          container
          style={{
            padding: '12px 16px',
          }}
        >
          <AppInput
            required
            rules={{ ...requiredRule }}
            name="anchorageHeight"
            unit={TensilTestDirectionData.anchorageHeight.unit}
            textFieldProps={{
              label: activeLang.treeDetailPage.tensilTest.anchorageHeight,
              size: 'small',
            }}
            marginBottom={30}
            showMessage
          />
          <AppInput
            required
            rules={{ ...requiredRule }}
            name="ropeAngle"
            unit={TensilTestDirectionData.ropeAngle.unit}
            textFieldProps={{
              label: activeLang.treeDetailPage.tensilTest.ropeAngle,
              size: 'small',
            }}
            marginBottom={30}
            showMessage
          />
          <AppInput
            required
            rules={{ ...requiredRule }}
            name="ktDistance"
            unit={TensilTestDirectionData.ktDistance.unit}
            textFieldProps={{
              label: activeLang.treeDetailPage.tensilTest.ktDistance,
              size: 'small',
            }}
            marginBottom={30}
            showMessage
          />
          <AppInput
            required
            rules={{ ...requiredRule }}
            name="barkThickness"
            unit={TensilTestDirectionData.barkThickness.unit}
            textFieldProps={{
              label: activeLang.treeDetailPage.tensilTest.barkThickness,
              size: 'small',
            }}
            marginBottom={30}
            showMessage
          />
          <AppTensileTestsTaxonSelect rules={{ ...requiredRule }} showMessage />
        </Grid>
      </Grid>

      <Grid item style={{ width: '50%', padding: '10px 0', position: 'relative' }}>
        <IconButton onClick={pinFormCallback} style={{ position: 'absolute', top: 0, right: 0 }}>
          {isFormPinned ? <PushpinFilled style={{ color: 'black' }} /> : <PushpinOutlined />}
        </IconButton>

        <Grid container>
          <Typography style={{ fontSize: 16, fontWeight: 700, padding: 15, paddingTop: 5, margin: 3 }}>
            {activeLang.treeDetailPage.tensilTest.safeCoef}
          </Typography>

          <TensileTestSafetyCoefTable />
        </Grid>

        <Grid container style={{ paddingRight: 10, marginTop: 30 }} justifyContent="space-between">
          <Grid item>
            {/* TODO: add general errors here */}
          </Grid>

          <Grid item>
            <AppButton
              onClick={handleSubmit(precalculateValues)}
              variant="contained"
              color="primary"
              disabled={isSubmitDisabled}
              startIcon={isLoading ? <LoadingOutlined /> : <FormatAlignJustify />}
              tooltipTitle={disabledSubmitButtonTitle}
            >
              {activeLang.treeDetailPage.tensilTest.precalculate}
            </AppButton>
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  );
};
