import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import { ExpandMoreOutlined } from '@material-ui/icons';
import { css } from 'aphrodite';
import { FC, useState } from 'react';
import { useCurrentLanguage } from '../../../../translations/languageSelector';
import { dynamicStyles, getMeterBgColor, styles } from './TensilTests.styles';
import { CALCULATIONS } from '../../../../interfaces/tenstilTest';
import { ElastometerPrecalculationDto, InclinometerCalculationDto, TreeValuesCalculationsDto } from '../../../../helpers/apiHelper';
import { useTensileTest } from '../../../../contexts/TensilTestsContext';

type TensileTestPrecalculateElastometerTableProps = ElastometerPrecalculationDto & {
  name: string;
  treeValues: TreeValuesCalculationsDto;
};

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#ECF4F2',
    },
  },
}))(TableRow);

const zeroValueComponent = (
  <Typography style={{ fontSize: '0.875rem', color: 'red' }} component="span">0</Typography>
);

export const TensileTestPrecalculateElastometerTable: FC<
  TensileTestPrecalculateElastometerTableProps
> = ({ name, commonValues, quartilValues, treeValues }) => {
  const activeLang = useCurrentLanguage();
  const [expandedDetails, setExpandedDetails] = useState<boolean>(false);

  const quartilsPropsList = Array.from(new Set(
    quartilValues.flatMap(q => Object.keys(q || {}))
  ).values());

  return (
    <Grid item className={css(styles.tensilTestCard)}>

      <Grid container justifyContent="space-between" style={{ padding: '5px 10px' }}>

        <Grid item>
          <Typography variant="h6" style={{ fontWeight: 700, margin: 10 }}>
            {activeLang.treeDetailPage.tensilTest.resultFor}
          </Typography>
        </Grid>

        <Grid item style={{ margin: 15 }}>
          <Badge
            style={{ backgroundColor: getMeterBgColor(name) }}
            className={css(dynamicStyles(getMeterBgColor(name)).badge)}
          >
            {name}
          </Badge>
        </Grid>

        <Grid item style={{ flexGrow: 1 }}>
          <Accordion
            elevation={3 * Number(expandedDetails)}
            onChange={(_e, expanded) => setExpandedDetails(expanded)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreOutlined color="primary" />}
              aria-controls="deformation-content"
              id="deformation-header"
            >
              <Typography variant="overline" style={{ flexGrow: 1, textAlign: 'center' }}>
                {'General Elastometer Values'}
              </Typography>
              <Typography variant="overline" style={{ flexGrow: 1, textAlign: 'center' }}>
                {'General Tree Values'}
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container style={{ gap: 25 }}>
                <Grid item style={{ flexGrow: 1 }}>
                  <CommonInfoTable data={commonValues} />
                </Grid>

                <Grid item style={{ flexGrow: 1 }}>
                  <CommonInfoTable data={treeValues} />
                </Grid>
              </Grid>
            </AccordionDetails>

          </Accordion>
        </Grid>

      </Grid>

      <Table size="small" padding="none">
        <TableHead>
          <TableRow>
            <TableCell />
            {quartilValues.map((_q, quartilIndex) => (
              <TableCell key={quartilIndex} align="center" style={{ paddingTop: 25, paddingBottom: 10 }}>
                <Typography style={{ fontWeight: 700 }}>
                  {`${25 * (quartilIndex + 1)}%`}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {quartilsPropsList.map(prop => {
            const { precision, unit } = CALCULATIONS[prop] ?? {};

            return (
              <StyledTableRow key={prop}>
                <TableCell style={{ padding: 5 }} component="th" scope="row">
                  {activeLang.treeDetailPage.tensilTest[prop] ?? prop}
                </TableCell>

                {quartilValues.map((values, index) => (
                  <TableCell key={`${prop}${index}`} className={css(styles.unitCell)} data-unit={unit}>
                    {values?.[prop]?.toFixed(precision) || zeroValueComponent}
                  </TableCell>
                ))}
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>

    </Grid>
  );
};


const CommonInfoTable: FC<{ data: Record<string, number> }> = ({ data }) => {
  const activeLang = useCurrentLanguage();

  return (
    <Table size="small" padding="none">
      <TableBody>
        {Object.entries(data).map(([valueName, value]) => {
          const { precision, unit } = CALCULATIONS[valueName] ?? {};

          return (
            <StyledTableRow key={valueName}>
              <TableCell key="name" component="th" scope="row" style={{ padding: 2 }}>
                {activeLang.treeDetailPage.tensilTest[valueName] ?? valueName}
              </TableCell>

              <TableCell key="value" className={css(styles.unitCell)} data-unit={unit}>
                {precision ? value.toFixed(precision) : value}
              </TableCell>
            </StyledTableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};



export const TensileTestPrecalculateInclinometersTable: FC<
  { quartilValues: InclinometerCalculationDto[][] }
> = ({ quartilValues }) => {
  const activeLang = useCurrentLanguage();

  const { safetyCoeficientTable: { availableInclinometers } } = useTensileTest();

  return (
    <Grid item className={css(styles.tensilTestCard)}>

      <Grid container justifyContent="space-between" style={{ padding: '5px 10px' }}>

        <Grid item>
          <Typography variant="h6" style={{ fontWeight: 700, margin: 10 }}>
            {activeLang.treeDetailPage.tensilTest.resultFor}
            {'Inclinometers'}
          </Typography>
        </Grid>

      </Grid>

      <Table size="small" padding="none">
        <TableHead>
          <TableRow>
            <TableCell />

            {quartilValues[0]?.map((_q, quartilIndex) => (
              <TableCell key={quartilIndex} align="center" style={{ paddingTop: 25, paddingBottom: 10 }}>
                <Typography style={{ fontWeight: 700 }}>
                  {`${25 * (quartilIndex + 1)}%`}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          <StyledTableRow key={'dynamometer'}>
            <TableCell style={{ padding: 5 }} component="th" scope="row">
              {activeLang.treeDetailPage.tensilTest.dynamometer}
            </TableCell>

            {quartilValues[0]?.map((values, quartilIndex) => {
              const { precision, unit } = CALCULATIONS.dynamometer;

              return (
                <TableCell
                  key={`dynamometer-${quartilIndex}`}
                  className={css(styles.unitCell)}
                  data-unit={unit}
                >
                  {values.dynamometer?.toFixed(precision) || zeroValueComponent}
                </TableCell>
              );
            })}
          </StyledTableRow>

          {quartilValues.map((inclinometerValues, inclinometerIndex) => {
            const { precision, unit } = CALCULATIONS.resistanceToUprooting;
            const name = availableInclinometers[inclinometerIndex];

            return (
              <StyledTableRow key={inclinometerIndex}>
                <TableCell style={{ padding: 5 }} component="th" scope="row">
                  <Grid container>
                    <Grid item>
                      {activeLang.treeDetailPage.tensilTest.resistanceToUprooting}
                    </Grid>

                    <Grid item style={{ marginLeft: 15 }}>
                      <Badge
                        style={{ backgroundColor: getMeterBgColor(name) }}
                        className={css(dynamicStyles(getMeterBgColor(name)).badge)}
                      >
                        {name}
                      </Badge>
                    </Grid>
                  </Grid>
                </TableCell>

                {inclinometerValues.map((values, quartilIndex) => (
                  <TableCell
                    key={`${inclinometerIndex}-${quartilIndex}`}
                    className={css(styles.unitCell)}
                    data-unit={unit}
                  >
                    {values.uprootingResistanceSafetyFactor?.toFixed(precision) || zeroValueComponent}
                  </TableCell>
                ))}
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>

    </Grid>
  );
};
