import React, { FC, useMemo, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { css } from 'aphrodite';
import { ColumnProps } from 'antd/lib/table';
import Table from 'antd/lib/table';
import 'antd/lib/table/style/css';
import { Header } from '../shared/Header';
import { useCurrentLanguage } from '../../translations/languageSelector';
import { textColors } from '../../styles/colors';
import { Tree, PhotoType, TreeScannerDataStatus } from '../../interfaces/tree';
import { numericSort, alphabeticalSort } from '../../helpers/sortHelpers';
import { UserDTO } from '../../interfaces/user';
import { LoadingState } from '../../interfaces/enums';
import { Loader } from '../shared/Loader';
import { Project } from '../../interfaces/project';
import { useRole } from '../../hooks/useRole';
import { styles } from './ProjectDetailPage.styles';
import { CheckOutlined, CloseOutlined } from '@material-ui/icons';

export interface StateProps {
  activeProject: Project | undefined;
  loggedUser: UserDTO | undefined;
  trees: Tree[];
  loadStateTrees: LoadingState;
  errorMsg: string;
  total: number;
}

export interface DispatchProps {
  setActiveProjectById: (payload: { projectId: number }) => void;
  getActiveProject: (projectId: number | undefined) => void;
  logOut: () => void;
  getTreesData: (idProject: number | undefined, page: number) => void;
}

type ProjectDetailProps = StateProps & DispatchProps & RouteComponentProps<any>;

export interface State {
  column: Array<ColumnProps<Tree>>;
}

export const ProjectDetailPage: FC<ProjectDetailProps> = ({
  loggedUser,
  loadStateTrees,
  match,
  activeProject,
  trees,
  errorMsg,
  total,
  logOut,
  getActiveProject,
  getTreesData,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const { isAdmin, isOperator } = useRole();
  const [column, setColumn] = useState<ColumnProps<Tree>[]>([]);

  const activeLang = useCurrentLanguage();

  const createData = useMemo((): Array<ColumnProps<Tree>> => {
    const tableData: Array<ColumnProps<Tree>> = [
      {
        key: 'id',
        title: activeLang.detailProjectPage.tree,
        dataIndex: 'id',
        defaultSortOrder: 'descend',
        sorter: (a: Tree, b: Tree) => numericSort(a.id, b.id),
        render: (text: number) => (
          <>
            <Link
              data-at="link-tree-detail"
              to={`/treeDetail/${text}`}
              className={css(textColors.primaryGreen)}
            >
              {text}
            </Link>
          </>
        ),
      },
      {
        key: 'project',
        title: activeLang.detailProjectPage.project,
        dataIndex: ['project', 'name'],
        sorter: (a: Tree, b: Tree) =>
          alphabeticalSort(a.project.name || '', b.project.name || ''),
        sortDirections: ['descend', 'ascend'],
        width: 200,
      },
      {
        key: 'site',
        title: activeLang.detailProjectPage.site,
        dataIndex: 'site',
        sorter: (a: Tree, b: Tree) =>
          alphabeticalSort(a.site || '', b.site || ''),
        sortDirections: ['descend', 'ascend'],
        width: 200,
      },
      {
        key: 'analysisLevel',
        title: activeLang.detailProjectPage.analysisLevel,
        dataIndex: 'analysisLevel',
        width: 120,
        align: 'center',
      },
      {
        key: 'taxon',
        title: activeLang.detailProjectPage.taxon,
        dataIndex: 'taxon',
        sorter: (a: Tree, b: Tree) =>
          alphabeticalSort(a.taxon || '', b.taxon || ''),
        sortDirections: ['descend', 'ascend'],
        width: 200,
      },
      {
        key: 'countOfPhotosTrunk',
        title: activeLang.detailProjectPage.countOfPhotosTrunk,
        dataIndex: 'countOfPhotosTrunk',
        align: 'center',
      },
      {
        key: 'countOfPhotosTreeTop',
        title: activeLang.detailProjectPage.countOfPhotosTreeTop,
        dataIndex: 'countOfPhotosTreeTop',
        align: 'center',
      },
      {
        key: 'countOfPhotosDetail',
        title: activeLang.detailProjectPage.countOfPhotosDetail,
        dataIndex: 'countOfPhotosDetail',
        align: 'center',
      },
    ];

    if (isAdmin) {
      tableData.push(
        {
          key: 'isAnalysed',
          title: activeLang.detailProjectPage.analysed,
          dataIndex: 'isAnalysed',
          align: 'center',
          render: (_, rec: Tree, _1) => {
            const { status } = rec;
            if (
              !status ||
              (status !== TreeScannerDataStatus.COREPDF &&
                status !== TreeScannerDataStatus.FINISHED)
            ) {
              return <CloseOutlined className={css(styles.rejectIcon)} />;
            } else {
              return <CheckOutlined className={css(styles.doneIcon)} />;
            }
          },
        },
        {
          key: 'isApprovedReport',
          title: activeLang.detailProjectPage.approvedReport,
          dataIndex: 'status',
          align: 'center',
          render: (_, rec: Tree, _1) => {
            const { status } = rec;
            if (!status || status !== TreeScannerDataStatus.FINISHED) {
              return <CloseOutlined className={css(styles.rejectIcon)} />;
            } else {
              return <CheckOutlined className={css(styles.doneIcon)} />;
            }
          },
        },
      );
    }
    else if (isOperator) {
      tableData.push(
        {
          key: 'hasCalculations',
          title: activeLang.detailProjectPage.calculations,
          dataIndex: 'status',
          align: 'center',
          render: (_, rec: Tree, _1) => {
            const { status } = rec;
            if (
              !status ||
              (status !== TreeScannerDataStatus.FINISHED &&
                status !== TreeScannerDataStatus.REJECTED)
            ) {
              return <CloseOutlined className={css(styles.rejectIcon)} />;
            } else {
              return <CheckOutlined className={css(styles.doneIcon)} />;
            }
          },
        },
        {
          key: 'isApproved',
          title: activeLang.detailProjectPage.rejected,
          dataIndex: 'isApproved',
          align: 'center',
          render: (_, rec: Tree, _1) => {
            const { status } = rec;
            if (status === TreeScannerDataStatus.REJECTED) {
              return <CloseOutlined className={css(styles.rejectIcon)} />;
            }
          },
        },
      );
    }

    return tableData;
  }, [isAdmin, isOperator, activeLang]);

  useEffect(() => {
    const id = match.params.id;
    if (id) {
      getTreesData(+id, currentPage);
    } else {
      getTreesData(undefined, currentPage);
    }
    setColumn(createData);
  }, [
    getActiveProject,
    getTreesData,
    match.params.id,
    createData,
    currentPage,
  ]);

  const breadcrumbs = useMemo(() => {
    const bc = [
      {
        label: activeLang.summaryPage.headerTitle,
        link: '/',
      },
    ];
    if (match.params.id) {
      bc.push({
        label: activeProject
          ? activeProject.name
          : activeLang.detailProjectPage.title,
        link: `/projectDetail/${match.params.id}/`,
      });
    }
    return bc;
  }, [activeProject, match.params.id, activeLang]);

  const table = useMemo(() => {
    if (trees) {
      const data = trees.map(tree => ({
        ...tree,
        countOfPhotosTrunk: tree.tree_scanner_images.filter(
          p => p.type === PhotoType.Trunk,
        ).length,
        countOfPhotosTreeTop: tree.tree_scanner_images.filter(
          p => p.type === PhotoType.Whole,
        ).length,
        countOfPhotosDetail: tree.tree_scanner_images.filter(
          p => p.type === PhotoType.Detail,
        ).length,
      }));
      return (
        <Row justify="center">
          <Col span={24}>
            <Table
              pagination={{
                current: currentPage + 1,
                onChange: page => setCurrentPage(page - 1),
                total,
              }}
              data-at="trees-table"
              columns={column}
              dataSource={data}
              className={css(styles.table)}
              rowKey={(record: Tree) => record.id.toString()}
            />
          </Col>
        </Row>
      );
    } else {
      return activeLang.error.noData;
    }
  }, [trees, currentPage, total, column, activeLang]);

  return (
    <>
      <Header
        logOut={logOut}
        loggedUser={loggedUser}
        breadcrumbs={breadcrumbs}
      />
      <div className={css(styles.body)}>
        <Loader requestState={loadStateTrees} errorMsg={errorMsg}>
          {table}
        </Loader>
      </div>
    </>
  );
};
