import { Line, Position, TreeHeights } from '../interfaces/canvas';
import { v4 as uuidv4 } from 'uuid';
import {
  GRID_CONSTANT_1_3,
  CANVAS_HEIGHT_TO_WINDOW_COEF,
} from '../constants/constants';
import { Photo } from '../interfaces/tree';

/**
 * Returns height in pixels of the two canvas line points
 */
const getScaleParams = (scale: Line): number => {
  // |AB| = sqrt((x2 - x1)^2 + (y2 - y1)^2) = distance between two points
  const pixelHeight = Math.sqrt(
    Math.pow(scale.pointTwo.x - scale.pointOne.x, 2) +
      Math.pow(scale.pointTwo.y - scale.pointOne.y, 2),
  );

  return Math.abs(pixelHeight);
};

/**
 * Servers for fitting image into canvas.
 * Method used for calculation ascpect ratio for the image for given canvas width.
 * Canvas width represents basically 0.27% of window innerWidth and
 * according to that measure, image height for canvas is calculated.
 * That same image HEIGHT is then used as canvas stage HEIGHT.
 */
export const canvasBackgroundImage = (
  photo: string,
  canvasWidth: number,
  padding: number,
  onLoadCallback: () => void,
): HTMLImageElement => {
  const image = new Image();
  image.src = photo;
  image.crossOrigin = 'Anonymous';

  image.onload = () => {
    // calculate image dimensions in consideration to canvas size
    const ratio = image.height / image.width;
    const height = canvasWidth * ratio;
    if (height >= window.innerHeight * CANVAS_HEIGHT_TO_WINDOW_COEF) {
      image.height = height * CANVAS_HEIGHT_TO_WINDOW_COEF;
      image.width = canvasWidth * CANVAS_HEIGHT_TO_WINDOW_COEF;
    } else {
      image.height = height;
      image.width = canvasWidth;
    }
    image.width -= padding * 2;
    image.height -= padding * 2;
    onLoadCallback();
  };
  return image;
};

/**
 * Helper method for finding extreme object from given array
 * Callback function is provided to define
 * what mathematical operation will be performed (min, max, avg etc.)
 */
export const findExtremeObject = (
  attribute: string,
  search: Position[],
  callback: any,
): Position => {
  const found = {
    ...search.find(
      co =>
        co[attribute] ===
        callback(...search.map((o: Position) => o[attribute])),
    ),
    id: uuidv4(),
  } as Position;

  return found;
};

/**
 * Calculations of the tree height and crown height
 *
 */
export const calculateHeights = (
  scale: number,
  scaleObject: Line,
  crownRecalculationPoints: Position[],
  crownObject: Position[],
): TreeHeights => {
  // find extreme points that are drawn on canvas
  const maxY = findExtremeObject('y', crownRecalculationPoints, Math.min);
  const minY = findExtremeObject('y', crownRecalculationPoints, Math.max);
  const baseY = findExtremeObject('y', crownObject, Math.max);
  const scalePixelHeight = getScaleParams(scaleObject);

  // calculate height in pixels for crown and tree
  const treeHeightPixels = minY.y - maxY.y;
  const crownHeightPixels = baseY.y - maxY.y;

  // calculate height in meters according scale height and pixel calculations
  const treeHeight = (treeHeightPixels * scale) / scalePixelHeight;
  const treeTopHeight = (crownHeightPixels * scale) / scalePixelHeight;

  return { treeHeight, treeTopHeight };
};

/**
 * Calculates trunk diameter in meters based on scale pixel height, scale real meters
 * and trunk line pixel height
 */
export const calculateTrunkDiameter = (
  scale: number,
  trunkDiameter: Line,
  scaleObject: Line,
): number => {
  const scalePixelHeight = getScaleParams(scaleObject);

  const x = trunkDiameter.pointOne.x - trunkDiameter.pointTwo.x;
  const y = trunkDiameter.pointOne.y - trunkDiameter.pointTwo.y;

  // get distance between two points
  const distance = Math.hypot(x, y);
  // diameters calc
  const diameter = (distance * scale) / scalePixelHeight;

  return diameter;
};

/**
 * Method calculates AVG value from photo analysis results
 */
export const calculateAvgValue = (
  photos: Photo[],
  name: string,
): number | undefined => {
  const values = photos.map(p => p.analysisResults && p.analysisResults[name]);

  const filteredValues = values.filter(element => element !== undefined);

  const average = (array: number[]) =>
    array.reduce((a, b) => a + b) / array.length;

  return filteredValues.length
    ? Number(average(filteredValues).toFixed(2))
    : undefined;
};

/**
 * "Constructor" for new canvas POINT object defined by coordinates
 */
export const createObjectByCoords = (x: number, y: number): Position => ({
  x,
  y,
  id: uuidv4(),
});

/**
 * "Constructor" for new canvas LINE object defined by coordinates
 */
export const createLineByPoints = (one: Position, two: Position): Line => ({
  pointOne: one,
  pointTwo: two,
  id: uuidv4(),
});

interface BoundingBox {
  lines: Line[];
  points: Position[];
}

/**
 * Method for generating bounding box (line and points) from three crown points
 * crated by user
 */
export const createCrownItems = (
  crownObject: Position[],
  baseObject: Position[],
): BoundingBox => {
  // finding location of tree top points
  const mostUpPoint = findExtremeObject('y', crownObject, Math.min);
  const mostLeftPoint = findExtremeObject('x', crownObject, Math.min);
  const mostRightPoint = findExtremeObject('x', crownObject, Math.max);
  const crownBasePoint = findExtremeObject('y', crownObject, Math.max);

  // move points to the same Y coordinate
  mostLeftPoint.y = mostUpPoint.y;
  mostRightPoint.y = mostUpPoint.y;

  // create new canvas points
  const crownBaseLeft = createObjectByCoords(mostLeftPoint.x, crownBasePoint.y);
  const crownBaseRight = createObjectByCoords(
    mostRightPoint.x,
    crownBasePoint.y,
  );
  const leftBasePoint = findExtremeObject('x', baseObject, Math.min);
  const rightBasePoint = findExtremeObject('x', baseObject, Math.max);

  const baseLeft = createObjectByCoords(mostLeftPoint.x, leftBasePoint.y);
  const baseRight = createObjectByCoords(mostRightPoint.x, rightBasePoint.y);

  // based on previously generated points,
  // create new canvas lines -- bounding box for tree top calculations
  const line1 = createLineByPoints(mostLeftPoint, mostRightPoint);
  const line2 = createLineByPoints(mostLeftPoint, baseLeft);
  const line3 = createLineByPoints(mostRightPoint, baseRight);
  const line4 = createLineByPoints(crownBaseLeft, crownBaseRight);
  const line5 = createLineByPoints(baseLeft, baseRight);

  return {
    lines: [line1, line2, line3, line4, line5],
    points: [
      mostLeftPoint,
      mostRightPoint,
      crownBasePoint,
      crownBaseLeft,
      crownBaseRight,
      baseLeft,
      baseRight,
    ],
  };
};

/**
 * Method crates new line object for canvas
 * when scale and scale line object are provided
 * line is drawn on canvas and represents 1.3m from tree base
 * line is crated with two points of 0 and canvasWidth coordinates
 */
export const createGridLine = (
  scale: number,
  scaleObject: Line,
  baseObject: Position[],
  canvasWidth: number,
): Line => {
  const scalePixelHeight = getScaleParams(scaleObject);
  // Y coordinate for 1.3m line on canvas
  const gridY = (scalePixelHeight * GRID_CONSTANT_1_3) / scale;
  // line points definition wiht X coords of 0 and canvasWidth
  const newLine: Line = {
    id: uuidv4(),
    pointOne: {
      id: uuidv4(),
      x: 0,
      y: baseObject[0].y - gridY,
    },
    pointTwo: {
      id: uuidv4(),
      x: canvasWidth,
      y: baseObject[0].y - gridY,
    },
  };
  return newLine;
};

export const isBaseAndTop = (trunkPoints: Position[]) => {
  const topPoints = trunkPoints.filter(item => item.key === 'top');
  const basePoints = trunkPoints.filter(item => item.key === 'base');

  if (basePoints.length === 2 && topPoints.length === 2) {
    return true;
  } else {
    return false;
  }
};
