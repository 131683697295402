import { StyleSheet } from 'aphrodite';
import { colors } from '../../styles/colors';

export const styles = StyleSheet.create({
  root: {
    '& > div': {
      backgroundColor: 'transparent',
    },
  },
  paper: {
    maxWidth: 400,
    maxHeight: 500,
    position: 'fixed',
    margin: 0,
  },
  rejectButton: {
    backgroundColor: colors.redPrimary,
    color: colors.white,
  },
  disabled: {
    backgroundColor: 'rgb(218 119 119)',
    color: colors.white,
  },
  textGrid: {
    paddingRight: 10,
  },
  text: {
    fontWeight: 200,
    color: 'black',
  },
  time: {
    color: '#9e9e9e',
    textAlign: 'right',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: 20,
  },
  listItem: {
    paddingTop: 3,
    paddingLeft: 5,
    paddingRight: 5,
    cursor: 'pointer',
  },
  listItemUnread: {
    backgroundColor: '#f0f0f0',
  },
  content: {
    padding: 0,
  },
  emptyList: {
    display: 'flex',
    justifyContent: 'center',
  },
});
