import { StyleSheet } from 'aphrodite';
import { colors } from '../../../styles/colors';
import { projectTheme } from '../../../styles/variables';

export const styles = StyleSheet.create({
  root: {
    padding: '30px 50px',
    [projectTheme.breakpoints.up('md')]: {
      padding: '30px 100px',
    },
  },
  header: {
    alignItems: 'flex-end',
  },
  titleTop: {
    marginBottom: 12,
  },
  tableWrapper: {
    maxWidth: '100% !important',
    margin: 0,
    padding: 30,
    textAlign: 'center',
    border: `1px solid ${colors.grey}`,
  },
  botBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  item: {
    textAlign: 'left',
  },
  rejectedMessage: {
    paddingTop: 15,
  },
  message: {
    textAlign: 'left',
    fontWeight: 200,
    paddingTop: 10,
  },
  value: {
    paddingTop: 11,
    fontWeight: 200,
    textAlign: 'left',
    marginBottom: 10,
    [projectTheme.breakpoints.up('sm')]: {
      marginBottom: 40,
    },
  },
  rejectButton: {
    backgroundColor: colors.redPrimary,
    color: colors.white,
  },
  disabled: {
    backgroundColor: 'rgb(218 119 119)',
    color: colors.white,
  },
  btn: {
    display: 'flex',
    marginBottom: 10,
    [projectTheme.breakpoints.up('sm')]: {
      margin: 0,
    },
  },
  dialog: {
    margin: 'auto',
  },
  dialogContent: {
    padding: 20,
    fontWeight: 200,
  },
});
