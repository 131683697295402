import { RcFile } from 'antd/lib/upload';
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAnalysedPhoto } from '../../../hooks/useAnalysedPhoto';
import { usePhotoUpdate } from '../../../hooks/usePhotoUpdate';
import { useTreeDetailFormData } from '../../../hooks/useTreeDetailFormData';
import { useTrunkScan } from '../../../hooks/useTrunkScan';
import { CanvasResultsWithReset } from '../../../interfaces/canvas';
import { DialogType, SiteTab } from '../../../interfaces/enums';
import { getProjectsData } from '../../../store/projects/actions';
import {
  callDeletePhoto,
  fetchTaxons,
  fetchTree,
  setActiveTreeId,
  setInitialState,
  setTrunkScan,
} from '../../../store/trees/actions';
import { getActiveTreeById } from '../../../store/trees/selectors';
import { TreeFormData } from '../components/TreeDetailForm/types';
import {
  TreeDetailActions,
  TreeDetailActionTypes,
  TreeDetailState,
} from './types';

const initialState: TreeDetailState = {
  dialogType: DialogType.NONE,
  clickedPhoto: undefined,
  diameter: undefined,
  currentTab: SiteTab.Overview,
  currentPhotoTab: {
    whole: '0',
    trunk: '0',
  },
};

const treeDetailReducer = (
  state: TreeDetailState,
  action: TreeDetailActionTypes,
) => {
  switch (action.type) {
    case TreeDetailActions.SET_DIALOG_TYPE:
      return { ...state, dialogType: action.payload };
    case TreeDetailActions.SET_CLICKED_PHOTO:
      return { ...state, clickedPhoto: action.payload };
    case TreeDetailActions.SET_DIAMETER:
      return { ...state, diameter: action.payload };
    case TreeDetailActions.SET_CURRENT_TAB:
      return { ...state, currentTab: action.payload };
    case TreeDetailActions.SET_CURRENT_PHOTO_TAB:
      return { ...state, currentPhotoTab: action.payload };
    default:
      return state;
  }
};

type UseTreeDetailStateManagerResult = ReturnType<
  typeof useTreeDetailStateManager
>;

function useTreeDetailStateManager() {
  const activeTree = useSelector(getActiveTreeById);
  const [treeDetailState, dispatch] = useReducer(
    treeDetailReducer,
    initialState,
  );

  return { treeDetailState, dispatchTreeDetailState: dispatch, activeTree };
}

export const TreeDetailStateContext =
  createContext<UseTreeDetailStateManagerResult>({
    activeTree: undefined,
    treeDetailState: initialState,
    dispatchTreeDetailState: (value: TreeDetailActionTypes) => {
      /* template function */
    },
  });

export const TreeDetailStateProvider: FC = ({ children }) => {
  const value = useTreeDetailStateManager();

  return (
    <TreeDetailStateContext.Provider value={value}>
      {children}
    </TreeDetailStateContext.Provider>
  );
};

export const useTreeDetailState = () => {
  const dispatch = useDispatch();
  const {
    formDataLoading,
    formDataError,
    formDataResponse,
    postAnalysisData,
    putAnalysisData,
  } = useTreeDetailFormData();
  const {
    putTrunkScan,
    trunkScanError,
    trunkScanLoading,
    trunkScanResponse,
    trunkScanImageData,
    trunkScanImageError,
    trunkScanImageLoading,
    putTrunkScanImage,
    postTrunkScanImage,
  } = useTrunkScan();
  const {
    updateAnalysedPhoto,
    analysedPhotoData,
    analysedPhotoLoading,
    analysedPhotoError,
  } = useAnalysedPhoto();
  const { updatePhoto, photoData, photoError, photoLoading } = usePhotoUpdate();
  const { treeDetailState, dispatchTreeDetailState, activeTree } = useContext(
    TreeDetailStateContext,
  );

  const loadTree = useCallback(
    (treeId: number) => {
      dispatch(fetchTree(treeId));
      dispatch(fetchTaxons());
      dispatch(getProjectsData());
      dispatch(setActiveTreeId({ id: treeId }));
    },
    [dispatch],
  );

  useEffect(() => {
    if (activeTree?.id) {
      loadTree(activeTree.id);
    }
    if (trunkScanResponse) {
      dispatch(setTrunkScan(trunkScanResponse.trunkScan));
    }
  }, [formDataResponse, activeTree?.id, loadTree, trunkScanResponse, dispatch]);

  const clearActiveTree = useCallback(
    () => dispatch(setInitialState()),
    [dispatch],
  );

  const deletePhoto = useCallback(
    (photoId: number) => {
      dispatch(callDeletePhoto(photoId));
    },
    [dispatch],
  );

  const savePhoto = useCallback(
    ({ reset = false, ...payload }: CanvasResultsWithReset) => {
      if (activeTree) {
        const newPhoto = activeTree.tree_scanner_images.find(
          p => p.url === payload.photoId,
        );
        if (newPhoto) {
          newPhoto.analysed = !reset;
          newPhoto.analysisResults = payload;

          updateAnalysedPhoto(`/tree-scanner-images/${newPhoto.id}`, newPhoto);
        }
      }
    },
    [activeTree, updateAnalysedPhoto],
  );

  const updateTreeForm = useCallback(
    (formValues: Partial<TreeFormData>, analyseId: number) => {
      putAnalysisData(`/tree-scanner-analysis-data/${analyseId}`, formValues);
    },
    [putAnalysisData],
  );

  const sendTreeForm = useCallback(
    (formValues: TreeFormData) => {
      if (activeTree?.tree_scanner_analysis_data?.id) {
        updateTreeForm(formValues, activeTree?.tree_scanner_analysis_data?.id);
      } else {
        postAnalysisData(`/tree-scanner-analysis-data/`, formValues);
      }
    },
    [
      activeTree?.tree_scanner_analysis_data?.id,
      postAnalysisData,
      updateTreeForm,
    ],
  );

  const sendTrunkScan = useCallback(
    (file: string | Blob | RcFile) => {
      if (activeTree?.id) {
        putTrunkScan(`/tree-scanner-data/${activeTree.id}`, file, 'trunkScan');
      }
    },
    [putTrunkScan, activeTree],
  );

  const sendTrunkScanImage = useCallback(
    (file: string | Blob | RcFile) => {
      if (activeTree?.tree_scanner_analysis_data?.id) {
        putTrunkScanImage(
          `/tree-scanner-analysis-data/${activeTree?.tree_scanner_analysis_data?.id}`,
          file,
          'trunkScanScreen',
        );
      } else {
        postTrunkScanImage(
          '/tree-scanner-analysis-data',
          file,
          'trunkScanScreen',
          { tree_scanner_data: activeTree?.id },
        );
      }
    },
    [activeTree, putTrunkScanImage, postTrunkScanImage],
  );

  return {
    formDataResponse,
    formDataError,
    formDataLoading,
    analysedPhotoData,
    analysedPhotoError,
    analysedPhotoLoading,
    treeDetailState,
    activeTree,
    trunkScanError,
    trunkScanLoading,
    trunkScanResponse,
    photoData,
    photoError,
    photoLoading,
    sendTrunkScan,
    sendTrunkScanImage,
    dispatchTreeDetailState,
    savePhoto,
    sendTreeForm,
    loadTree,
    deletePhoto,
    editPhoto: updatePhoto,
    clearActiveTree,
    updateTreeForm,
    trunkScanImageData,
    trunkScanImageError,
    trunkScanImageLoading,
  };
};
