import {
  Badge,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import { css } from 'aphrodite';
import { useTensileTest } from '../../../../contexts/TensilTestsContext';
import { TensilTestInputs } from '../../../../interfaces/tenstilTest';
import { dynamicStyles, getMeterBgColor, styles } from './TensilTests.styles';


// TODO: unify use of styling
// styling object
const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#ECF4F2',
    },
  },
}))(TableRow);

const TableHeaderStyle: React.CSSProperties = {
  padding: 5,
  backgroundColor: 'transparent',
  borderLeft: '5px solid white',
  borderRight: '5px solid white',
  borderRadius: 10,
  textAlign: 'center',
};

const SelectedTableHeaderStyle: React.CSSProperties = {
  ...TableHeaderStyle,
  backgroundColor: '#009577aa',
};

const TableCellStyle: React.CSSProperties = {
  padding: 5,
  textAlign: 'center',
};

const TableBodyCellStyle: React.CSSProperties = {
  ...TableCellStyle,
  cursor: 'pointer',
};

const TableFooterCellStyle = TableCellStyle;

const SelectedTableBodyCellStyle: React.CSSProperties = {
  width: '90%',
  backgroundColor: '#2F80ED',
  borderRadius: 8,
  color: 'white',
};


export const TensileTestSafetyCoefTable = () => {
  const {
    safetyCoeficientTable: {
      availableElastometers,
      availableInclinometers,
      availableSensors,
      isSensorSelected,
      toggleSelectedSensor,
      isMomentSelected,
      toggleSelectedMoment,
      input,
      quartilValues,
      protocol,
    },
  } = useTensileTest();

  return (
    <Table size="small" padding="none">

      <TableHead style={{ padding: '10px 0' }}>
        <TableRow>
          <TableCell />

          <TableCell key="force" style={TableHeaderStyle}>
            <Badge className={css(styles.force)}>
              {'Force' /* TODO: add translation */}
            </Badge>
          </TableCell>

          {availableSensors.map(sensor => (
            <TableCell
              key={sensor}
              style={isSensorSelected(sensor) ? SelectedTableHeaderStyle : TableHeaderStyle}
              onClick={() => toggleSelectedSensor(sensor)}
            >
              <Badge className={/* TODO: update to smth better */css(dynamicStyles(getMeterBgColor(sensor)).badge)}>
                {sensor}
              </Badge>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>



      <TableBody>
        {quartilValues.map((quartilValue, quartilIndex) => (
          <StyledTableRow key={`${quartilValue}${quartilIndex}`}>
            <TableCell style={{ padding: 5 }} component="th" scope="row">
              {`${25 * (quartilIndex + 1)}%`}
            </TableCell>


            <TableCell
              key="force"
              style={TableBodyCellStyle}
              onClick={() => toggleSelectedMoment('force', quartilValue)}
            >
              <Grid alignItems="center" justifyContent="center" container>
                <Typography style={isMomentSelected('force', quartilValue) ? SelectedTableBodyCellStyle : undefined}>
                  {input[quartilValue]?.force?.toFixed(TensilTestInputs.force.precision) ?? '×'}
                </Typography>
              </Grid>
            </TableCell>

            {availableElastometers.map((sensor, sensorNumber) => (
              <TableCell
                key={sensor}
                style={TableBodyCellStyle}
                onClick={() => toggleSelectedMoment(sensor, quartilValue)}
              >
                <Grid alignItems="center" justifyContent="center" container>
                  <Typography
                    style={isSensorSelected(sensor) &&
                      isMomentSelected(sensor, quartilValue) ? SelectedTableBodyCellStyle : undefined
                    }
                  >
                    {
                      input[quartilValue]?.elastometers[sensorNumber]?.toFixed(
                        TensilTestInputs.elastometer.precision
                      ) ?? '×'
                    }
                  </Typography>
                </Grid>
              </TableCell>
            ))}

            {availableInclinometers.map((sensor, sensorNumber) => (
              <TableCell
                key={sensor}
                style={TableBodyCellStyle}
                onClick={() => toggleSelectedMoment(sensor, quartilValue)}
              >
                <Grid alignItems="center" justifyContent="center" container>
                  <Typography
                    style={isSensorSelected(sensor) &&
                      isMomentSelected(sensor, quartilValue) ? SelectedTableBodyCellStyle : undefined
                    }
                  >
                    {
                      input[quartilValue]?.inclinometers[sensorNumber]?.value?.toFixed(
                        TensilTestInputs.inclinometer.precision
                      ) ?? '×'
                    }
                  </Typography>
                </Grid>
              </TableCell>
            ))}

          </StyledTableRow>
        ))}
      </TableBody>



      <TableFooter>
        <StyledTableRow>
          <TableCell style={TableFooterCellStyle} component="th" scope="row">
            {'Protokol' /* TODO: add translation */}
          </TableCell>

          <TableCell style={TableFooterCellStyle}>
            {protocol.force?.toFixed(TensilTestInputs.force.precision)}
          </TableCell>

          {availableElastometers.map((sensor, sensorNumber) => (
            <TableCell key={sensor} style={TableFooterCellStyle}>
              {isSensorSelected(sensor) &&
                protocol.elastometers[sensorNumber]?.toFixed(TensilTestInputs.elastometer.precision)
              }
            </TableCell>
          ))}

          {availableInclinometers.map((sensor, sensorNumber) => (
            <TableCell key={sensor} style={TableFooterCellStyle}>
              {isSensorSelected(sensor) &&
                protocol.inclinometers[sensorNumber]?.toFixed(TensilTestInputs.inclinometer.precision)
              }
            </TableCell>
          ))}
        </StyledTableRow>
      </TableFooter>

    </Table>
  );
};
