import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getTensilTestTaxons } from '../helpers/apiHelper';

export const useTensilTestTaxon = (defaultValue?: string) => {
  const [taxonQuery, setTaxonQuery] = useState<string>(defaultValue || '');
  const [taxons, setTaxons] = useState<string[]>([]);
  const { refetch, isLoading } = useQuery(
    taxonQuery,
    () => getTensilTestTaxons(taxonQuery),
    {
      enabled: false,
      onSuccess: d => setTaxons(d.data),
    },
  );

  useEffect(() => {
    refetch();
  }, [refetch, taxonQuery]);

  return { taxons, setTaxonQuery, isTaxonsLoading: isLoading };
};
