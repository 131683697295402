import { StyleSheet } from 'aphrodite';
import { colors } from '../../../styles/colors';

export const styles = StyleSheet.create({
  container: {
    marginTop: 30,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingBottom: 50,
  },
  flexHalf: {
    flex: 1,
    margin: '0 15px',
  },
  contentContainer: {
    marginTop: 8,
  },
  gridWrapper: {
    paddingRight: '3%',
    minHeight: 600,
  },
  map: {
    height: 300,
    width: '100%',
    position: 'relative',
  },
  containerReject: {
    display: 'flex',
    maxWidth: 'none',
  },
  containerNotice: {
    backgroundColor: colors.redPrimary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0px 14px',
    padding: '5px 20px',
  },
  rejectTitile: {
    fontSize: '14px',
    color: colors.white,
    textAlign: 'center',
    marginBottom: 10,
  },
  rejectMessage: {
    fontSize: '12px',
    color: colors.white,
    textAlign: 'center',
  },
  selectContainer: {
    marginTop: 15,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});
