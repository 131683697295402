import { connect } from 'react-redux';
import { StateProps, DispatchProps } from './ProjectDetailPage';
import { State } from '../../store/combinedReducers';
import { ProjectDetailPage } from './ProjectDetailPage';
import { bindActionCreators, Dispatch } from 'redux';
import {
  setActiveProjectById,
  getActiveProject,
} from '../../store/projects/actions';
import { logOut } from '../../store/general/actions';
import { getTreesData } from '../../store/trees/actions';

const mapStateToProps = (state: State): StateProps => ({
  activeProject: state.projects.activeProject,
  loggedUser: state.general.loggedUser,
  errorMsg: state.general.errorMsg,
  trees: state.trees.trees,
  loadStateTrees: state.trees.treeLoadState,
  total: state.trees.total,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  ...bindActionCreators(
    {
      setActiveProjectById,
      logOut,
      getTreesData,
      getActiveProject,
    },
    dispatch,
  ),
});

export const ProjectDetailCont = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectDetailPage);
