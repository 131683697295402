import { StyleSheet } from 'aphrodite';

export const styles = StyleSheet.create({
  text: {
    border: '1px solid #f0f0f0',
    textAlign: 'left',
    padding: 10,
    fontSize: '1rem',
    fontWeight: 200,
  },
});
