import { Divider, Grid, ListItemIcon, ListItemText, Menu, MenuItem, MenuProps, SvgIcon, SvgIconProps } from '@material-ui/core';
import { withStyles, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { FC, useState } from 'react';
import { useTensileTest } from '../../../../contexts/TensilTestsContext';
import { colors } from '../../../../styles/colors';
import { useCurrentLanguage } from '../../../../translations/languageSelector';
import AppButton from '../Form/AppButton/AppButton';
import { TensileTestStatus } from '../../../../interfaces/tenstilTest';
import {
  ArrowDropDownOutlined,
  AssessmentOutlined,
  AssignmentLateOutlined,
  AssignmentTurnedInOutlined,
  DragIndicatorOutlined,
  InfoOutlined,
  VerticalAlignBottom,
  VerticalAlignTop,
} from '@material-ui/icons';

import { DotChartOutlined } from '@ant-design/icons';
import { REPORTS_URL } from '../../../../constants/constants';


const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      border: `1px solid ${theme.palette.divider}`,
      margin: theme.spacing(2, 0, 4),
      borderRadius: '4px',
      backgroundColor: theme.palette.background.paper,
    },
    divider: {
      margin: theme.spacing(0.5, 0, 0.5, 1),
      '&:first-child': {
        margin: theme.spacing(0.5, 0, 0.5, 1),
        visibility: 'hidden',
      },
    },
    dividerIcon: {
      fontSize: '1rem',
    },
    buttonIcon: {
      fontSize: '1.25rem',
      marginRight: theme.spacing(0.5),
    },
    finishedItem: {
      color: colors.greenPrimary,
      '& $buttonIcon': {
        color: colors.greenPrimary,
      },
    },
    finishedButtonIcon: {
      color: colors.greenPrimary,
      fontSize: '1.25rem',
      marginRight: theme.spacing(0.5),
    },
    todoItem: {
      color: colors.darkBlue,
      '& $buttonIcon': {
        color: colors.darkBlue,
      }
    }
  }),
);

const StyledToggleButtonGroup = withStyles(theme => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    color: colors.textDark,
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);



const AutoFixHighIcon: FC<SvgIconProps> = props => (
  <SvgIcon {...props}>
    <path d="M7.5 5.6 10 7 8.6 4.5 10 2 7.5 3.4 5 2l1.4 2.5L5 7zm12 9.8L17 14l1.4 2.5L17 19l2.5-1.4L22 19l-1.4-2.5L22 14zM22 2l-2.5 1.4L17 2l1.4 2.5L17 7l2.5-1.4L22 7l-1.4-2.5zm-7.63 5.29a.9959.9959 0 0 0-1.41 0L1.29 18.96c-.39.39-.39 1.02 0 1.41l2.34 2.34c.39.39 1.02.39 1.41 0L16.7 11.05c.39-.39.39-1.02 0-1.41zm-1.03 5.49-2.12-2.12 2.44-2.44 2.12 2.12z" />
  </SvgIcon>
);

const PanelDivider: FC = () => {
  const toolbarClasses = useToolbarStyles();
  return (
    <>
      <Divider flexItem orientation="vertical" className={toolbarClasses.divider} />
      <DragIndicatorOutlined color="disabled" className={toolbarClasses.dividerIcon} />
    </>
  );
};


const StyledMenu = withStyles(
  theme => ({
    paper: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 0,
    },
  }),
)((props: MenuProps) => (
  <Menu
    elevation={1}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
));


const TensileTestSelector: FC<{
  activeTensileTest: any;
  tensileTests: any[];
  onPaginationChange: (tensileTestId: number) => void;
}> = ({ activeTensileTest, tensileTests, onPaginationChange }) => {
  const toolbarClasses = useToolbarStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuToggle = (event: React.MouseEvent<HTMLElement>) => {
    if (tensileTests.length > 1) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (newActiveTensileTestId: number) => {
    setAnchorEl(null);
    onPaginationChange(newActiveTensileTestId);
  };

  return (
    <>
      <AppButton variant="text" size="small" onClick={handleMenuToggle} style={{ paddingTop: 11, paddingBottom: 11 }}>
        {
          activeTensileTest.status === TensileTestStatus.FINISHED ?
            <AssignmentTurnedInOutlined className={toolbarClasses.finishedButtonIcon} /> :
            <AssignmentLateOutlined className={toolbarClasses.buttonIcon} />
        }
        {'Tensile test no.'} {activeTensileTest.id}
        {tensileTests.length > 1 && <ArrowDropDownOutlined />}
      </AppButton>

      {tensileTests.length > 1 && (
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          variant="selectedMenu"
        >
          {tensileTests.map(tensileTest => (
            <MenuItem
              selected={tensileTest.id === activeTensileTest.id}
              key={tensileTest.id}
              onClick={() => handleSelect(tensileTest.id)}
              className={
                tensileTest.status === TensileTestStatus.FINISHED ?
                  toolbarClasses.finishedItem :
                  toolbarClasses.todoItem
              }
            >
              <ListItemIcon>
                {tensileTest.status === TensileTestStatus.FINISHED ?
                  <AssignmentTurnedInOutlined className={toolbarClasses.buttonIcon} /> :
                  <AssignmentLateOutlined className={toolbarClasses.buttonIcon} />
                }
              </ListItemIcon>
              <ListItemText primary={`Tensile test no. ${tensileTest.id}`} />
            </MenuItem>
          ))}
        </StyledMenu>
      )}
    </>
  );
};

export const TensileTestHeader: FC = () => {
  const activeLang = useCurrentLanguage();
  const [infoShown, setInfoShown] = useState<boolean>(false);

  const {
    common: {
      activeTensileTest,
      handlePaginationChange: onPaginationChange,
      tensileTests,
    },
    computation: {
      dataRange: {
        boundaryPickingMethod,
        setBoundaryPickingMethod
      }
    },
  } = useTensileTest();

  const toolbarClasses = useToolbarStyles();
  return (
    <>
      <Grid
        container
        alignItems="center"
        className={toolbarClasses.root}
      >

        <PanelDivider />

        <TensileTestSelector
          tensileTests={tensileTests}
          activeTensileTest={activeTensileTest}
          onPaginationChange={onPaginationChange}
        />

        {/*
        <StyledToggleButtonGroup
          size="small"
          exclusive
          color='primary'
          value={activeTensileTest.id}
          onChange={(_e, newActiveTensileTest) => onPaginationChange(newActiveTensileTest)}
        >
          {tensileTests?.map((tensileTest) => (
            <ToggleButton key={tensileTest.id} value={tensileTest.id}>
              {tensileTest.status === TensileTestStatus.FINISHED ?
                <AssignmentTurnedInOutlined className={toolbarClasses.buttonIcon} /> :
                <AssignmentLateOutlined className={toolbarClasses.buttonIcon} />
              }
              {" no."} {tensileTest.id}
            </ToggleButton>
          ))}
        </StyledToggleButtonGroup>
        */}


        <PanelDivider />

        <StyledToggleButtonGroup
          size="small"
          exclusive
          value={boundaryPickingMethod}
          onChange={(_e, newMethod) => setBoundaryPickingMethod(newMethod) /* TODO: add enum for values? */}
        >
          <ToggleButton value="min">
            <VerticalAlignBottom className={toolbarClasses.buttonIcon} />
            MIN
          </ToggleButton>

          <ToggleButton value="max">
            <VerticalAlignTop className={toolbarClasses.buttonIcon} />
            MAX
          </ToggleButton>

          <ToggleButton value="auto">
            <AutoFixHighIcon className={toolbarClasses.buttonIcon} />
            AUTO
          </ToggleButton>
        </StyledToggleButtonGroup>

        <PanelDivider />

        <StyledToggleButtonGroup
          size="small"
          value={[infoShown]}
          onChange={() => setInfoShown(!infoShown)}
        >
          <ToggleButton value={true}>
            <InfoOutlined className={toolbarClasses.buttonIcon} />
            {'Additional Info'}
          </ToggleButton>
        </StyledToggleButtonGroup>

        <PanelDivider />

        <AppButton href={activeTensileTest.inputFileUrl} target="_blank" tooltipTitle={'Shows orignal file containing raw unprocessed data sent for import'}>
          <DotChartOutlined className={toolbarClasses.buttonIcon} />
          {activeLang.treeDetailPage.tensilTest.showRawData}
        </AppButton>

        <AppButton
          href={new URL(`pulling-test/${activeTensileTest.id}`, REPORTS_URL).href}
          target="_blank"
          disabled={activeTensileTest.status !== TensileTestStatus.FINISHED}
          tooltipTitle={activeTensileTest.status !== TensileTestStatus.FINISHED ? 'Tensile test report can be viewed only after test evaluation is finished. Finish evaluation and try again' : undefined}
        >
          <AssessmentOutlined className={toolbarClasses.buttonIcon} />
          {'Final report' /* TODO: translate */}
        </AppButton>
      </Grid>




      {!infoShown ? null : (
        <Grid container justifyContent="space-between" alignItems="flex-end">
          <Grid item>
            {activeTensileTest.directionData.note && (
              <div>
                <h3>{activeLang.treeDetailPage.tensilTest.note}:</h3>
                <p>{activeTensileTest.directionData.note}</p>
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};
