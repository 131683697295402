import { colors } from './colors';

export interface LineConfig {
  strokeWidth: number;
  shadowBlur: number;
  stroke: string;
  dash?: number[];
  shadowColor?: string;
}

export interface PointConfig {
  radius: number;
  shadowBlur: number;
  strokeWidth: number;
  stroke: string;
  dragColor: string;
  fillColor: string;
}

export interface PointDragConfig {
  draggable: boolean;
  fill: string;
  onDragStart: () => void;
  onTouchStart: () => void;
  onDragEnd: (e: any) => void;
  onTouchEnd: (e: any) => void;
}

export interface TextConfig {
  text: string;
  fill: string;
  fontFamily: string;
  fontStyle: string;
  fontSize: number;
}

export const scaleConfig: LineConfig = {
  strokeWidth: 3,
  shadowBlur: 2,
  stroke: colors.redPrimary,
};

export const baseConfig: LineConfig = {
  strokeWidth: 2,
  shadowBlur: 2,
  stroke: colors.redPrimary,
};

export const gridConfig: LineConfig = {
  strokeWidth: 3,
  shadowBlur: 10,
  shadowColor: colors.white,
  stroke: colors.mapElements.trunkPoint,
  dash: [4, 4],
};

export const trunkLineConfig: LineConfig = {
  strokeWidth: 3,
  stroke: colors.white,
  shadowBlur: 2,
};

export const crownLineConfig: LineConfig = {
  strokeWidth: 2,
  stroke: colors.white,
  shadowBlur: 1,
};

export const basePoint: PointConfig = {
  radius: 6,
  shadowBlur: 3,
  strokeWidth: 1,
  stroke: colors.white,
  dragColor: colors.grayBtn,
  fillColor: colors.redPrimary,
};

export const crownPoint: PointConfig = {
  radius: 6,
  shadowBlur: 3,
  strokeWidth: 1,
  stroke: colors.white,
  dragColor: colors.grayBtn,
  fillColor: colors.greenPrimary,
};

export const crownPointGenerated: PointConfig = {
  radius: 2,
  shadowBlur: 2,
  strokeWidth: 1,
  stroke: colors.white,
  dragColor: colors.grayBtn,
  fillColor: colors.yellow,
};

export const trunkPoint: PointConfig = {
  radius: 4,
  strokeWidth: 1,
  shadowBlur: 2,
  stroke: colors.white,
  dragColor: colors.grayBtn,
  fillColor: colors.white,
};

export const scalePoint: PointConfig = {
  radius: 4,
  strokeWidth: 1,
  shadowBlur: 2,
  stroke: colors.white,
  dragColor: colors.grayBtn,
  fillColor: colors.redPrimary,
};

export const scaleText: TextConfig = {
  text: '1,3 m',
  fill: colors.mapElements.trunkPoint,
  fontFamily: 'Roboto',
  fontStyle: 'bold',
  fontSize: 14,
};
