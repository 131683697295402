import { useMutation } from 'react-query';
import {
  readOneNotification,
  readAllNotifications,
  deleteAllNotifications,
} from '../helpers/apiHelper';
import { NotificationDTOExtended } from '../interfaces/notification';

const readOne = async (notificationId: number) => {
  await readOneNotification({ notificationId });
};

const deleteAll = async (notifications: NotificationDTOExtended[]) => {
  if (notifications) {
    const notificationIds = notifications
      .filter(notification => notification.hiddenAt === null)
      .map(notification => notification.id);

    await deleteAllNotifications({ notificationIds });
  }
};

const readAll = async (notifications: NotificationDTOExtended[]) => {
  if (notifications) {
    const notificationIds = notifications
      ?.filter(notification => notification.viewedAt === null)
      .map(notification => notification.id);

    await readAllNotifications({ notificationIds });
  }
};

export const useNotification = () => {
  const readOneNotificationMutation = useMutation((notificationId: number) =>
    readOne(notificationId),
  );
  const readAllNotificationsMutation = useMutation(
    (notifications: NotificationDTOExtended[]) => readAll(notifications),
  );
  const deleteAllNotificationsMutation = useMutation(
    (notifications: NotificationDTOExtended[]) => deleteAll(notifications),
  );
  return {
    readOneNotificationMutation,
    readAllNotificationsMutation,
    deleteAllNotificationsMutation,
  };
};
