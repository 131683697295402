import { DialogType, SiteTab } from '../../../interfaces/enums';
import { Photo } from '../../../interfaces/tree';

export interface TreeDetailState {
  dialogType: DialogType;
  clickedPhoto: Photo | undefined;
  diameter: number | undefined;
  currentTab: SiteTab;
  currentPhotoTab: {
    whole: string;
    trunk: string;
  };
}

export enum TreeDetailActions {
  SET_DIALOG_TYPE = 'SET_DIALOG_TYPE',
  SET_CLICKED_PHOTO = 'SET_CLICKED_PHOTO',
  SET_DIAMETER = 'SET_DIAMETER',
  SET_SAVE_CLICKED = 'SET_SAVE_CLICKED',
  SET_CURRENT_TAB = 'SET_CURRENT_TAB',
  SET_CURRENT_PHOTO_TAB = 'SET_CURRENT_PHOTO_TAB',
}

export type TreeDetailActionTypes =
  | {
      type: TreeDetailActions.SET_DIALOG_TYPE;
      payload: DialogType;
    }
  | {
      type: TreeDetailActions.SET_CLICKED_PHOTO;
      payload: Photo | undefined;
    }
  | {
      type: TreeDetailActions.SET_DIAMETER;
      payload?: number;
    }
  | {
      type: TreeDetailActions.SET_SAVE_CLICKED;
      payload: boolean;
    }
  | {
      type: TreeDetailActions.SET_CURRENT_TAB;
      payload: SiteTab;
    }
  | {
      type: TreeDetailActions.SET_CURRENT_PHOTO_TAB;
      payload: {
        whole: string;
        trunk: string;
      };
    };
