import { useRef, useEffect, useCallback, useMemo } from 'react';
import ImageEditor from '@toast-ui/react-image-editor';
import 'tui-image-editor/dist/tui-image-editor.css';

type TEditorRef = {
  getInstance: () => any;
  getRootElement: () => any;
};

interface UseEditorReturnState {
  rotate: (angle: number) => void;
  filter: (percent: number) => void;
  toggleCrop: () => void;
  applyCrop: () => void;
  getImage: () => string;
  setCropRatio: (ratio: number) => void;
  drawingMode: () => 'CROPPER' | 'NORMAL';
  editorMemo: JSX.Element;
}

/**
 * @param {string} url load image from
 * @param {string} className of imageEditor
 * @version 1.0
 */
export const useEditor = (
  url: string,
  className: string,
): UseEditorReturnState => {
  const editorRef = useRef<TEditorRef | null>(null);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.getRootElement().classList.add(className);
      const editor = editorRef.current.getInstance();
      const loadImage = async () => {
        try {
          await editor.loadImageFromURL(url.replace('https', 'http'), ' ');
        } catch (error) {
          console.error(error);
        }
      };

      loadImage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  /**
   * Rotate image by angle
   * @param {number} angle in degrees.
   * @version 1.0
   */
  const rotate = useCallback((angle: number) => {
    if (editorRef.current) {
      const editor = editorRef.current.getInstance();
      editor.rotate(angle);
    }
  }, []);

  /**
   * Sets amount of brightness -100 darker, +100 brighter
   * @param {number} percent in interval -100 to 100.
   * @version 1.0
   */
  const filter = useCallback((percent: number) => {
    if (editorRef.current) {
      const editor = editorRef.current.getInstance();
      editor.applyFilter('brightness', { brightness: percent / 100 });
    }
  }, []);

  /**
   * method to Toggle between CROPPER and NORMAL mode
   * @version 1.0
   */
  const toggleCrop = useCallback(() => {
    if (editorRef.current) {
      const editor = editorRef.current.getInstance();

      if (editor.getDrawingMode() !== 'CROPPER') {
        editor.startDrawingMode('CROPPER');
      } else {
        editor.stopDrawingMode();
      }
    }
  }, []);

  /**
   * method toggle Ratio
   * @param {1 | 1.5 | 1.3333333333333333 | 1.25 | 1.7777777777777777 | 0} mode
   * @version 1.0
   */
  const setCropRatio = useCallback((mode: number) => {
    if (editorRef.current) {
      const editor = editorRef.current.getInstance();

      if (editor.getDrawingMode() === 'CROPPER') {
        editor.setCropzoneRect(mode);
      }
    }
  }, []);

  /**
   * method to Apply crop
   * @version 1.0
   */
  const applyCrop = useCallback(() => {
    if (editorRef.current) {
      const editor = editorRef.current.getInstance();

      if (editor.getDrawingMode() === 'CROPPER') {
        editor.crop(editor.getCropzoneRect()).then(() => {
          editor.stopDrawingMode();
        });
      }
    }
  }, []);

  /**
   * method to Export edited image
   * @version 1.0
   */
  const getImage = useCallback((): string => {
    if (editorRef.current) {
      const editor = editorRef.current.getInstance();
      return editor.toDataURL();
    }
    return '';
  }, []);

  const editorMemo = useMemo(
    () => <ImageEditor ref={editorRef} usageStatistics={false} />,
    [],
  );

  return {
    rotate,
    filter,
    toggleCrop,
    applyCrop,
    getImage,
    setCropRatio,
    drawingMode: () => editorRef.current?.getInstance().getDrawingMode(),
    editorMemo,
  };
};
