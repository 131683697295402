import { useState, useEffect } from 'react';

const useKeyPress = (keyCodes: number[]) => {
  const [isCmdDown, setIsCmdDown] = useState(false);

  useEffect(() => {
    const handleDown = event => {
      if (keyCodes.includes(event.keyCode)) {
        setIsCmdDown(true);
      }
    };
    const handleUp = event => {
      if (keyCodes.includes(event.keyCode)) {
        setIsCmdDown(false);
      }
    };
    window.addEventListener('keydown', handleDown);
    window.addEventListener('keyup', handleUp);

    return () => {
      window.removeEventListener('keydown', handleDown);
      window.removeEventListener('keyup', handleUp);
    };
  }, [keyCodes]);

  return { isCmdDown };
};

export default useKeyPress;
