import { Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { FC } from 'react';
import { LoadingState } from '../../interfaces/enums';
import { StyleSheet, css } from 'aphrodite';
import { useCurrentLanguage } from '../../translations/languageSelector';

const Spinner: FC = () => (
  <Grid container align-items="center" justifyContent="center">
    <Grid item xs={12}>
      <div className={css(styles.loader)}>
        <CircularProgress color="primary" />
      </div>
    </Grid>
  </Grid>
);

interface LoaderProps {
  requestState: LoadingState;
  errorMsg?: string;
}

export const Loader: FC<LoaderProps> = props => {
  const activeLang = useCurrentLanguage();
  return (
    <div className={css(styles.loaderPagePosition)}>
      {props.requestState === LoadingState.Success && props.children}
      {props.requestState === LoadingState.Loading && <Spinner />}
      {props.requestState === LoadingState.Failure && (
        <div>
          {props.errorMsg ? props.errorMsg : activeLang.error.unknownError}
        </div>
      )}
    </div>
  );
};

const styles = StyleSheet.create({
  loader: {
    height: '100%',
    width: '100%',
    position: 'relative',
    textAlign: 'center',
    paddingTop: '10%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  loaderPagePosition: {
    height: '100%',
    width: '100%',
    flex: 1,
  },
});
