import { useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { css } from 'aphrodite';
import { DialogType, SiteTab } from '../../../interfaces/enums';
import { PhotoType } from '../../../interfaces/tree';
import { textColors } from '../../../styles/colors';
import { fontsWeights } from '../../../styles/variables';
import { useCurrentLanguage } from '../../../translations/languageSelector';
import { styles } from './WholeTreePage.styles';
import TreePhotos from '../components/TreePhotos/TreePhotos';
import { useTreeDetailState } from '../TreeDetailProvider/TreeDetailStateProvider';
import { TreeDetailActions } from '../TreeDetailProvider/types';

const WholeTreePage = () => {
  const activeLang = useCurrentLanguage();
  const { wholeTree } = activeLang.treeDetailPage.trunkPart;

  const { dispatchTreeDetailState } = useTreeDetailState();

  useEffect(() => {
    dispatchTreeDetailState({
      type: TreeDetailActions.SET_CURRENT_TAB,
      // @ts-ignore
      payload: SiteTab.TreeTopAnalysis,
    });
  }, [dispatchTreeDetailState]);

  return (
    <Grid
      container
      className={css(styles.contentContainer)}
      justifyContent="space-between"
    >
      <Grid item xs={12}>
        <Typography
          variant="subtitle1"
          className={css(
            textColors.textDark,
            fontsWeights.bold,
            styles.titleTop,
          )}
        >
          {wholeTree}
        </Typography>
        <TreePhotos photoType={PhotoType.Whole} dialogType={DialogType.WHOLE} />
      </Grid>
    </Grid>
  );
};

export default WholeTreePage;
