import { Grid, InputAdornment, TextField } from '@material-ui/core';
import { css } from 'aphrodite';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { styles } from './AppInput.styles';
import { AppInputProps } from './types';

const AppInput: FC<AppInputProps> = ({
  name,
  showMessage,
  rules,
  textFieldProps: { variant = 'outlined', ...textFieldProps },
  wrapped = true,
  unit,
  required,
  size,
  className,
  marginBottom = 40,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = errors[name];

  return (
    <>
      {wrapped ? (
        <Grid className={css(styles.input)} style={{ marginBottom }}>
          <Controller
            rules={rules}
            control={control}
            name={name}
            render={({ field }) => (
              <TextField
                {...field}
                {...textFieldProps}
                InputProps={{
                  ...field,
                  endAdornment: (
                    <InputAdornment position="end">
                      {unit ?? ''}
                    </InputAdornment>
                  ),
                  inputProps: { min: 0 },
                }}
                helperText={showMessage ? error?.message : undefined}
                error={!!error?.message}
                fullWidth
                variant={variant}
                required={required}
              />
            )}
          />
        </Grid>
      ) : (
        <Controller
          rules={rules}
          control={control}
          name={name}
          render={({ field }) => (
            <TextField
              InputProps={{
                ...field,
                endAdornment: (
                  <InputAdornment position="end">
                    {unit ?? ''}
                  </InputAdornment>
                ),
                inputProps: { min: 0 },
              }}
              helperText={showMessage ? error?.message : undefined}
              error={!!error?.message}
              fullWidth
              size={size}
              className={className}
              {...field}
              {...textFieldProps}
              variant={variant}
              required={required}
            />
          )}
        />
      )}
    </>
  );
};

export default AppInput;
