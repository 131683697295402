import { CalculatedProtocolDataDto, ProtocolDto } from '../helpers/apiHelper';

export enum ElastometerTag {
  E1 = 'E1',
  E2 = 'E2',
  E3 = 'E3',
  E4 = 'E4',
}

export interface Elastometer {
  name: ElastometerTag;
  height?: number;
  diameters?: number[];
  color?: string;
}

export const ElastometerTagsConfig: {
  [key in ElastometerTag]: Elastometer;
} = {
  [ElastometerTag.E1]: { name: ElastometerTag.E1, color: '#2F80ED' },
  [ElastometerTag.E2]: { name: ElastometerTag.E2, color: '#F2994A' },
  [ElastometerTag.E3]: { name: ElastometerTag.E3, color: '#CF91F9' },
  [ElastometerTag.E4]: { name: ElastometerTag.E4, color: '#FF56C2' },
};


export enum InclinometerTag {
  I1 = 'I1',
  I2 = 'I2',
  I3 = 'I3',
  I4 = 'I4',
}

type InclinometerAxis = 'x' | 'y';
export type InclinometerAxisTag = `${InclinometerTag}${InclinometerAxis}`;
export type InclinometersDataTag = InclinometerAxisTag | InclinometerTag;

export interface Inclinometer {
  name: InclinometerTag;
  color: string;
  partials?: InclinometerAxis[];
}

export const InclinometerTagsConfig: {
  [key in InclinometerTag]: Inclinometer;
} = {
  [InclinometerTag.I1]: { name: InclinometerTag.I1, color: '#2F80ED' },
  [InclinometerTag.I2]: { name: InclinometerTag.I2, color: '#2F80ED' },
  [InclinometerTag.I3]: { name: InclinometerTag.I3, color: '#CF91F9' },
  [InclinometerTag.I4]: { name: InclinometerTag.I4, color: '#FF56C2' },
};

export type ChartsData = {
  number: number,
  time: number,
} & {
  [I in InclinometersDataTag | ElastometerTag]?: number;
};

export type SensorTag = InclinometerTag | ElastometerTag;
export type MomentTag = SensorTag | 'force';

export type SelectedMoments = {
  [K in MomentTag]: Input['number'][];
};

export enum BallastHeightLevelType {
  PULL = 'pull',
  PUSH = 'push',
}

export interface BallastHeightLevel {
  type: BallastHeightLevelType;
  height?: number;
  elestomer?: Elastometer;
  isActive: boolean;
}

export interface Location {
  latitude: number;
  longitude: number;
}

export interface Ballast {
  id: string;
  location: Location;
  isNewlyAdded?: boolean;
  elastomers?: BallastHeightLevel[];
  inclinometersHeight?: number;
  inclinometers?: (Inclinometer | undefined)[];
  anchorageHeight?: number;
  ropeAngle?: number;
  ktDistance?: number;
  note?: string;
}

export interface DirectionOfLoad {
  location: Location;
  inclinometers: Inclinometer[];
  elastomers: BallastHeightLevel[];
  note?: string;
  tensilTests?: TensilTest[];
  anchorageHeight?: number;
  ropeAngle?: number;
  ktDistance?: number;
}

export type InputData = {
  number: number;
  elastometers: number[];
  inclinometers: { value: number, x?: number, y?: number }[];
  force: number;
  ropeAngle: number;
  timestamp: string;
  time: number;
};

export type Input = {
  number: number;
  force: number;
  elastometers: (number)[];
  inclinometers: { value: number, x?: number, y?: number }[];
  ropeAngle: number;
  timestamp: string;
  time: number;
};

export type InputDataKey = keyof InputData;

export type DirectionData = {
  elastomers: BallastHeightLevel[];
  inclinometers: Inclinometer[];
  isNewlyAdded: boolean;
  location: { latitude: number; longitude: number };
  note?: string;
  uid: string;
  anchorageHeight: number;
  ropeAngle: number;
  ktDistance: number;
  barkThickness: number;
};

export enum TensileTestStatus {
  CREATED = 'CREATED',
  FINISHED = 'FINISHED',
}
export interface TensilTest {
  id: number;
  uid: string;
  status: TensileTestStatus;
  inputFileUrl: string;
  pdfUrl: string;
  directionData: DirectionData;
  data: {
    results: TCalculations;
    inputData: InputData[];
    calculationsInputs: {
      protocol: any;
      selectedValue: InputData;
      selectedMoments: SelectedState;
    };
  };
}

export type TensilTestsDTO = {
  taxon: string;
  id: number;
  uid: string;
  status: TensileTestStatus;
  inputFileUrl: string;
  pdfUrl: string;
  directionData: DirectionData;
  data: {
    results: TCalculations;
    inputData: InputData[];
    input: Input[];
    availableElastometers?: Elastometer[];
    availableInclinometers?: Inclinometer[];
    calculations: {
      input: {
        min: Input['number'],
        max: Input['number'],
        selectedSensors: SensorTag[],
        selectedMoments: SelectedMoments,
      },
      results: CalculatedProtocolDataDto,
      protocol: ProtocolDto,
    },
    calculationsInputs: {
      protocol: any;
      selectedValues: [InputData, InputData];
      selectedMoments: SelectedState;
    };
  };
};

export type SelectedState = {
  force100:   InputData[];
  elasto90:   InputData[];
  elasto91:   InputData[];
  elasto92:   InputData[];
  elasto93:   InputData[];
  inclino80:  InputData[];
  inclino80x: InputData[];
  inclino80y: InputData[];
  inclino81:  InputData[];
  inclino81x: InputData[];
  inclino81y: InputData[];
  inclino82:  InputData[];
  inclino82x: InputData[];
  inclino82y: InputData[];
  inclino83:  InputData[];
  inclino83x: InputData[];
  inclino83y: InputData[];
};

export type TCalculation = {
  safetyFactor: number;
  ropeAngle: number;
  shearStrength: number;
  bendingMomentAtMeasure: number;
  momentOfInertia: number;
  shareOfWindLoad: number;
  relativeDeformation: number;
  measurementPosition: number;
  criticalBendingMoment: number;
  inclinometer: number;
  bendingMomentAtBase: number;
  substitutionForce: number;
  resistanceToUprooting: number;
  crossSectionModule: number;
  anchorageHeight: number;
  trunkAvg1: number;
  variableDeformation: number;
  crownExcentricity: number;
  trunkAvg2: number;
  elastometer: number;
  shearStress: number;
  resistanceToFracture: number;
  cleanDiameter: number;
  barkThickness: number;
  ktDistance: number;
  dynamometer: number;
};

export type TCalculations = {
  E1: TCalculation;
  E2: TCalculation;
  E3: TCalculation;
  E4: TCalculation;
};


export const UNITS = {
  DEGREES: '°',
  DIMENSIONLESS: '',
  PERCENTAGE: '%',
  KN: ' kN',
  KNCM2: 'kN/cm²',
  KNCM3: 'kN/cm³',
  KNM: 'kN⋅m',
  M: 'm',
  CM: 'cm',
  CM3: 'cm³',
  MICROM: 'µm',
} as const;

export type UNIT = typeof UNITS[keyof typeof UNITS];

export const CALCULATIONS: Record<string, TensilTestTypeDisplayConfig> = {
  measurementPosition:     { unit: UNITS.M,             precision: 2 },
  bendingMomentAtBase:     { unit: UNITS.KNM,           precision: 2 },
  bendingMomentAtMeasure:  { unit: UNITS.KNM,           precision: 2 },
  criticalBendingMoment:   { unit: UNITS.KNM,           precision: 2 },
  resistanceToFracture:    { unit: UNITS.PERCENTAGE,    precision: 2 },
  resistanceToUprooting:   { unit: UNITS.PERCENTAGE,    precision: 2 },
  safetyFactor:            { unit: UNITS.PERCENTAGE,    precision: 2 },
  cleanDiameter:           { unit: UNITS.CM,            precision: 2 },
  crossSectionModule:      { unit: UNITS.KNCM3,         precision: 2 },
  crownExcentricity:       { unit: UNITS.M,             precision: 2 },
  dynamometer:             { unit: UNITS.KN,            precision: 2 },
  momentOfInertia:         { unit: UNITS.CM3,           precision: 2 },
  relativeDeformation:     { unit: UNITS.DIMENSIONLESS, precision: 5 },
  shareOfWindLoad:         { unit: UNITS.PERCENTAGE,    precision: 2 },
  shearStrength:           { unit: UNITS.KNCM2,         precision: 2 },
  shearStress:             { unit: UNITS.KNCM2,         precision: 2 },
  substitutionForce:       { unit: UNITS.KNM,           precision: 2 },
  variableDeformation:     { unit: UNITS.PERCENTAGE,    precision: 2 },
  trunkAvg1:               { unit: UNITS.CM,            precision: 2 },
  trunkAvg2:               { unit: UNITS.CM,            precision: 2 },
  E:                       { unit: UNITS.DIMENSIONLESS, precision: 2 },
} as const;

export type CALCULATION_ID = keyof typeof CALCULATIONS;

type TensilTestTypeDisplayConfig = {
  unit: UNIT;
  precision: number;
};

export const TensilTestInputs: Record<string, TensilTestTypeDisplayConfig> = {
  force:        { unit: UNITS.KN,      precision: 2 },
  elastometer:  { unit: UNITS.MICROM,  precision: 0 },
  inclinometer: { unit: UNITS.DEGREES, precision: 3 },

  force100:   { unit: UNITS.KN,      precision: 2 },
  elasto90:   { unit: UNITS.MICROM,  precision: 0 },
  elasto91:   { unit: UNITS.MICROM,  precision: 0 },
  elasto92:   { unit: UNITS.MICROM,  precision: 0 },
  elasto93:   { unit: UNITS.MICROM,  precision: 0 },
  inclino80:  { unit: UNITS.DEGREES, precision: 3 },
  inclino80x: { unit: UNITS.DEGREES, precision: 3 },
  inclino80y: { unit: UNITS.DEGREES, precision: 3 },
  inclino81:  { unit: UNITS.DEGREES, precision: 3 },
  inclino81x: { unit: UNITS.DEGREES, precision: 3 },
  inclino81y: { unit: UNITS.DEGREES, precision: 3 },
  inclino82:  { unit: UNITS.DEGREES, precision: 3 },
  inclino82x: { unit: UNITS.DEGREES, precision: 3 },
  inclino82y: { unit: UNITS.DEGREES, precision: 3 },
  inclino83:  { unit: UNITS.DEGREES, precision: 3 },
  inclino83x: { unit: UNITS.DEGREES, precision: 3 },
  inclino83y: { unit: UNITS.DEGREES, precision: 3 },
} as const;

export const TensilTestDirectionData: Record<string, TensilTestTypeDisplayConfig> = {
  anchorageHeight: { unit: UNITS.M,       precision: 2 },
  barkThickness:   { unit: UNITS.CM,      precision: 0 },
  ktDistance:      { unit: UNITS.M,       precision: 2 },
  ropeAngle:       { unit: UNITS.DEGREES, precision: 3 },
} as const;
