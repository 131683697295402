import { useCallback, useState } from 'react';
import { postResetPassword } from '../helpers/apiHelper';

export const useResetPassword = () => {
  const [error, setError] = useState<any>(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const resetPassword = useCallback((password, passwordAgain, token) => {
    postResetPassword(password, passwordAgain, token)
      .then(res => setIsSuccess(true))
      .catch(err => setError(err));
  }, []);

  return { resetPassword, error, isSuccess };
};
