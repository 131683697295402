import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { action, payload } from 'ts-action';
import * as Sentry from '@sentry/browser';
import { State } from '../combinedReducers';
import {
  Tree,
  AnalysisResult,
  AnalysisEvaluation,
  Photo,
  TreeCalculations,
  OrderStatus,
  AnalysedTomogram,
} from '../../interfaces/tree';
import { LoadingState } from '../../interfaces/enums';
import {
  getTrees,
  getSingleTree,
  convertTreesHelper,
  getTaxons,
  postTreeAnalysisResult,
  postEvaluateTreeAnalysis,
  putTreeAnalysisResult,
  updateTreePhoto,
  deleteTreePhoto,
  putTensilTestsFile,
  postTree,
  makeOrder,
  putTree,
  putTrunkScan,
} from '../../helpers/apiHelper';
import { setErrorMsg } from '../general/actions';
import { activeLang } from '../../translations/languageSelector';
import { treeImagesPartition } from '../../helpers/treeHelpers';
import { RcFile } from 'antd/lib/upload';
import { TreeResponse } from '../../interfaces/responses';
import { parseError } from '../../helpers/errorHelpers';
import { SaveState } from './reducers';
import { DirectionOfLoad } from '../../interfaces/tenstilTest';

export enum ActionTypes {
  SET_TREES = '[trees] SET_TREES',
  SET_TOTAL = '[trees] SET_TOTAL',
  SET_ACTIVE_TREE_ID = '[trees] SET_ACTIVE_TREE_ID',
  SET_ANALYSIS_ID = '[trees] SET_ANALYSIS_ID',
  SET_LOADING_STATE = '[trees] SET_LOADING_STATE',
  SET_TAXONS = '[trees] SET_TAXONS',
  SET_TAXON = '[trees] SET_TAXON',
  FIND_TAXONS = '[trees] FIND_TAXONS',
  UPDATE_TREE = '[trees] UPDATE_TREE',
  SET_TREE_ANALYSIS = '[trees] SET_TREE_ANALYSIS',
  SET_CANVAS_SAVE_STATE = '[trees] SET_CANVAS_SAVE_STATE',
  FETCH_TREE = '[trees] FETCH_TREE',
  SET_TAXON_LOADING_STATE = '[trees] SET_TAXON_LOADING_STATE',
  UPLOAD_TENSIL_TESTS = '[trees] UPLOAD_TENSIL_TESTS',
  SET_TENSIL_TESTS_SAVE_STATE = '[trees] UPLOAD_TENSIL_TESTS',
  UPDATE_PHOTO = '[trees] UPDATE_PHOTO',
  DELETE_PHOTO = '[trees] DELETE_PHOTO',
  SET_PHOTO_SAVE_STATE = '[trees] SET_PHOTO_SAVE_STATE',
  SET_ORDER_STATE = '[trees] SET_ORDER_STATE',
  SET_TRUNK_SCAN = '[trees] SET_TRUNK_SCAN',
  SET_INITIAL_STATE = '[trees] SET_INITIAL_STATE',
}

export const setTrees = action(
  ActionTypes.SET_TREES,
  payload<{ trees: Tree[] }>(),
);

export const setTotal = action(ActionTypes.SET_TOTAL, payload<number>());

export const setActiveTreeId = action(
  ActionTypes.SET_ACTIVE_TREE_ID,
  payload<{ id: number }>(),
);

export const setLoadingState = action(
  ActionTypes.SET_LOADING_STATE,
  payload<{ state: LoadingState }>(),
);

export const setTaxons = action(
  ActionTypes.SET_TAXONS,
  payload<{ taxons: string[] }>(),
);

export const setSearchedTaxon = action(
  ActionTypes.SET_TAXON,
  payload<{ taxon: string }>(),
);

export const setTree = action(
  ActionTypes.UPDATE_TREE,
  payload<{ tree: Tree }>(),
);

export const setCanvasSaveState = action(
  ActionTypes.SET_CANVAS_SAVE_STATE,
  payload<{ saveState: SaveState }>(),
);

export const setTensilTestsSaveState = action(
  ActionTypes.SET_TENSIL_TESTS_SAVE_STATE,
  payload<{ saveTensilTestState: SaveState }>(),
);

export const setTaxonLoadingState = action(
  ActionTypes.SET_TAXON_LOADING_STATE,
  payload<{ state: LoadingState }>(),
);

export const setTreeAnalysisResults = action(
  ActionTypes.SET_TREE_ANALYSIS,
  payload<{ results: AnalysisResult[] }>(),
);

export const updatePhoto = action(
  ActionTypes.UPDATE_PHOTO,
  payload<{ photo: Photo }>(),
);

export const deletePhoto = action(
  ActionTypes.DELETE_PHOTO,
  payload<{ photoId: number }>(),
);

export const setSavePhotoState = action(
  ActionTypes.SET_PHOTO_SAVE_STATE,
  payload<{ savePhotoState: SaveState }>(),
);

export const setOrderState = action(
  ActionTypes.SET_ORDER_STATE,
  payload<OrderStatus>(),
);

export const setTrunkScan = action(
  ActionTypes.SET_TRUNK_SCAN,
  payload<string | Blob | RcFile>(),
);

export const setInitialState = action(ActionTypes.SET_INITIAL_STATE);

/**
 * API call to retrieve all trees
 */
export const getTreesData: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> = (idProject: number | undefined, page: number = 1) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(setLoadingState({ state: LoadingState.Loading }));
      const result = await getTrees(idProject, page);
      dispatch(setLoadingState({ state: LoadingState.Success }));

      const convertedTrees = await convertTreesHelper(result.data.result);

      dispatch(
        setTrees({
          trees: convertedTrees,
        }),
      );
      if (result?.data?.metadata?.total) {
        dispatch(setTotal(result.data.metadata.total));
      }
    } catch (err) {
      Sentry.captureException(err);
      dispatch(setLoadingState({ state: LoadingState.Failure }));
      if (parseError(err).code === 403) {
        dispatch(
          setErrorMsg({
            errorMsg: parseError(err).message,
          }),
        );
      }
    }
  };
};

/**
 * Fetchin all taxons data
 */

export const fetchTaxons: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> = () => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(setTaxonLoadingState({ state: LoadingState.Loading }));
      const result = await getTaxons();
      dispatch(setTaxons({ taxons: result }));
      dispatch(setTaxonLoadingState({ state: LoadingState.Success }));
    } catch (err) {
      dispatch(setTaxonLoadingState({ state: LoadingState.Failure }));
      if (parseError(err).code === 403) {
        dispatch(
          setErrorMsg({
            errorMsg: parseError(err).message,
          }),
        );
      }
    }
  };
};

/**
 * Fetch one single tree and its analysis
 */
export const fetchTree: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> = (id: number) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(setLoadingState({ state: LoadingState.Loading }));

      // FETCH TREE
      const result = await getSingleTree(id);

      const convertedTree = await convertTreesHelper([result.data]);

      dispatch(
        setTrees({
          trees: convertedTree,
        }),
      );
    } catch (err) {
      dispatch(setLoadingState({ state: LoadingState.Failure }));
      if (parseError(err).code === 403) {
        dispatch(
          setErrorMsg({
            errorMsg: parseError(err).message,
          }),
        );
      }
    }
  };
};

/**
 * Updates photo of a tree with analysis attributes
 * Calculations of diameter, heights and also canvas objects to be rendered
 */
export const updateTree: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> = (result: Tree, serverSave: boolean, photo?: Photo) => {
  return async (dispatch: Dispatch<Action>, getState): Promise<void> => {
    const user = getState().general.loggedUser;
    let newAnalysis;

    if (serverSave && user) {
      try {
        const realPayload = {
          tree_scanner_data: result.id,
          operator: user.id,
          ...result.tree_scanner_analysis_data,
        };
        const ogTree = await getSingleTree(result.id);
        if (ogTree.data.tree_scanner_analysis_data) {
          await putTreeAnalysisResult(realPayload);
          if (photo) await updateTreePhoto(photo);
        } else {
          newAnalysis = await postTreeAnalysisResult(realPayload);
        }
        dispatch(
          setCanvasSaveState({
            saveState: {
              state: LoadingState.Success,
              errorCode: 200,
            },
          }),
        );
      } catch (error) {
        dispatch(
          setCanvasSaveState({
            saveState: {
              state: LoadingState.Failure,
              errorCode: parseError(error).code,
            },
          }),
        );
        if (parseError(error).code === 403) {
          dispatch(
            setErrorMsg({
              errorMsg: parseError(error).message,
            }),
          );
        }
      }
    }

    dispatch(
      setTree({
        tree: {
          ...result,
          tree_scanner_analysis_data:
            newAnalysis && newAnalysis.data.id
              ? newAnalysis.data
              : result.tree_scanner_analysis_data,
        },
      }),
    );

    dispatch(
      setTreeAnalysisResults({
        results: [
          {
            analysedTree: {
              ...result,
              tree_scanner_analysis_data:
                newAnalysis && newAnalysis.data.id
                  ? newAnalysis.data
                  : result.tree_scanner_analysis_data,
            },
            treeId: result.id,
          },
        ],
      }),
    );
  };
};

/**
 * send tensil tests excel file
 */
export const createTensilTests: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  (tensilTests: File, treeId: number, tensilTestsId: number) =>
  async (dispatch: Dispatch<Action>, getState): Promise<void> => {
    const treeToUpdate = getState().trees.trees.find(
      tree => tree.id === treeId,
    );
    try {
      dispatch(
        setTensilTestsSaveState({
          saveTensilTestState: { state: LoadingState.Loading, errorCode: 0 },
        }),
      );
      const result = await putTensilTestsFile(
        { tensilTests },
        treeId,
        tensilTestsId,
      );

      dispatch(
        setTensilTestsSaveState({
          saveTensilTestState: { state: LoadingState.Success, errorCode: 0 },
        }),
      );

      if (result.data && treeToUpdate) {
        const newDirectionsOfLoad = result.data.directionOfLoad.map(
          (dir: DirectionOfLoad, index: number) =>
            index === tensilTestsId - 1
              ? {
                  ...dir,
                  tensilTests: result.data.directionOfLoad[index].tensilTests,
                }
              : dir,
        );

        dispatch(
          setTree({
            tree: { ...treeToUpdate, directionOfLoad: newDirectionsOfLoad },
          }),
        );
      }
    } catch (error) {
      dispatch(
        setTensilTestsSaveState({
          saveTensilTestState: {
            state: LoadingState.Failure,
            errorCode: parseError(error).code,
          },
        }),
      );
    }
  };

export const updateTrunkScan: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  (
    trunkScan: File,
    treeId: number,
    successCb?: (data: TreeResponse) => void,
    errorCb?: (error: string) => void,
  ) =>
  async (): Promise<void> => {
    try {
      const { data } = await putTrunkScan(trunkScan, treeId);

      if (data && successCb) {
        successCb(data);
      }
    } catch (error) {
      if (errorCb && parseError(error).message) {
        errorCb(parseError(error).message);
      }
    }
  };

/**
 * Post admin analysis verdict for analysed tree by operator
 */
export const evaluateAnalysis: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> = (evaluation: AnalysisEvaluation) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(
        setCanvasSaveState({
          saveState: { state: LoadingState.Loading, errorCode: 0 },
        }),
      );
      await postEvaluateTreeAnalysis(evaluation);

      dispatch(
        setCanvasSaveState({
          saveState: { state: LoadingState.Success, errorCode: 200 },
        }),
      );
    } catch (error) {
      dispatch(
        setCanvasSaveState({
          saveState: {
            state: LoadingState.Failure,
            errorCode: parseError(error).code,
          },
        }),
      );
      if (parseError(error).code === 403) {
        dispatch(
          setErrorMsg({
            errorMsg: parseError(error).message,
          }),
        );
      }
    }
  };
};

export const callUpdatePhoto: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> = (photo: Photo) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(
        setSavePhotoState({
          savePhotoState: { state: LoadingState.Loading, errorCode: 0 },
        }),
      );
      const result = await updateTreePhoto(photo);

      if (result && result.data) {
        dispatch(
          updatePhoto({
            photo: {
              ...result.data,
              url: result.data.image.url,
            },
          }),
        );
        dispatch(
          setSavePhotoState({
            savePhotoState: { state: LoadingState.Success, errorCode: 0 },
          }),
        );
      } else {
        dispatch(
          setSavePhotoState({
            savePhotoState: { state: LoadingState.Failure, errorCode: 400 },
          }),
        );
      }
    } catch (err) {
      Sentry.captureException(err);
      dispatch(
        setSavePhotoState({
          savePhotoState: {
            state: LoadingState.Failure,
            errorCode: parseError(err).code,
          },
        }),
      );
      if (parseError(err).code === 403) {
        dispatch(
          setErrorMsg({
            errorMsg: parseError(err).message,
          }),
        );
      }
    }
  };
};

export const callDeletePhoto: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> = (photoId: number) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(
        setSavePhotoState({
          savePhotoState: { state: LoadingState.Loading, errorCode: 0 },
        }),
      );
      const result = await deleteTreePhoto(photoId);

      if (result && result.data.id) {
        dispatch(deletePhoto({ photoId }));
        dispatch(
          setSavePhotoState({
            savePhotoState: {
              state: LoadingState.Success,
              errorCode: 0,
              msg: activeLang.treeDetailPage.photoEditing.form.deleteSucc,
            },
          }),
        );
      } else {
        dispatch(
          setSavePhotoState({
            savePhotoState: { state: LoadingState.Failure, errorCode: 400 },
          }),
        );
      }
    } catch (err) {
      Sentry.captureException(err);
      dispatch(
        setSavePhotoState({
          savePhotoState: {
            state: LoadingState.Loading,
            errorCode: parseError(err).code,
          },
        }),
      );
      if (parseError(err).code === 403) {
        dispatch(
          setErrorMsg({
            errorMsg: parseError(err).message,
          }),
        );
      }
    }
  };
};

export const createTree: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> =
  (tree: Tree) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      const analysisData = tree.tree_scanner_analysis_data;
      const photos = tree.tree_scanner_images;

      const { crownPhotos, trunkPhotos } = treeImagesPartition(photos);

      if (analysisData && trunkPhotos.length > 0 && crownPhotos.length > 0) {
        const newTree: TreeCalculations = {
          ...analysisData,
          groupName: tree.project.name,
          sid: tree.treeSid!,
          number: tree.treeNumber,
          areaName: analysisData.site,
          dbh: analysisData?.dbh ? analysisData.dbh : tree.dbh!,
          taxon: tree.taxon,
          crownPhotos,
          trunkPhotos,
          latitude: tree.latitude!,
          longitude: tree.longitude!,
          announcement: tree.announcement,
          protectionFactor: tree.protectionFactor,
          profile: tree.profile,
          mistletoe: tree.mistletoe,
          condition: tree.condition,
          trunkScanHeight: analysisData?.trunkScanHeight || null,
          tomograms:
            tree?.tree_scanner_tomograms?.map(tomogram => ({
              ...tomogram,
              image: {
                url:
                  typeof tomogram?.image === 'string'
                    ? tomogram.image
                    : tomogram.image?.url || '',
              },
            })) || [],
        };

        if (+analysisData.analysisLevel >= 3) {
          newTree.trunkScanUrl = tree.trunkScanUrl;
        }

        const tomograms: AnalysedTomogram[] = tree.tree_scanner_tomograms
          ? tree.tree_scanner_tomograms.map(item => {
              const newTomogram: AnalysedTomogram = {
                height: item.height,
                note: item.note,
                id: item.id,
                image: { url: '' },
                scaleImage: item.scaleImage,
                scaleReal: item.scaleReal,
                tomogramType: item.tomogramType,
                tomogramAnalysisData: undefined,
              };
              if (typeof item.image === 'string') {
                newTomogram.image = { url: item.image };
              } else if (item.image?.url) {
                newTomogram.image = { url: item.image.url };
              } else {
                newTomogram.image = { url: '' };
              }
              return newTomogram;
            })
          : [];

        if (+analysisData.analysisLevel === 4 && tomograms.length > 0) {
          newTree.tomograms = tomograms.map(tom => ({
            ...tom,
            image: { ...tom.image, url: tom.image.url },
          }));
        }

        if (+analysisData.analysisLevel === 2) {
          newTree.trunkScanUrl = '';
          newTree.tomograms = [];
        }

        if (!analysisData.treeId) {
          const { data }: { data: Tree } = await postTree(newTree);
          if (data) {
            await putTreeAnalysisResult({ ...analysisData, treeId: data.id });
            const order = await makeOrder(
              [data.id],
              analysisData.analysisLevel,
            );
            if (order.data) {
              dispatch(setOrderState(order.data.order.status));
            }
          }
        } else {
          const { data }: { data: Tree } = await putTree(
            analysisData.treeId,
            newTree,
          );
          const order = await makeOrder([data.id], analysisData.analysisLevel);
          if (order.data) {
            dispatch(setOrderState(order.data.order.status));
          }
        }
      }
    } catch (err) {
      dispatch(
        setErrorMsg({
          errorMsg: parseError(err).message,
        }),
      );
    }
  };
