export enum LoadingState {
  Initial = 'INITIAL',
  Loading = 'LOADING',
  Success = 'SUCCESS',
  Failure = 'FAILURE',
}

export enum TreeState {
  Active,
  NonActive,
  Approved,
}

export enum DialogType {
  WHOLE = 'whole',
  TRUNK = 'trunk',
  NONE = 'none',
  REJECT = 'treeReject',
}

export enum CanvasEditOption {
  Base = 'Base',
  Trunk = 'Trunk',
  CenterPoint = 'CenterPoint',
  TopPoint = 'TopPoint',
  Range = 'Range',
  Crown = 'Crown',
}

export enum SiteTab {
  Overview = 'overview',
  Photos = 'photos',
  TreeTopAnalysis = 'treeTopAnalysis',
  TrunkAnalysis = 'trunkAnalysis',
  TensilTest = 'tensilTest',
  Tomograms = 'tomograms',
  Calculations = 'calculations',
}

export enum TreeProfile {
  City = 'city',
  Outskirts = 'outskirts',
  Village = 'village',
  Countryside = 'countryside',
  None = '',
}

export enum TreeProtectionFactor {
  ALLEY = 'alley',
  FOREST_STAND = 'forestStand',
  SOLITAIRE = 'soliter',
  NONE = '',
}

export enum UserRole {
  TreeAdmin = 'Admin',
  Operator = 'Operator',
}

export enum NotificationType {
  OC = 'OC',
  SHOP = 'SHOP',
}

export enum OrderMessage {
  FAILED = 'Failed',
  FINISHED = 'Finished',
  ORDERED = 'Ordered',
}
