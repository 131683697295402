import type { FinishedOrderDetail } from '../interfaces/finishedOrderDetail';
import { instance } from '../../../helpers/apiHelper';

export const setReadyForClient = async (
  body: { orderId: string } | { selectedIds: number[] },
) => {
  const { data } = await instance.post(
    `tree-scanner-data/set-ready-for-client`,
    body,
  );

  return data;
};

export const getDetail = async (id: string, page: number, perPage: number) => {
  const { data } = await instance.get<FinishedOrderDetail>(
    `tree-scanner-data/processed/${id}?page=${page - 1}&perPage=${perPage}`,
  );

  return data;
};
