import { createContext, FC, useState } from 'react';

interface UnreadNotificationsContextProps {
  unreadNotificationsCount: number;
  update: (_count: number) => void;
}

export const UnreadNotificationsCountContext =
  createContext<UnreadNotificationsContextProps>({
    update: (_count: number) => null,
    unreadNotificationsCount: 0,
  });

export const UnreadNotificationsCountProvider: FC = ({ children }) => {
  const [count, setCount] = useState(0);

  return (
    <UnreadNotificationsCountContext.Provider
      value={{ unreadNotificationsCount: count, update: setCount }}
    >
      {children}
    </UnreadNotificationsCountContext.Provider>
  );
};
