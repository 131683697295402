import { StyleSheet } from 'aphrodite';

import { MENU_HEIGHT } from '../../styles/variables';
import { tableStylingMixin, bodyPadding } from '../../styles/mixins';
import { colors } from '../../styles/colors';

export const styles = StyleSheet.create({
  body: {
    ...bodyPadding,
    backgroundColor: colors.containerBackground,
    minHeight: `calc(100vh - ${MENU_HEIGHT}px)`,
  },
  icon: {
    margin: 10,
    width: 20,
  },
  row: {
    marginTop: 20,
  },
  table: {
    justifyContent: 'center',
    margin: 30,
    ...tableStylingMixin,
    borderRadius: 10,
  },
  doneIcon: {
    color: colors.greenPrimary,
    fontSize: 24,
  },
  rejectIcon: {
    color: colors.redPrimary,
    fontSize: 24,
  },
});
