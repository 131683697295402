import Konva from 'konva';
import { KonvaEventObject } from 'konva/types/Node';
import { FC, useRef } from 'react';
import { Circle, Group, Line } from 'react-konva';
import { Position } from '../../../../../interfaces/canvas';
import {
  crownLineConfig,
  crownPoint,
  scalePoint,
} from '../../../../../styles/canvasStyles';

type RangeProps = {
  range: Position[];
  zoom: number;
  setIsDraggingPoint: (isDraggingPoint: boolean) => void;
  dragRangePoint: (point: Position) => void;
  draggable?: boolean;
};

export const Range: FC<RangeProps> = ({
  range,
  zoom,
  setIsDraggingPoint,
  dragRangePoint,
  draggable,
}) => {
  const rangePointRef = useRef<Konva.Circle[]>([]);

  const onDragPoint = (evt: KonvaEventObject<DragEvent>) => {
    const draggedPointRef = rangePointRef.current.find(
      ref => ref.id() === evt.target.attrs.id,
    );
    if (draggedPointRef) {
      const parent = draggedPointRef.parent?.parent;

      if (draggedPointRef.x() <= 0) {
        draggedPointRef.setPosition({
          x: 0,
          y: draggedPointRef.y(),
          id: +draggedPointRef.id(),
        });
      } else if (parent && draggedPointRef.x() >= parent.width()) {
        draggedPointRef.setPosition({
          x: parent.width(),
          y: draggedPointRef.y(),
          id: +draggedPointRef.id(),
        });
      }

      if (draggedPointRef.y() <= 0) {
        draggedPointRef.setPosition({
          x: draggedPointRef.x(),
          y: 0,
          id: +draggedPointRef.id(),
        });
      } else if (parent && draggedPointRef.y() >= parent.height()) {
        draggedPointRef.setPosition({
          x: draggedPointRef.x(),
          y: parent.height(),
          id: +draggedPointRef.id(),
        });
      }

      dragRangePoint({
        x: draggedPointRef.x(),
        y: draggedPointRef.y(),
        id: +draggedPointRef.id(),
      });
    }
  };

  return (
    <Group>
      {range.length === 2 && (
        <Line
          points={[range[0].x, range[0].y, range[1].x, range[1].y]}
          {...crownLineConfig}
          strokeWidth={crownLineConfig.strokeWidth / zoom}
        />
      )}
      {range.map(item => (
        <Circle
          ref={ref =>
            ref &&
            !rangePointRef.current.includes(ref) &&
            rangePointRef.current.push(ref!)
          }
          draggable={draggable}
          onDragStart={() => setIsDraggingPoint(true)}
          id={`${item.id}`}
          key={item.id}
          onDragMove={onDragPoint}
          onDragEnd={() => setIsDraggingPoint(false)}
          x={item.x}
          y={item.y}
          {...scalePoint}
          fill={crownPoint.fillColor}
          radius={crownPoint.radius / zoom}
          strokeWidth={crownPoint.strokeWidth / zoom}
        />
      ))}
    </Group>
  );
};
