import React, { FC } from 'react';
import { Grid } from '@material-ui/core';

type HeaderIconProps = {
  className?: string;
  iconClassName?: string;
};

const HeaderIcon: FC<HeaderIconProps> = ({ className, iconClassName }) => {
  return (
    <Grid className={className}>
      <svg className={iconClassName}>
        <path
          d="M72.4809 0.00125137C87.0448 -0.138343 97.9499 11.4282 108.747 21.1667C120.399 31.6776 135.937 41.6349 136 57.295C136.063 72.9758 119.645 82.1281 109.038 93.714C97.5246 106.29 89.2287 123.306 72.4809 126.677C53.1316 130.571 31.5202 125.91 17.41 112.157C3.19746 98.3047 -2.88497 76.6602 1.29603 57.295C4.96372 40.3073 23.3388 32.7753 36.9064 21.8553C48.1718 12.7882 57.9988 0.140061 72.4809 0.00125137Z"
          fill="#FFEBD9"
        />
        <path
          d="M99.1135 133.631C97.943 133.631 97.0215 132.685 97.0215 131.539V112.043C97.0215 110.873 97.9679 109.952 99.1135 109.952C100.284 109.952 101.205 110.898 101.205 112.043V131.539C101.23 132.685 100.284 133.631 99.1135 133.631Z"
          fill="#D87B0F"
        />
        <path
          d="M97.6437 77.7822C108.029 77.7822 116.472 86.1981 116.472 96.6059C116.472 106.989 108.054 115.43 97.6437 115.43C87.2336 115.43 78.8159 107.014 78.8159 96.6059C78.8159 86.1981 87.2336 77.7822 97.6437 77.7822Z"
          fill="#6C8E2D"
        />
        <path
          d="M116.447 96.606C116.447 106.989 108.03 115.43 97.6197 115.43C93.1867 115.43 89.1023 113.886 85.8896 111.321C87.5832 111.819 89.3514 112.093 91.2192 112.093C101.604 112.093 110.047 103.652 110.047 93.2696C110.047 87.3187 107.283 81.9903 102.949 78.5542C110.769 80.8449 116.447 88.0657 116.447 96.606Z"
          fill="#607D2B"
        />
        <path
          d="M64.3963 73.9476C56.2774 73.9476 49.7026 67.3742 49.7026 59.2571V38.6905C49.7026 30.5734 56.2774 24 64.3963 24C72.5152 24 79.0899 30.5734 79.0899 38.6905V59.2571C79.0899 67.3742 72.5152 73.9476 64.3963 73.9476Z"
          fill="#6C8E2D"
        />
        <path
          d="M79.0895 38.6905V59.2571C79.0895 67.3742 72.5147 73.9476 64.3959 73.9476C63.0012 73.9476 61.6564 73.7484 60.3862 73.3998C66.5625 71.6569 71.0703 65.9799 71.0703 59.2571V38.6905C71.0703 34.6319 69.4266 30.9717 66.7618 28.3075C65.0185 26.5646 62.8269 25.245 60.3862 24.5727C61.6813 24.1992 63.0261 24 64.4208 24C68.4802 24 72.1412 25.6433 74.8059 28.3075C77.4458 30.9717 79.0895 34.6319 79.0895 38.6905Z"
          fill="#607D2B"
        />
        <path
          d="M70.5482 52.3102C69.7264 51.4885 68.3815 51.4885 67.5597 52.3102L66.4888 53.3809V48.7994V40.5827C66.4888 39.4125 65.5424 38.4663 64.3968 38.4663C63.2512 38.4663 62.3048 39.4125 62.3048 40.5827V43.72L60.1879 41.6036C59.3661 40.7819 58.0461 40.7819 57.1994 41.6036C56.3775 42.4253 56.3775 43.7449 57.1994 44.5666L62.3048 49.6709V58.4603V132.336C62.3048 133.506 63.2512 134.427 64.3968 134.427C65.5673 134.427 66.4888 133.481 66.4888 132.336V59.3317L70.5233 55.2981C71.3701 54.4764 71.3701 53.1319 70.5482 52.3102Z"
          fill="#D87B0F"
        />
        <path
          d="M84.9927 56.3936C97.3204 56.3936 107.307 66.3781 107.307 78.7031C107.307 91.0282 97.3204 101.013 84.9927 101.013C72.6649 101.013 62.6782 91.0282 62.6782 78.7031C62.7031 66.3781 72.6898 56.3936 84.9927 56.3936Z"
          fill="#6C8E2D"
        />
        <path
          d="M107.307 78.6782C107.307 91.0033 97.3203 100.988 84.9926 100.988C81.4561 100.988 78.1189 100.166 75.1553 98.6971C85.5405 96.7799 93.3854 87.6917 93.3854 76.761C93.3854 67.9716 88.3048 60.3774 80.9331 56.7421C82.2531 56.4931 83.6228 56.3687 84.9926 56.3687C97.3203 56.3936 107.307 66.3781 107.307 78.6782Z"
          fill="#607D2B"
        />
        <path
          d="M91.5418 68.4947C90.7199 67.6731 89.3751 67.6731 88.5781 68.4947L87.1088 69.9638V67.5237C87.1088 66.3783 86.1624 65.4321 85.0168 65.4321C83.8463 65.4321 82.9248 66.3783 82.9248 67.5237V89.7585C82.9248 90.9288 83.8712 91.875 85.0168 91.875C86.1873 91.875 87.1088 90.9288 87.1088 89.7585V75.8897L91.5418 71.4577C92.3636 70.636 92.3636 69.3164 91.5418 68.4947Z"
          fill="#D87B0F"
        />
        <path
          d="M84.9924 134.452C83.8219 134.452 82.9004 133.506 82.9004 132.361V112.392C82.9004 111.222 83.8468 110.3 84.9924 110.3C86.1629 110.3 87.0843 111.246 87.0843 112.392V132.361C87.1093 133.506 86.1629 134.452 84.9924 134.452Z"
          fill="#D87B0F"
        />
        <path
          d="M126.683 83.4591C126.683 109.304 120.059 108.856 111.865 108.856C103.696 108.856 97.0469 109.304 97.0469 83.4591C97.0469 57.6139 105.415 28.457 111.865 28.457C118.315 28.457 126.683 57.6139 126.683 83.4591Z"
          fill="#B1CC2C"
        />
        <path
          d="M126.683 83.4591C126.683 109.304 120.059 108.856 111.865 108.856C103.696 108.856 97.0469 109.304 97.0469 83.4591C97.0469 57.6139 105.415 28.457 111.865 28.457C118.315 28.457 126.683 57.6139 126.683 83.4591Z"
          fill="#8FBA23"
        />
        <path
          d="M126.683 83.4591C126.683 109.304 120.058 108.856 111.865 108.856C110.545 108.856 109.25 108.856 108.004 108.782C114.305 108.308 118.962 105.096 118.962 83.484C118.962 62.5937 113.508 39.562 108.004 31.4698C109.299 29.5526 110.619 28.4819 111.865 28.4819C118.315 28.4571 126.683 57.6139 126.683 83.4591Z"
          fill="#7DA32B"
        />
        <path
          d="M120.78 86.1981C119.958 85.3765 118.613 85.3765 117.816 86.1981L113.981 90.0326V81.9902V72.5285C113.981 71.3583 113.035 70.437 111.864 70.437C110.719 70.437 109.772 71.3832 109.772 72.5285V76.9108L106.784 73.9229C105.962 73.1012 104.642 73.1012 103.82 73.9229C102.998 74.7446 102.998 76.0642 103.82 76.8859L109.772 82.8368V95.0871V131.514C109.772 132.685 110.719 133.606 111.864 133.606C113.035 133.606 113.981 132.66 113.981 131.514V95.9835L120.805 89.1611C121.602 88.3395 121.602 86.9949 120.78 86.1981Z"
          fill="#F39204"
        />
        <path
          d="M31 102.332C31 122.252 39.9656 121.878 51.0481 121.878C62.1306 121.878 71.0962 122.227 71.0962 102.332C71.0962 82.4131 59.7896 59.9541 51.0481 59.9541C42.3066 59.9541 31 82.4131 31 102.332Z"
          fill="#B1CC2C"
        />
        <path
          d="M31 102.332C31 122.252 39.9656 121.878 51.0481 121.878C62.1306 121.878 71.0962 122.227 71.0962 102.332C71.0962 82.4131 59.7896 59.9541 51.0481 59.9541C42.3066 59.9541 31 82.4131 31 102.332Z"
          fill="#8FBA23"
        />
        <path
          d="M71.0705 102.332C71.0705 122.252 62.1048 121.878 51.0223 121.878C49.5779 121.878 48.1832 121.878 46.8384 121.853C55.8787 121.629 62.7025 119.662 62.7025 102.332C62.7025 85.5006 54.6335 66.8512 46.8384 61.4729C48.2579 60.477 49.6775 59.9541 51.0223 59.9541C59.7638 59.9541 71.0705 82.4131 71.0705 102.332Z"
          fill="#7DA32B"
        />
        <path
          d="M90.1969 103.08C90.1969 117.795 83.5723 117.521 75.3787 117.521C67.1851 117.521 60.5605 117.795 60.5605 103.08C60.5605 88.3645 68.9285 71.7568 75.3787 71.7568C81.829 71.7568 90.1969 88.3645 90.1969 103.08Z"
          fill="#B1CC2C"
        />
        <path
          d="M90.1969 103.08C90.1969 117.795 83.5723 117.521 75.3787 117.521C67.1851 117.521 60.5605 117.795 60.5605 103.08C60.5605 88.3645 68.9285 71.7568 75.3787 71.7568C81.829 71.7568 90.1969 88.3645 90.1969 103.08Z"
          fill="#8FBA23"
        />
        <path
          d="M90.198 103.079C90.198 117.795 83.5734 117.521 75.3798 117.521C73.8606 117.521 72.3912 117.521 70.9966 117.446C77.0484 117.098 81.4316 115.056 81.4316 103.079C81.4316 91.5761 76.3261 78.9024 70.9966 73.9475C72.4659 72.578 73.9851 71.7813 75.3798 71.7813C81.8549 71.7564 90.198 88.3641 90.198 103.079Z"
          fill="#7DA32B"
        />
        <path
          d="M84.3198 99.1952C83.498 98.3735 82.1531 98.3735 81.3313 99.1952L77.496 103.03V92.7463C77.496 91.576 76.5496 90.6299 75.404 90.6299C74.2335 90.6299 73.312 91.576 73.312 92.7463V108.109V131.539C73.312 132.709 74.2584 133.631 75.404 133.631C76.5745 133.631 77.496 132.684 77.496 131.539V109.005L84.3198 102.183C85.1167 101.336 85.1167 100.017 84.3198 99.1952Z"
          fill="#F39204"
        />
        <path
          d="M56.3519 94.5889C57.1737 93.7673 57.1737 92.4476 56.3519 91.626C55.53 90.8043 54.2101 90.8043 53.3883 91.626L53.1392 91.8749V86.0984C53.1392 84.9281 52.1928 83.9819 51.0472 83.9819C49.8767 83.9819 48.9303 84.9281 48.9303 86.0984V104.076L46.2905 101.436C45.4686 100.615 44.1487 100.615 43.3268 101.436C42.505 102.258 42.505 103.602 43.3268 104.399L48.9553 110.026V131.539C48.9553 132.709 49.9016 133.631 51.0721 133.631C52.2426 133.631 53.1641 132.685 53.1641 131.539V109.155V97.8258L56.3519 94.5889Z"
          fill="#F39204"
        />
        <path
          d="M126.347 135H34.447C33.2764 135 32.3301 134.079 32.3301 132.909C32.3301 131.764 33.2764 130.817 34.447 130.817H126.372C127.542 130.817 128.464 131.764 128.464 132.909C128.464 134.079 127.518 135 126.347 135Z"
          fill="#7DA32B"
        />
      </svg>
    </Grid>
  );
};

export default HeaderIcon;
