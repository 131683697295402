import {
  Circle,
  GoogleMap,
  InfoBox,
  Polyline,
  useJsApiLoader,
} from '@react-google-maps/api';
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import {
  GOOGLE_MAP_API_KEY,
  MARKER_HEIGHT,
  MARKER_WIDTH,
} from '../../constants/constants';
import { alphabet } from '../../constants/map';
import { getLineCenter } from '../../helpers/mapHelpers';
import { getPhotosByType } from '../../helpers/photoHelpers';
import { DefaultMapValues, defaultValues } from '../../interfaces/map';
import { Location, PhotoType, Tree } from '../../interfaces/tree';
import { colors } from '../../styles/colors';
import {
  crownPhotoLineConfig,
  crownPointConfig,
  loadLineConfig,
  loadPointConfig,
  structureLineConfig,
  treePointConfig,
  trunkPhotoLineConfig,
  trunkPointConfig,
} from '../../styles/mapStyles';

interface DefaultMapSettings {
  zoom: number;
  isMarkerShown: boolean;
  activeTree: Tree | undefined;
  activatedStreetView: boolean;
  loadingElement: any;
}

export const getDefaultValues = (
  zoomDefault: number,
  activeTree: Tree | undefined,
) =>
  activeTree && activeTree.latitude && activeTree.longitude
    ? {
        zoom: zoomDefault,
        latitude: activeTree.latitude,
        longitude: activeTree.longitude,
      }
    : defaultValues;

export type DefaultMapProps = DefaultMapSettings;

let elementsArray: JSX.Element[] = [];

export const MapComponentRaw: React.FC<DefaultMapProps> = (
  props: DefaultMapProps,
) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAP_API_KEY!,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const googleMapsProps = getDefaultValues(props.zoom, props.activeTree);

  elementsArray = [];

  return isLoaded ? (
    <GoogleMap
      zoom={props.zoom}
      mapContainerStyle={{
        width: '100%',
        height: '600px',
      }}
      onLoad={onLoad}
      onUnmount={onUnmount}
      center={{
        lat: googleMapsProps.latitude,
        lng: googleMapsProps.longitude,
      }}
      options={{
        fullscreenControl: false,
        mapTypeId: google.maps.MapTypeId.SATELLITE,
        tilt: 0,
        rotateControl: false,
      }}
    >
      {renderMapContent(
        googleMapsProps,
        props.activatedStreetView,
        props.activeTree!,
      )}
    </GoogleMap>
  ) : (
    props.loadingElement
  );
};

const renderMapContent = (
  props: DefaultMapValues,
  activatedStreetView: boolean,
  activeTree: Tree,
) => {
  const treePoint: google.maps.LatLngLiteral = {
    lat: props.latitude,
    lng: props.longitude,
  };
  if (activatedStreetView) {
    return <React.Fragment />;
  } else {
    // if not StreetView, render also elements on map
    const trunkPhotos = getPhotosByType(PhotoType.Trunk, activeTree);
    const wholePhoto = getPhotosByType(PhotoType.Whole, activeTree);
    generateMapElements(
      trunkPhotos,
      treePoint,
      trunkPhotoLineConfig,
      trunkPointConfig,
      true,
      true,
    );
    generateMapElements(
      wholePhoto,
      treePoint,
      crownPhotoLineConfig,
      crownPointConfig,
      false,
      true,
    );
    generateMapElements(
      activeTree.directionOfLoad,
      treePoint,
      loadLineConfig,
      loadPointConfig,
      false,
      false,
    );

    return (
      <>
        {createCircle('treePoint', treePoint, treePointConfig)}
        {elementsArray.map(e => e)}
        {activeTree && renderStructureLine(activeTree)}
      </>
    );
  }
};

/**
 * Method used for creating line element from photo.location || location array
 */
const generateMapElements = (
  photos: any,
  treePoint: google.maps.LatLngLiteral,
  lineConfig: google.maps.PolylineOptions,
  pointConfig: google.maps.CircleOptions,
  isTrunk: boolean,
  isPhoto: boolean,
) => {
  if (photos && photos.length > 0) {
    photos.forEach((p: any, index: number) => {
      createElements(
        treePoint,
        !isPhoto
          ? p.location
          : { longitude: p.longitude, latitude: p.latitude },
        lineConfig,
        pointConfig,
        isTrunk,
        index,
      );
    });
  }
};

/**
 * Method that generates line, circle in its middle and marker with tag
 */
const createElements = (
  treePoint: google.maps.LatLngLiteral,
  location: Location,
  lineConfig: google.maps.PolylineOptions,
  pointConfig: google.maps.CircleOptions,
  isTrunk: boolean,
  index: number,
) => {
  const point: google.maps.LatLngLiteral = {
    lat: location.latitude,
    lng: location.longitude,
  };
  let line: JSX.Element;
  let circle: JSX.Element;
  let marker: JSX.Element;

  const center = getLineCenter(treePoint, point);
  line = <React.Fragment />;
  // line = (
  //   <Polyline
  //     key={`Line${`${index}${isTrunk}${lineConfig.strokeColor}`}`}
  //     path={pathPoints}
  //     options={lineConfig}
  //   />
  // );
  circle = createCircle(
    `${index}${isTrunk}${pointConfig.fillColor}`,
    center,
    pointConfig,
  );
  marker = createCircleText(
    `${index}${isTrunk}${pointConfig.fillColor}`,
    new google.maps.LatLng(center.lat, center.lng),
    isTrunk ? String(alphabet[index]) : String(index + 1),
  );

  elementsArray = [...elementsArray, circle, line, marker];
};

/**
 * Method creates single line from Locations stored in structure
 */
const renderStructureLine = (activeTree: Tree) => {
  const structure: google.maps.LatLngLiteral[] = activeTree.structure.map(
    s => ({
      lat: s.latitude,
      lng: s.longitude,
    }),
  );

  if (structure.length > 0) {
    return (
      <Polyline
        key={`Linestructure`}
        path={structure}
        options={structureLineConfig}
      />
    );
  }
  return null;
};

/**
 * New circle element
 */
const createCircle = (
  key: string,
  latLng: google.maps.LatLng | google.maps.LatLngLiteral,
  config: google.maps.CircleOptions,
): JSX.Element => (
  <Circle
    key={`Point${key}`}
    center={latLng}
    options={config}
    radius={config.radius!}
  />
);

/**
 * New circle element
 */
const createCircleText = (
  key: string,
  latLng: google.maps.LatLng,
  text: string,
): JSX.Element => {
  return (
    <InfoBox
      key={`InfoBox${key}`}
      position={new google.maps.LatLng(latLng.lat(), latLng.lng())}
      options={{
        closeBoxURL: ``,
        // this map size is is to set static position on of the marker on the map
        // it should be calculated as a half of the marker dimensions
        pixelOffset: new google.maps.Size(
          -(MARKER_WIDTH / 2 - 5),
          -(MARKER_HEIGHT / 2 - 50),
        ),
      }}
    >
      <span className={css(styles.circleText)}>{text}</span>
    </InfoBox>
  );
};

const styles = StyleSheet.create({
  circleText: {
    color: colors.white,
    fontSize: 30,
    zIndex: 500,
  },
});

export const MapComponent = React.memo(MapComponentRaw);
