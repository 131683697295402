import { useState } from 'react';

import { Header } from '../../components/shared/Header';
import AppAlert from '../../components/treeDetailPageNew/components/Form/AppAlert/AppAlert';

import { useRole } from '../../hooks/useRole';

import { useCurrentLanguage } from '../../translations/languageSelector';
import { FinishedOrderDetail } from './FinishedOrderDetail';

export const FinishedOrderDetailPage = () => {
  const { isAdmin } = useRole();

  const [errorMessageShown, setErrorMesageShown] = useState<boolean>(false);
  const [successMessageShown, setSuccessMesageShown] = useState<boolean>(false);

  const activeLang = useCurrentLanguage();

  return (
    <>
      <AppAlert
        message={activeLang.finished.orderSentSuccess}
        open={successMessageShown}
        severity="success"
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
      <AppAlert
        message={activeLang.finished.orderSentError}
        open={errorMessageShown}
        severity="error"
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
      <Header
        breadcrumbs={[
          {
            label: activeLang.summaryPage.headerTitle,
            link: '/',
          },
        ]}
      />

      {isAdmin ? (
        <FinishedOrderDetail
          showErrorMessage={() => setErrorMesageShown(true)}
          showSuccessMessage={() => setSuccessMesageShown(true)}
        />
      ) : (
        <div>
          {/* TODO: extract to separate component */}
          {activeLang.error['403']}
        </div>
      )}
    </>
  );
};
