import { Grid, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Position } from '../../../interfaces/canvas';
import { useCurrentLanguage } from '../../../translations/languageSelector';
import { HeaderWithButton } from './HeaderWithButton';
import { TomogramImageProps, TomogramsPreview } from './TomogramsPreview';
import { getActiveTreeById } from '../../../store/trees/selectors';
import { useSelector } from 'react-redux';
import { useTrunkSlice } from '../../../hooks/useTrunkSlice';
import { useTomogramImages } from '../../../hooks/useTomogramImages';
import { ActiveTomogram } from './ActiveTomogram';

interface TomogramCutProps {
  onTomogramSelect: (id: number) => void;
  tomograms: TomogramImageProps[];
  activeTomogram: TomogramImageProps | undefined;
}

const useStyles = makeStyles(theme => ({
  cutContainer: { marginTop: '30px' },
}));

export const TomogramCut = ({
  onTomogramSelect,
  tomograms,
  activeTomogram,
}: TomogramCutProps) => {
  const activeLang = useCurrentLanguage();
  const classes = useStyles();

  const [tomogramPoints, setTomogramPoints] = useState<Position[]>([]);

  const [slicePoints, setSlicePoints] = useState<Position[]>([]);

  useEffect(() => {
    if (tomogramPoints.length > slicePoints.length) {
      setSlicePoints(prevPoints => [
        ...prevPoints,
        tomogramPoints[tomogramPoints.length - 1],
      ]);
    }
  }, [slicePoints, tomogramPoints]);

  useEffect(() => {
    if (activeTomogram?.tomogramAnalysisData?.tomogramPoints) {
      setTomogramPoints(activeTomogram?.tomogramAnalysisData?.tomogramPoints);
    }

    if (activeTomogram?.tomogramAnalysisData?.slicePoints) {
      setSlicePoints(activeTomogram?.tomogramAnalysisData?.slicePoints);
    }
  }, [activeTomogram]);

  const activeTree = useSelector(getActiveTreeById);

  const {
    trunkSlice,
    trunkSliceData,
    isPutTrunkSliceLoading,
    putTrunkSliceError,
  } = useTrunkSlice(activeTree, activeTomogram);

  const { image, sliceImage } = useTomogramImages(
    activeTomogram,
    trunkSliceData,
  );

  const height = activeTomogram?.height;
  const modelUrl = activeTree?.trunkScanUrl;
  const generateCutsEnabled = !!(
    typeof height === 'number' &&
    modelUrl &&
    activeTomogram
  );

  const generateCuts = () => {
    if (generateCutsEnabled) {
      trunkSlice(height!, modelUrl!);
    }
  };

  const handleTomogramSelect = (id: number) => {
    setTomogramPoints([]);
    setSlicePoints([]);
    onTomogramSelect(id);
  };

  return (
    <Grid container className={classes.cutContainer} spacing={4}>
      <Grid item xs={12}>
        <HeaderWithButton
          loading={isPutTrunkSliceLoading}
          error={putTrunkSliceError}
          disabled={!generateCutsEnabled}
          title={activeLang.tomogramsPage.header.cuts}
          buttonText={activeLang.tomogramsPage.header.generateCuts}
          onClick={generateCuts}
        />
      </Grid>
      <Grid item>
        <TomogramsPreview
          tomograms={tomograms}
          onTomogramSelect={handleTomogramSelect}
        />
      </Grid>

      {activeTomogram && (
        <ActiveTomogram
          image={image}
          tomograms={tomograms}
          activeTree={activeTree}
          sliceImage={sliceImage}
          activeTomogram={activeTomogram}
          tomogramPoints={tomogramPoints}
          slicePoints={slicePoints}
          setTomogramPoints={setTomogramPoints}
          setSlicePoints={setSlicePoints}
        />
      )}
    </Grid>
  );
};
