import { useCallback } from 'react';
import { Photo } from '../interfaces/tree';
import { useSendData } from './useSendData/useSendData';

export const usePhotoUpdate = () => {
  const { data, error, loading, putData } = useSendData<Photo>();

  const updatePhoto = useCallback(
    (photo: Photo) => {
      putData(`/tree-scanner-images/${photo.id}`, photo);
    },
    [putData],
  );

  return {
    photoData: data,
    photoError: error,
    photoLoading: loading,
    updatePhoto,
  };
};
