import { Grid, makeStyles, Card } from '@material-ui/core';
import { TomogramAnalysis } from '../../../interfaces/tree';
import { colors } from '../../../styles/colors';
import { TomogramButton } from './TomogramButton';

interface UrlProps {
  url: string;
}
export interface TomogramImageProps {
  id: number;
  image: UrlProps | null | string;
  height: number;
  tomogramAnalysisData?: TomogramAnalysis;
}

interface FilteredTomogramProps {
  id: number;
  image: string;
}

interface TomogramImagesProps {
  tomograms: TomogramImageProps[];
  onTomogramSelect: (id: number) => void;
}

const useStyles = makeStyles(theme => ({
  tomogramImg: {
    width: '300px',
    height: '300px',
    objectFit: 'scale-down',
    '&:hover': {
      cursor: 'pointer',
    },
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  selectButton: {
    marginTop: '1em',
    width: '100%',
    backgroundColor: `${colors.greenPrimary}`,
    color: `${colors.white}`,
  },
}));

export const TomogramsPreview = ({
  tomograms,
  onTomogramSelect,
}: TomogramImagesProps) => {
  const classes = useStyles();

  tomograms.forEach(tomogram => {
    if (tomogram.image && typeof tomogram.image === 'object') {
      tomogram.image = tomogram.image.url;
    }
  });

  const filteredTomograms = tomograms.filter(tomogram => {
    if (!tomogram.image) {
      return false;
    }

    return true;
  }) as FilteredTomogramProps[];

  return (
    <Grid container direction="row" spacing={5}>
      {filteredTomograms.length > 0 &&
        filteredTomograms.map(tomogram => (
          <Grid item key={tomogram.image}>
            <Grid container direction="column" justifyContent="center">
              <Grid item xs={12}>
                <Card
                  key={tomogram.image}
                  onClick={() => onTomogramSelect(tomogram.id)}
                >
                  <img
                    className={classes.tomogramImg}
                    src={tomogram.image}
                    alt={tomogram.image}
                  />
                </Card>
              </Grid>
              <Grid item xs={12}>
                <TomogramButton
                  onClick={() => onTomogramSelect(tomogram.id)}
                  title={'Vybrat tomogram'}
                />
              </Grid>
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
};
