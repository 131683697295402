import { FC } from 'react';
import { Button, Tooltip } from '@material-ui/core';
import { AppButtonProps } from './types';

const AppButton: FC<AppButtonProps> = ({ tooltipTitle, ...rest }) => {
  if (tooltipTitle) {
    return (
      <Tooltip title={tooltipTitle}>
        <span style={{ cursor: 'help' }}>
          <Button {...rest} />
        </span>
      </Tooltip>
    );
  }

  return <Button {...rest} />;
};

export default AppButton;
