import Konva from 'konva';
import { KonvaEventObject } from 'konva/types/Node';
import { FC, useCallback, useRef } from 'react';
import { Circle, Group, Line } from 'react-konva';
import { Position } from '../../../../../interfaces/canvas';
import {
  crownLineConfig,
  crownPoint,
  crownPointGenerated,
} from '../../../../../styles/canvasStyles';

type CrownProps = {
  crownPoints: Position[];
  zoom: number;
  setIsDraggingPoint: (isDraggingPoint: boolean) => void;
  dragCrownPoint: (point: Position) => void;
  draggable?: boolean;
};

export const Crown: FC<CrownProps> = ({
  crownPoints,
  zoom,
  setIsDraggingPoint,
  dragCrownPoint,
  draggable,
}) => {
  const crownPointsRef = useRef<Konva.Circle[]>([]);

  const onDragPoint = useCallback(
    (evt: KonvaEventObject<DragEvent>) => {
      const draggedPointRef = crownPointsRef.current.find(
        ref => ref.id() === evt.target.attrs.id,
      );
      if (draggedPointRef) {
        const parent = draggedPointRef.parent?.parent;

        if (draggedPointRef.x() <= 0) {
          draggedPointRef.setPosition({
            x: 0,
            y: draggedPointRef.y(),
            id: +draggedPointRef.id(),
          });
        } else if (parent && draggedPointRef.x() >= parent.width()) {
          draggedPointRef.setPosition({
            x: parent.width(),
            y: draggedPointRef.y(),
            id: +draggedPointRef.id(),
          });
        }

        if (draggedPointRef.y() <= 0) {
          draggedPointRef.setPosition({
            x: draggedPointRef.x(),
            y: 0,
            id: +draggedPointRef.id(),
          });
        } else if (parent && draggedPointRef.y() >= parent.height()) {
          draggedPointRef.setPosition({
            x: draggedPointRef.x(),
            y: parent.height(),
            id: +draggedPointRef.id(),
          });
        }

        dragCrownPoint({
          x: draggedPointRef.x(),
          y: draggedPointRef.y(),
          id: +draggedPointRef.id(),
        });
      }
    },
    [dragCrownPoint],
  );

  return (
    <Group>
      {crownPoints.length >= 2 &&
        crownPoints.map((item, index, arr) =>
          arr[index + 1] ? (
            <Line
              key={`${item.id}`}
              points={[item.x, item.y, arr[index + 1].x, arr[index + 1].y]}
              {...crownLineConfig}
              strokeWidth={crownLineConfig.strokeWidth / zoom}
            />
          ) : (
            <Line
              key={`${item.id}`}
              points={[item.x, item.y, arr[0].x, arr[0].y]}
              {...crownLineConfig}
              strokeWidth={crownLineConfig.strokeWidth / zoom}
            />
          ),
        )}
      {crownPoints.map((item, index) => (
        <Circle
          ref={ref =>
            ref &&
            !crownPointsRef.current.includes(ref) &&
            crownPointsRef.current.push(ref!)
          }
          draggable={draggable}
          onDragStart={() => setIsDraggingPoint(true)}
          id={`${item.id}`}
          key={`${item.id}`}
          onDragMove={onDragPoint}
          onDragEnd={() => setIsDraggingPoint(false)}
          radius={crownPoint.radius / zoom}
          strokeWidth={crownPoint.strokeWidth / zoom}
          stroke={
            index === crownPoints.length - 1
              ? crownPointGenerated.stroke
              : crownPoint.stroke
          }
          fill={
            index === crownPoints.length - 1
              ? crownPointGenerated.fillColor
              : crownPoint.fillColor
          }
          x={item.x}
          y={item.y}
        />
      ))}
    </Group>
  );
};
