import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { useTreeDetailState } from '../components/treeDetailPageNew/TreeDetailProvider/TreeDetailStateProvider';
import { postAcceptAnalysis } from '../helpers/apiHelper';

export const useAcceptReport = () => {
  const { activeTree, loadTree } = useTreeDetailState();
  const { mutate: onAcceptReport, isLoading: formDataLoading } =
    useMutation(postAcceptAnalysis);

  const onAccept = useCallback(() => {
    if (activeTree) {
      onAcceptReport(
        { id: activeTree.id },
        { onSuccess: () => loadTree(activeTree.id) },
      );
    }
  }, [activeTree, onAcceptReport, loadTree]);

  return {
    onAccept,
    acceptLoading: formDataLoading,
  };
};
