import { Photo } from '../interfaces/tree';
import { useSendData } from './useSendData/useSendData';

export const useAnalysedPhoto = () => {
  const { loading, error, data, putData } = useSendData<Photo>();

  return {
    analysedPhotoLoading: loading,
    analysedPhotoError: error,
    analysedPhotoData: data,
    updateAnalysedPhoto: putData,
  };
};
