import { StyleSheet } from 'aphrodite';

export const styles = StyleSheet.create({
  radio: {
    width: '100%',
    height: 40,
    marginBottom: 50,
  },
  label: {
    marginLeft: 15,
    marginBottom: 10,
  },
});
