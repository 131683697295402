import {
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid,
} from '@material-ui/core';
import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { css } from 'aphrodite';
import { styles } from './AppOperatorSelect.styles';
import AppButton from '../Form/AppButton/AppButton';
import { OperatorDTO } from '../../../../interfaces/user';
import { useCurrentLanguage } from '../../../../translations/languageSelector';

type AppOperatorSelectProps = {
  name: string;
  options: { value: number; label: string }[];
  label: string;
  handleOperator: (value: number) => void;
  value: OperatorDTO | null;
  disabled?: boolean;
};

export const AppOperatorSelect: FC<AppOperatorSelectProps> = ({
  name,
  options,
  label,
  handleOperator,
  value,
  disabled,
}) => {
  const activeLang = useCurrentLanguage();
  const { control, setValue } = useForm();

  useEffect(() => {
    if (value) {
      setValue(name, value);
    }
  }, [value, setValue, name]);

  return (
    <form
      onSubmit={data => {
        data.preventDefault();
        handleOperator(data.target[0].value);
      }}
    >
      <Grid className={css(styles.container)}>
        <FormControl variant="outlined" className={css(styles.select)}>
          <InputLabel htmlFor={name}>{`${label}`}</InputLabel>
          <Controller
            name={name}
            control={control}
            defaultValue={value ? `${value}` : ''}
            render={({ field }) => (
              <Select
                disabled={disabled}
                variant="outlined"
                label={label}
                {...field}
                inputProps={{
                  name: `${name}`,
                }}
              >
                {options.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
        <AppButton
          variant="contained"
          color="primary"
          className={css(styles.button)}
          type="submit"
        >
          {activeLang.summaryOfProjects.buttonAssign}
        </AppButton>
      </Grid>
    </form>
  );
};
