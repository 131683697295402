import Konva from 'konva';
import { KonvaEventObject } from 'konva/types/Node';
import { useCallback, useRef } from 'react';
import { Group, Image, Layer, Stage } from 'react-konva';
import { Position } from '../../../interfaces/canvas';
import { TomogramPoint } from './TomogramPoint';

interface TomogramKonvaImageProps {
  image: HTMLImageElement | undefined;
  createPoint: (position: Position) => void;
  points: Position[];
  draggable?: boolean;
  handleDragPoint?: (index: number, newPoint: Position) => void;
  clickable?: boolean;
}

export const MAX_TOMOGRAM_SIZE = 500;

export const TomogramKonvaImage = ({
  image,
  points,
  draggable,
  clickable,
  createPoint,
  handleDragPoint,
}: TomogramKonvaImageProps) => {
  const imageGroupRef = useRef<Konva.Group>(null);

  const onLayerClick = useCallback(
    (event: KonvaEventObject<MouseEvent>) => {
      const stage = event.target._getStage();

      if (!stage || !stage.pointerPos || !imageGroupRef.current || !image) {
        return;
      }

      const stageTransform = stage.getAbsoluteTransform().copy();

      const correctedPointerPos = stageTransform.point(stage.pointerPos);

      const newPosition = {
        x: correctedPointerPos.x - imageGroupRef.current.x(),
        y: correctedPointerPos.y - imageGroupRef.current.y(),
        id: new Date().getTime(),
      };

      createPoint(newPosition);
    },
    [createPoint, image],
  );

  const pointModification = (index: number, point: Position) => {
    if (handleDragPoint) {
      handleDragPoint(index, point);
    }
  };

  const size = (() => {
    const ret = { width: 0, height: 0 };
    if (!image) {
      return ret;
    }

    ret.height =
      image.height > MAX_TOMOGRAM_SIZE ? MAX_TOMOGRAM_SIZE : image.height;
    ret.width =
      image.width > MAX_TOMOGRAM_SIZE ? MAX_TOMOGRAM_SIZE : image.width;

    return ret;
  })();

  return (
    <>
      {image && (
        <Stage width={size.width} height={size.height}>
          <Layer>
            <Group
              width={image.width}
              height={image.height}
              onClick={clickable ? onLayerClick : undefined}
              ref={imageGroupRef}
            >
              <Image image={image} width={size.width} height={size.height} />
              {points.map((point, index) => {
                return (
                  <TomogramPoint
                    key={`${point.x}${point.y}`}
                    position={point}
                    newPoint={(position: Position) => {
                      pointModification(index, position);
                    }}
                    text={(index + 1).toString()}
                    draggable={draggable}
                  />
                );
              })}
            </Group>
          </Layer>
        </Stage>
      )}
    </>
  );
};
