import { SvgIcon } from '@material-ui/core';

export const TrunkCrossSection = () => {
  return (
    <SvgIcon
      style={{ width: '100%', height: '100%', fill: '#D3DADD' }}
      viewBox="0 0 200 200"
    >
      <circle cx="100" cy="100" r="100" fill="#D3DADD" />
      <path
        d="M100.136 181.873L100.105 181.869H100.074C77.2153 181.869 58.1286 176.134 43.9878 162.978C29.8482 149.822 20.5823 129.181 17.5 99.2202C17.5136 53.6774 53.6956 26.6977 100.18 16.6108C111.708 14.1093 121.439 15.9864 129.689 20.6985C137.952 25.4179 144.757 32.9981 150.379 41.9432C161.628 59.8405 168.068 83.0777 171.997 99.363C175.891 115.506 172.275 137.969 160.491 155.482C148.727 172.964 128.837 185.498 100.136 181.873Z"
        stroke="#718A99"
        strokeOpacity="0.3"
      />
      <path
        d="M100.966 169.967L100.935 169.963H100.904C81.4056 169.963 65.1351 165.084 53.0835 153.902C41.0335 142.721 33.13 125.172 30.5 99.6836C30.5136 60.9731 61.3495 38.0267 101.01 29.4439C110.831 27.3184 119.119 28.9135 126.145 32.9154C133.183 36.9245 138.983 43.3661 143.778 50.9739C153.372 66.1966 158.866 85.9652 162.219 99.8268C165.537 113.545 162.457 132.645 152.41 147.535C142.383 162.395 125.433 173.049 100.966 169.967Z"
        stroke="#718A99"
        strokeOpacity="0.3"
      />
      <path
        d="M100.209 156.072L100.178 156.068H100.146C84.4469 156.068 71.36 152.149 61.6702 143.178C51.9822 134.208 45.6187 120.123 43.5 99.6425C43.5137 68.5842 68.3064 50.155 100.252 43.2569C108.145 41.5525 114.801 42.8318 120.442 46.0383C126.096 49.2518 130.76 54.4181 134.62 60.5281C142.343 72.7554 146.768 88.6391 149.47 99.786C152.138 110.79 149.662 126.121 141.578 138.074C133.516 149.997 119.889 158.545 100.209 156.072Z"
        stroke="#718A99"
        strokeOpacity="0.3"
      />
      <path
        d="M132.382 103.598C135.585 116.589 126.432 139.66 102.865 136.749C84.1617 136.749 71.517 127.565 69 103.598C69 85.2895 83.8729 74.4793 102.865 70.4475C121.856 66.4157 129.178 90.6066 132.382 103.598Z"
        fill="#B6C2C9"
        fillOpacity="0.4"
      />
    </SvgIcon>
  );
};
