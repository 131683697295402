import { ChartsData, InclinometerTag, InclinometersDataTag, TensilTestsDTO } from '../interfaces/tenstilTest';

export const findClosestValue = (arr: number[], selected: number) => {
  if (arr.every(item => item === null || item === 0)) {
    return null;
  }

  if (arr[selected] !== null && arr[selected] !== 0) {
    return arr[selected];
  }

  const getClosest = (
    array: (number | undefined | null)[],
    selectedIndex: number,
    originalArray: (number | undefined | null)[] = array,
    size: number = 1,
    left: number = -1,
    right: number = -1,
  ) => {
    if (size === 1) {
      return getClosest(
        [
          originalArray[selectedIndex - 1],
          array[selectedIndex],
          originalArray[selectedIndex + 1],
        ],
        selectedIndex,
        originalArray,
        size + 2,
        selectedIndex - 1,
        selectedIndex + 1,
      );
    }

    if (array.some(item => item !== null && item !== undefined && item !== 0)) {
      return array.find(
        item => item !== null && item !== undefined && item !== 0,
      );
    }

    return getClosest(
      [originalArray[left - 1], ...array, originalArray[right + 1]],
      selectedIndex,
      originalArray,
      size + 2,
      left - 1,
      right + 1,
    );
  };

  return getClosest(arr, selected);
};



export const prepareElastometersChartsData = (tensilTest: TensilTestsDTO) => {
  const availableElastometersList = tensilTest.data.availableElastometers?.map(e => e.name) || [];

  const elastometersData = tensilTest.data.inputData.map(
    input => input.elastometers.reduce(
      (acc, val, elastometerNumber) => {
        acc[availableElastometersList[elastometerNumber]] = val;
        return acc;
      },
      {
        number: input.number,
        time: input.time,
      } as ChartsData
    )
  );

  return {
    availableElastometersList,
    elastometersData,
  };
};



const getInclinometersList = (tensilTest: TensilTestsDTO) => tensilTest.data.availableInclinometers?.map(e => [
  e.name,
  ...e.partials?.map(partial => `${e.name}${partial}` as InclinometersDataTag) || []
]) || [];

const isInclinometerPartialAxis = (inclinometer: InclinometersDataTag) => !(inclinometer in InclinometerTag);



export const prepareInclinometersChartsData = (tensilTest: TensilTestsDTO) => {
  const inclinometersList = getInclinometersList(tensilTest);
  const availableInclinometersList = inclinometersList?.flat();

  const defaultVisibleInclinometersList = availableInclinometersList.map(
    inclinometer => [inclinometer, !isInclinometerPartialAxis(inclinometer)] as [InclinometersDataTag, boolean]
  );

  const inclinometersData = tensilTest.data.inputData.map(
    input => input.inclinometers?.reduce(
      (acc, val, inclinometerNumber) => {
        const currentInclinoMeter = inclinometersList[inclinometerNumber];
        acc[currentInclinoMeter[0]] = val.value;
        acc[currentInclinoMeter[1]] = val.x;
        acc[currentInclinoMeter[2]] = val.y;
        return acc;
      },
      {
        number: input.number,
        time: input.time,
      } as ChartsData
    )
  );

  return {
    availableInclinometersList,
    defaultVisibleInclinometersList,
    inclinometersData,
  };
};
