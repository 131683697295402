import {
  setProjects,
  setOperators,
  setLoadingState,
  setAssigning,
  setActiveProject,
  setActiveProjectById,
  setOperatorsToProject,
  unassignOperatorsFromProjectStore,
  setLoadingStateAssign,
} from './actions';
import { Project, Operator } from '../../interfaces/project';
import { on, reducer } from 'ts-action';
import { withObjectUpdated } from 'redux-common-helpers';
import { LoadingState } from '../../interfaces/enums';

export interface State {
  projects: Project[];
  operators: Operator[];
  mainPageLoadingState: LoadingState;
  isAssigningActive: boolean;
  activeProject: Project | undefined;
  activeProjectId: number | undefined;
  assignLoadingState: LoadingState;
}

export const initialState: State = {
  projects: [],
  operators: [],
  mainPageLoadingState: LoadingState.Loading,
  isAssigningActive: false,
  activeProject: undefined,
  activeProjectId: undefined,
  assignLoadingState: LoadingState.Initial,
};

export const projectReducer = reducer<State>(
  initialState,

  on(setProjects, (state: State, { payload }) =>
    withObjectUpdated(state, {
      projects: payload.projects,
    }),
  ),
  on(setOperators, (state: State, { payload }) =>
    withObjectUpdated(state, {
      operators: payload.operators,
    }),
  ),
  on(setLoadingState, (state: State, { payload }) =>
    withObjectUpdated(state, {
      mainPageLoadingState: payload.state,
    }),
  ),
  on(setAssigning, (state: State) =>
    withObjectUpdated(state, {
      isAssigningActive: !state.isAssigningActive,
      assignLoadingState:
        state.assignLoadingState === LoadingState.Success
          ? LoadingState.Initial
          : state.assignLoadingState,
    }),
  ),
  on(setActiveProject, (state: State, { payload }) =>
    withObjectUpdated(state, {
      activeProject: payload.actProject,
    }),
  ),
  on(setActiveProjectById, (state: State, { payload }) =>
    withObjectUpdated(state, {
      activeProjectId: payload.projectId,
    }),
  ),
  on(setOperatorsToProject, (state: State, { payload }) =>
    withObjectUpdated(state, {
      projects: state.projects.map(p =>
        p.id === payload.projectId
          ? withObjectUpdated(p, {
              operators: p.operators!.concat(payload.operators),
            })
          : p,
      ),
    }),
  ),
  on(unassignOperatorsFromProjectStore, (state: State, { payload }) =>
    withObjectUpdated(state, {
      projects: state.projects.map(p =>
        p.id === payload.projectId
          ? withObjectUpdated(p, {
              operators: p.operators!.filter(
                o =>
                  !payload.operators.find(op => op.operatorId === o.operatorId),
              ),
            })
          : p,
      ),
    }),
  ),
  on(setLoadingStateAssign, (state: State, { payload }) =>
    withObjectUpdated(state, {
      assignLoadingState: payload.state,
    }),
  ),
);
