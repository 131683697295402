import { useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import { TomogramImageProps } from '../components/treeDetailPage/Tomograms/TomogramsPreview';
import { putTreeScannerTomogram } from '../helpers/apiHelper';
import { putTrunkSlice } from '../helpers/tomogramAPI';
import { Tree } from '../interfaces/tree';

export const useTrunkSlice = (
  tree: Tree | undefined,
  tomogram: TomogramImageProps | undefined,
) => {
  const [trunkSliceData, setTrunkScanData] = useState<string>('');

  const {
    mutate: callPutTrunkSlice,
    isLoading: isPutTrunkSliceLoading,
    data: putTrunkSliceResponse,
    error: putTrunkSliceError,
  } = useMutation(putTrunkSlice);

  const trunkSlice = useCallback(
    (height: number, modelUrl: string) => {
      setTrunkScanData('');

      if (tomogram) {
        const analysisData = tomogram.tomogramAnalysisData;

        if (analysisData?.sliceURL) {
          setTrunkScanData(analysisData.sliceURL);
        } else {
          callPutTrunkSlice(
            { height: height / 100, modelUrl },
            {
              onSuccess: async ({ data }) => {
                const url = JSON.parse(data).url;
                await putTreeScannerTomogram(tomogram.id, url);
                setTrunkScanData(url);
              },
            },
          );
        }
      }
    },
    [callPutTrunkSlice, tomogram],
  );

  return {
    trunkSlice,
    isPutTrunkSliceLoading,
    putTrunkSliceResponse,
    putTrunkSliceError,
    trunkSliceData,
  };
};
