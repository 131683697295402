import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { UserRole } from '../interfaces/enums';
import { userRoleSelector } from '../store/general/selectors';

export const useRole = () => {
  const loggedUserRole = useSelector(userRoleSelector);

  const isAdmin = useMemo(
    () => loggedUserRole?.name === UserRole.TreeAdmin,
    [loggedUserRole?.name],
  );

  const isOperator = useMemo(
    () => loggedUserRole?.name === UserRole.Operator,
    [loggedUserRole?.name],
  );

  return { isAdmin, isOperator };
};
