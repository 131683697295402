import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { action, payload } from 'ts-action';
import * as Sentry from '@sentry/browser';
import { State } from '../combinedReducers';
import { Project, Operator } from '../../interfaces/project';
import { LoadingState } from '../../interfaces/enums';
import {
  getProjects,
  getOperators,
  parseStates,
  assignOperators,
  getProjectById,
} from '../../helpers/apiHelper';
import {
  OperatorResponse,
  ProjectResponse,
  AssignOperatorResponse,
} from '../../interfaces/responses';
import { setErrorMsg } from '../general/actions';
import { parseError } from '../../helpers/errorHelpers';

export enum ActionTypes {
  SET_PROJECTS = '[projects] SET_PROJECTS',
  SET_OPERATORS = '[projects] SET_OPERATORS',
  SET_LOADING_STATE = '[projects] SET_LOADING_STATE',
  SET_ASSIGNING = '[projects] SET_ASSIGNING',
  SET_ACTIVE_PROJECT = '[projects] SET_ACTIVE_PROJECT',
  GET_ACTIVE_PROJECT = '[projects] GET_ACTIVE_PROJECT',
  SET_OPERATORS_TO_PROJECT = '[projects] SET_OPERATORS_FOR_PROJECT',
  SET_ACTIVE_PROJECT_BY_ID = '[projects] SET_ACTIVE_PROJECT_BY_ID ',
  UNASSIGN_OPERATORS_FROM_PROJECT_STORE = '[projects] UNASSIGN_OPERATORS_FROM_PROJECT_STORE',
  SET_LOADING_STATE_ASSIGN = '[project] SET_LOADING_STATE_ASSIGN',
}

/**
 * Fetches all Projects
 */
export const setProjects = action(
  ActionTypes.SET_PROJECTS,
  payload<{
    projects: Project[];
  }>(),
);

export const setOperators = action(
  ActionTypes.SET_OPERATORS,
  payload<{
    operators: Operator[];
  }>(),
);

export const setLoadingState = action(
  ActionTypes.SET_LOADING_STATE,
  payload<{ state: LoadingState }>(),
);

export const setActiveProject = action(
  ActionTypes.SET_ACTIVE_PROJECT,
  payload<{ actProject: Project }>(),
);

export const setActiveProjectById = action(
  ActionTypes.SET_ACTIVE_PROJECT_BY_ID,
  payload<{ projectId: number | undefined }>(),
);

export const setOperatorsToProject = action(
  ActionTypes.SET_OPERATORS_TO_PROJECT,
  payload<{ projectId: number; operators: Operator[] }>(),
);

export const unassignOperatorsFromProjectStore = action(
  ActionTypes.UNASSIGN_OPERATORS_FROM_PROJECT_STORE,
  payload<{ projectId: number; operators: Operator[] }>(),
);

export const setLoadingStateAssign = action(
  ActionTypes.SET_LOADING_STATE_ASSIGN,
  payload<{ state: LoadingState }>(),
);

export const setAssigning = action(ActionTypes.SET_ASSIGNING);

/**
 * API call to retrieve all projects
 */
export const getProjectsData: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> = () => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      const result = await getProjects();
      const projects: Project[] = result.data.map((p: ProjectResponse) => {
        const project = {
          projectId: p.id,
          name: p.name,
          stateAnalysisOfRoad: parseStates(p.road),
          stateAnalysisOfTreeTop: parseStates(p.crown),
          stateAnalysisOfTrunk: parseStates(p.trunk),
          stateOfProject: parseStates(p.status),
          operators: p.operators.map((o: OperatorResponse) => {
            const operator: Operator = {
              operatorId: o.operator_id,
              projectOperatorId: o.project_operator_id,
              name: o.username,
              analysisOfRoad: o.analysisOfRoad,
              analysisOfTreeTop: o.analysisOfTreeTop,
              analysisOfTrunk: o.analysisOfTrunk,
              countOfDoneTrees: o.count_snapshots ? o.count_snapshots : 0,
            };
            return operator;
          }),
          countOfPhotos: p.count_photos,
          countOfTrees: p.count_snapshots,
        };
        return project;
      });
      dispatch(
        setProjects({
          projects,
        }),
      );
      dispatch(
        setLoadingState({
          state: LoadingState.Success,
        }),
      );
    } catch (err) {
      Sentry.captureException(err);
      dispatch(
        setLoadingState({
          state: LoadingState.Failure,
        }),
      );
      if (parseError(err).code === 403) {
        dispatch(
          setErrorMsg({
            errorMsg: parseError(err).message,
          }),
        );
      }
    }
  };
};

/**
 * API call to retrieve all operators
 */
export const getOperatorsData: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> = () => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      const result = await getOperators();
      const operators: Operator[] = result.data.map((o: OperatorResponse) => {
        const operator: Operator = {
          operatorId: o.id,
          name: o.username,
          analysisOfRoad: o.analysisOfRoad,
          analysisOfTreeTop: o.analysisOfTreeTop,
          analysisOfTrunk: o.analysisOfTrunk,
          countOfDoneTrees: o.count_snapshots ? o.count_snapshots : 0,
        };
        return operator;
      });
      dispatch(
        setLoadingState({
          state: LoadingState.Success,
        }),
      );
      dispatch(
        setOperators({
          operators,
        }),
      );
    } catch (err) {
      Sentry.captureException(err);
      dispatch(
        setLoadingState({
          state: LoadingState.Failure,
        }),
      );
      if (parseError(err).code === 403) {
        dispatch(
          setErrorMsg({
            errorMsg: parseError(err).message,
          }),
        );
      }
    }
  };
};

export const setOperatorsForProject: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> = (idProject: number, operators: Operator[]) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(setLoadingStateAssign({ state: LoadingState.Loading }));
      if (operators.length > 0) {
        const result = await assignOperators(
          idProject,
          operators.map(op => op.operatorId),
        );
        dispatch(setLoadingStateAssign({ state: LoadingState.Success }));
        const fetchOp: Operator[] = operators.map((operator, i) => ({
          ...operator,
          projectOperatorId: result.data.project_operators.map(
            (o: AssignOperatorResponse) => o.operator_id,
          ),
        }));
        dispatch(
          setOperatorsToProject({ projectId: idProject, operators: fetchOp }),
        );
      }
    } catch (err) {
      Sentry.captureException(err);
      dispatch(setLoadingState({ state: LoadingState.Failure }));
      if (parseError(err).code === 403) {
        dispatch(
          setErrorMsg({
            errorMsg: parseError(err).message,
          }),
        );
      }
    }
  };
};
export const unassignOperatorsFromProject: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> = (operators: Operator[], idProject: number) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      if (operators.length > 0) {
        dispatch(setLoadingStateAssign({ state: LoadingState.Loading }));
        dispatch(setLoadingStateAssign({ state: LoadingState.Success }));
        dispatch(
          unassignOperatorsFromProjectStore({
            projectId: idProject,
            operators,
          }),
        );
      }
    } catch (err) {
      Sentry.captureException(err);
      dispatch(setLoadingState({ state: LoadingState.Failure }));
      if (parseError(err).code === 403) {
        dispatch(
          setErrorMsg({
            errorMsg: parseError(err).message,
          }),
        );
      }
    }
  };
};
export const getActiveProject: ActionCreator<
  ThunkAction<Promise<void>, State, any, any>
> = (projectId: number) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(setLoadingStateAssign({ state: LoadingState.Loading }));
      const result = await getProjectById(projectId);
      dispatch(setLoadingStateAssign({ state: LoadingState.Success }));
      dispatch(setActiveProject({ actProject: result.data }));
    } catch (err) {
      Sentry.captureException(err);
      dispatch(setLoadingState({ state: LoadingState.Failure }));
      if (parseError(err).code === 403) {
        dispatch(
          setErrorMsg({
            errorMsg: parseError(err).message,
          }),
        );
      }
    }
  };
};
