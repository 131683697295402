import { FC, useState } from 'react';
import { css } from 'aphrodite';
import { Grid } from '@material-ui/core';

import { useTensileTest } from '../../../../contexts/TensilTestsContext';
import { GraphAccordions } from './GraphAccordions';
import { TensileTestData } from './TensileTestData';
import { TensileTestForm } from './TensileTestForm';
import { styles } from './TensilTests.styles';
import { TensileTestPrecalculateTables } from './TensileTestsPrecalculateTables';
import { TensileTestResultsTable } from './TensileTestResultsTable';


export const TensileTestBody: FC = () => {
  const {
    computation: {
      results: {
        precalculations,
        calculations,
      },
    }
  } = useTensileTest();

  const [isFormPinned, setPinnedForm] = useState<boolean>(false);
  const toggleFormPinned = () => setPinnedForm(!isFormPinned);

  return (
    <Grid
      container
      className={css(styles.bodyContainer)}
      justifyContent="space-between"
      style={{ flexDirection: 'column', maxWidth: 1900 }}
    >
      <Grid item md="auto" style={{ paddingBottom: 20 }}>
        <GraphAccordions />
      </Grid>

      <Grid item>
        <Grid container justifyContent="space-evenly">

          <Grid item md={9}>
            <Grid container style={{ flexDirection: 'column', gap: 10 }}>

              <Grid container style={{ flexDirection: 'column', gap: 10 }}>
                <Grid
                  item
                  className={css(styles.tensilTestCard)}
                  style={{
                    width: '100%',
                    position: isFormPinned ? 'sticky' : 'static',
                    marginBottom: isFormPinned ? 100 : 0,
                    top: 20,
                    background: 'white',
                    zIndex: 2,
                    boxShadow: isFormPinned ? '#fff 0px -20px, #0003 0px 5px 5px, #fff 0px 30px 15px 15px' : '',
                  }}
                >
                  <TensileTestForm
                    isFormPinned={isFormPinned}
                    pinFormCallback={toggleFormPinned}
                  />
                </Grid>

                <Grid item style={{ marginTop: isFormPinned ? -100 : 0 }}>
                  {precalculations && (
                    <TensileTestPrecalculateTables
                      isFormPinned={isFormPinned}
                      pinFormCallback={toggleFormPinned}
                    />
                  )}
                </Grid>
              </Grid>

              <Grid container style={{ flexDirection: 'column', gap: 10 }}>
                <Grid item>
                  {calculations && (
                    <TensileTestResultsTable />
                  )}
                </Grid>
              </Grid>

            </Grid>
          </Grid>


          <Grid
            item
            style={{ flexDirection: 'column', width: 275, height: 680, position: 'sticky', top: 20 }}
            className={css(styles.tensilTestCard)}
          >
            <TensileTestData />
          </Grid>

        </Grid>
      </Grid>

    </Grid>
  );
};
