import { Fragment, FC } from 'react';
import {
  Fab,
  Grid,
  Icon,
  Breadcrumbs,
  Link,
  makeStyles,
} from '@material-ui/core';
import { Card, Row } from 'antd';
import { css } from 'aphrodite';
import { alphabet } from '../../../../constants/map';
import { getPhotosByType } from '../../../../helpers/photoHelpers';
import { useCurrentLanguage } from '../../../../translations/languageSelector';
import { Tab } from '../../../shared/Tab';
import { useTreeDetailState } from '../../TreeDetailProvider/TreeDetailStateProvider';
import { TreeDetailActions } from '../../TreeDetailProvider/types';
import { styles } from './TreePhotos.styles';
import { DialogType } from '../../../../interfaces/enums';
import AppAlert from '../Form/AppAlert/AppAlert';
import { colors } from '../../../../styles/colors';
import { PhotoType } from '../../../../interfaces/tree';
import AppCanvas from '../AppCanvas/AppCanvas';

type TreePhotosProps = {
  photoType: PhotoType;
  dialogType: DialogType;
};

const useStyles = makeStyles({
  separator: {
    margin: 0,
  },
});

const TreePhotos: FC<TreePhotosProps> = ({ photoType, dialogType }) => {
  const {
    analysedPhotoData,
    analysedPhotoError,
    activeTree,
    dispatchTreeDetailState,
    treeDetailState: { currentPhotoTab },
  } = useTreeDetailState();
  const photos = getPhotosByType(photoType, activeTree);
  const classes = useStyles();
  const activeLang = useCurrentLanguage();

  return photos.length ? (
    <Fragment>
      <AppAlert
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!analysedPhotoError}
        message={analysedPhotoError?.message}
        autoHideDuration={1000}
        color={colors.redPrimary}
        severity="error"
      />
      <AppAlert
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!analysedPhotoData}
        message="Analýza úspěšně uložena"
        autoHideDuration={1000}
        color={colors.greenPrim}
        severity="success"
      />

      <Breadcrumbs
        className={css(styles.breadcrumb)}
        classes={{ separator: classes.separator }}
        separator=""
      >
        {activeTree &&
          photos.length > 0 &&
          photos.map((ph, index) => {
            const isSamePath = +currentPhotoTab[ph.type] === index;
            return (
              <Link
                key={index}
                className={
                  isSamePath
                    ? css(styles.breadcrumbItemActive)
                    : css(styles.breadcrumbItem)
                }
              >
                <button
                  className={
                    isSamePath
                      ? css(styles.breadcrumbLinkActive)
                      : css(styles.breadcrumbLink)
                  }
                  onClick={() => {
                    dispatchTreeDetailState({
                      type: TreeDetailActions.SET_CURRENT_PHOTO_TAB,
                      payload: {
                        ...currentPhotoTab,
                        [photoType as string]: index.toString(),
                      },
                    });
                  }}
                >
                  {index + 1}
                </button>
              </Link>
            );
          })}
      </Breadcrumbs>

      <Grid
        container
        className={css(styles.scrollbar)}
        justifyContent="space-between"
      >
        {activeTree &&
          photos.length > 0 &&
          photos.map((p, index) => {
            return (
              <Tab
                key={index}
                currentTab={
                  currentPhotoTab[photoType as 'whole' | 'trunk']
                  // Cannot cast to string as TypeScript would complain
                }
                tabName={index.toString()}
              >
                <Grid item xs={12} className={css(styles.imageBox)}>
                  <Card>
                    <div>
                      {dialogType === DialogType.TRUNK
                        ? String(alphabet[index])
                        : `${index + 1} / ${photos.length}`}
                    </div>
                    {p.analysed && (
                      <Fab
                        color="primary"
                        size="small"
                        className={css(styles.fab)}
                      >
                        <Icon>done</Icon>
                      </Fab>
                    )}
                    <AppCanvas
                      photo={{ ...p, url: p.url }}
                      dialogType={dialogType}
                      analysisLevel={activeTree.analysisLevel}
                      status={activeTree.status}
                      isTensilTestsIncluded={activeTree.tensilTests.length > 0}
                    />
                  </Card>
                </Grid>
              </Tab>
            );
          })}
      </Grid>
    </Fragment>
  ) : (
    <Row justify="center" align="middle" className={css(styles.scrollbar)}>
      {activeLang.treeDetailPage.noPhotos}
    </Row>
  );
};

export default TreePhotos;
