import { FC, useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { getRouteLink } from '../../../helpers/treeHelpers';
import { SiteTab } from '../../../interfaces/enums';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { useCurrentLanguage } from '../../../translations/languageSelector';
import { Header } from '../../shared/Header';
import { useTreeDetailState } from '../TreeDetailProvider/TreeDetailStateProvider';
import { TreeDetailActions } from '../TreeDetailProvider/types';
import TreeDetailRouter from '../TreeDetailRouter/TreeDetailRouter';

const TreeDetailPage: FC = () => {
  const {
    activeTree,
    dispatchTreeDetailState,
    treeDetailState: { currentTab },
    loadTree,
    clearActiveTree,
  } = useTreeDetailState();

  const loggedUser = useCurrentUser();
  const activeLang = useCurrentLanguage();

  const match = useRouteMatch<{ id: string }>();

  useEffect(() => {
    const treeId = Number(match.params.id);
    loadTree(treeId);
    return () => {
      clearActiveTree();
    };
  }, [loadTree, match.params.id, clearActiveTree]);

  const navButtons = useMemo(
    () => [
      {
        label: activeLang.treeDetailPage.overview,
        link: activeTree ? getRouteLink(activeTree.id) : '/',
        onClick: () => {
          dispatchTreeDetailState({
            type: TreeDetailActions.SET_CURRENT_TAB,
            payload: SiteTab.Overview,
          });
        },
        active: currentTab === SiteTab.Overview,
      },
      {
        label: activeLang.treeDetailPage.treeTopAnalysis,
        link: activeTree
          ? getRouteLink(activeTree.id, SiteTab.TreeTopAnalysis)
          : '/',
        onClick: () => {
          dispatchTreeDetailState({
            type: TreeDetailActions.SET_CURRENT_TAB,
            payload: SiteTab.TreeTopAnalysis,
          });
        },
        active: currentTab === SiteTab.TreeTopAnalysis,
      },
      {
        label: activeLang.treeDetailPage.trunkAnalysis.title,
        link: activeTree
          ? getRouteLink(activeTree.id, SiteTab.TrunkAnalysis)
          : '/',
        onClick: () => {
          dispatchTreeDetailState({
            type: TreeDetailActions.SET_CURRENT_TAB,
            payload: SiteTab.TrunkAnalysis,
          });
        },
        active: currentTab === SiteTab.TrunkAnalysis,
      },
      {
        label: activeLang.treeDetailPage.photoEditing.title,
        link: activeTree ? getRouteLink(activeTree.id, SiteTab.Photos) : '/',
        onClick: () => {
          dispatchTreeDetailState({
            type: TreeDetailActions.SET_CURRENT_TAB,
            payload: SiteTab.Photos,
          });
        },
        active: currentTab === SiteTab.Photos,
      },
      {
        label: activeLang.treeDetailPage.tensilTest.title,
        link: activeTree
          ? getRouteLink(activeTree.id, SiteTab.TensilTest)
          : '/',
        onClick: () => {
          dispatchTreeDetailState({
            type: TreeDetailActions.SET_CURRENT_TAB,
            payload: SiteTab.TensilTest,
          });
        },
        active: currentTab === SiteTab.TensilTest,
      },
      {
        label: activeLang.treeDetailPage.tomograms.title,
        link: activeTree ? getRouteLink(activeTree.id, SiteTab.Tomograms) : '/',
        onClick: () => {
          dispatchTreeDetailState({
            type: TreeDetailActions.SET_CURRENT_TAB,
            payload: SiteTab.Tomograms,
          });
        },
        active: currentTab === SiteTab.Tomograms,
      },
      {
        label: activeLang.treeDetailPage.calculations.title,
        link: activeTree
          ? getRouteLink(activeTree.id, SiteTab.Calculations)
          : '/',
        onClick: () => {
          dispatchTreeDetailState({
            type: TreeDetailActions.SET_CURRENT_TAB,
            payload: SiteTab.Calculations,
          });
        },
        active: currentTab === SiteTab.Calculations,
      },
    ],
    [activeTree, currentTab, dispatchTreeDetailState, activeLang],
  );

  const filterNavButtons = useMemo(() => {
    let newButtons = [...navButtons];
    if (activeTree?.tree_scanner_images.length === 0) {
      newButtons = newButtons.filter(btn => {
        return (
          btn.label === activeLang.treeDetailPage.overview ||
          btn.label === activeLang.treeDetailPage.calculations.title
        );
      });
    }

    if (activeTree?.tree_scanner_tomograms?.length === 0) {
      newButtons = newButtons.filter(btn => {
        return btn.label !== activeLang.treeDetailPage.tomograms.title;
      });
    }

    if (
      activeTree?.tensilTests?.length === 0 ||
      activeTree?.tensilTests.length !== activeTree?.directionOfLoad.length ||
      activeTree?.status !== 'READYFORCLIENT'
    ) {
      newButtons = newButtons.filter(btn => {
        return btn.label !== activeLang.treeDetailPage.tensilTest.title;
      });
    }

    if (
      activeTree?.trunkScanUrl === '' ||
      activeTree?.tree_scanner_tomograms?.length === 0
    ) {
      newButtons = newButtons.filter(btn => {
        return btn.label !== activeLang.treeDetailPage.tomograms.title;
      });
    }
    return newButtons;
  }, [
    activeTree?.directionOfLoad.length,
    activeTree?.status,
    activeTree?.tensilTests.length,
    activeTree?.tree_scanner_images.length,
    activeTree?.tree_scanner_tomograms?.length,
    activeTree?.trunkScanUrl,
    navButtons,
    activeLang,
  ]);

  return (
    <div>
      <Header
        loggedUser={loggedUser}
        breadcrumbs={[
          {
            label: activeLang.summaryPage.headerTitle,
            link: '/',
            onClick: () => {
              dispatchTreeDetailState({
                type: TreeDetailActions.SET_CURRENT_TAB,
                payload: SiteTab.Overview,
              });
            },
          },
          {
            label: `${activeLang.treeDetailPage.header.title} ${activeTree?.id}`,
          },
        ]}
        navButtons={activeTree ? filterNavButtons : []}
      />
      <TreeDetailRouter />
    </div>
  );
};

export default TreeDetailPage;
