import { FC, useState, useCallback } from 'react';
import { Button, Modal } from 'antd';
import { Grid } from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import { useEditor } from '../../../hooks/useEditor';
import CheckIcon from '@material-ui/icons/Check';
import CropIcon from '@material-ui/icons/Crop';
import CloseIcon from '@material-ui/icons/Close';
import { css } from 'aphrodite';
import { styles } from './ImageModal.styles';
import AppInput from '../../treeDetailPageNew/components/Form/AppInput/AppInput';
import { useCurrentLanguage } from '../../../translations/languageSelector';
import {
  greaterThanZeroRule,
  requiredRule,
} from '../../../helpers/formHelpers';
import AppSelect from '../../treeDetailPageNew/components/Form/AppSelect/AppSelect';
import {
  TomogramTypeSelectionText,
  TomogramTypeSelectionValues,
} from '../../../interfaces/tree';
import { TomogramTypeSelectionOptions } from '../../treeDetailPageNew/components/TreeDetailForm/types';

type ImageModalFormProps = {
  scaleReal: number | null;
  scaleImage: number | null;
  tomogramType: TomogramTypeSelectionValues | null;
};

const tomogramTypeOptions: TomogramTypeSelectionOptions[] = [
  {
    label: TomogramTypeSelectionText.FAKKOP,
    value: TomogramTypeSelectionValues.ONE,
  },
  {
    label: TomogramTypeSelectionText.PICUS,
    value: TomogramTypeSelectionValues.TWO,
  },
  {
    label: TomogramTypeSelectionText.ARBOTOM,
    value: TomogramTypeSelectionValues.THREE,
  },
];

type ImageModalProps = {
  url: string;
  isVisible: boolean;
  saveImg: (
    img: string,
    scaleImage: number,
    scaleReal: number,
    tomogramType: TomogramTypeSelectionValues,
  ) => Promise<void>;
  onClose: () => void;
} & ImageModalFormProps;

const ImageModal: FC<ImageModalProps> = ({
  url,
  isVisible,
  scaleImage,
  scaleReal,
  saveImg,
  onClose,
  tomogramType,
}) => {
  const activeLang = useCurrentLanguage();

  const methods = useForm<ImageModalFormProps>({
    defaultValues: {
      scaleImage: scaleImage || 0,
      scaleReal: scaleReal || 0,
      tomogramType,
    },
  });
  const { editorMemo, toggleCrop, drawingMode, applyCrop, getImage } =
    useEditor(url, css(styles.editorModal));
  const [active, setActive] = useState(false);

  const onToggleCrop = useCallback(() => {
    toggleCrop();
    if (drawingMode() === 'CROPPER') {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [toggleCrop, drawingMode]);

  const onApplyCrop = useCallback(() => {
    applyCrop();
    setActive(false);
  }, [applyCrop]);

  const onCancelCrop = useCallback(() => {
    toggleCrop();
    setActive(false);
  }, [toggleCrop]);

  const onSave = useCallback(async () => {
    const isValid = await methods.trigger([
      'scaleImage',
      'scaleReal',
      'tomogramType',
    ]);
    if (isValid) {
      const img = getImage();
      const values = methods.getValues();
      if (values.scaleImage && values.scaleReal && values.tomogramType) {
        saveImg(
          img,
          +values.scaleImage!,
          +values.scaleReal,
          values.tomogramType,
        );
      }
    }
  }, [getImage, methods, saveImg]);

  return (
    <Modal visible={isVisible} onOk={onSave} onCancel={onClose}>
      <div className={css(styles.btnWrap)}>
        <Button
          type="primary"
          block
          size="large"
          className={css(styles.btn)}
          onClick={onToggleCrop}
        >
          <CropIcon />
        </Button>
        <Button
          type="primary"
          block
          size="large"
          className={css(styles.btn)}
          onClick={onApplyCrop}
          disabled={!active}
        >
          <CheckIcon />
        </Button>
        <Button
          type="primary"
          block
          size="large"
          className={css(styles.btn)}
          onClick={onCancelCrop}
          disabled={!active}
        >
          <CloseIcon />
        </Button>
      </div>
      <Grid>{editorMemo}</Grid>
      <FormProvider {...methods}>
        <form onSubmit={onSave} className={css(styles.inputs)}>
          <AppInput
            name="scaleImage"
            showMessage
            rules={{ ...requiredRule, ...greaterThanZeroRule }}
            textFieldProps={{
              label: activeLang.tomogramsPage.inputs.scaleImage,
              type: 'number',
            }}
          />
          <AppInput
            name="scaleReal"
            showMessage
            rules={{ ...requiredRule, ...greaterThanZeroRule }}
            unit="m"
            textFieldProps={{
              label: activeLang.tomogramsPage.inputs.scaleReal,
              type: 'number',
            }}
          />
          <AppSelect
            name="tomogramType"
            options={tomogramTypeOptions.map(tom => ({
              ...tom,
              value: `${tom.value}`,
            }))}
            label={activeLang.treeDetailPage.inputs.tomogramType}
            rules={{ ...requiredRule }}
          />
        </form>
      </FormProvider>
    </Modal>
  );
};

export default ImageModal;
