import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { css } from 'aphrodite';
import { styles } from './AppSelect.styles';
import { AppSelectProps } from './types';

const AppSelect: FC<AppSelectProps> = ({
  name,
  variant = 'outlined',
  label,
  options,
  rules,
  value,
  disabled,
  required,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = errors[name];

  return (
    <FormControl variant={variant} className={css(styles.select)}>
      <InputLabel error={!!error?.message} htmlFor={name}>
        {`${label}${required ? ' *' : ''}`}
      </InputLabel>
      <Controller
        rules={rules}
        name={name}
        defaultValue={value || ''}
        control={control}
        render={({ field }) => (
          <Select
            disabled={disabled}
            variant={variant}
            error={!!error?.message}
            label={label}
            {...field}
            inputProps={{
              name: `${name}`,
            }}
          >
            <MenuItem value="" />
            {options.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      {error?.message && (
        <FormHelperText error={!!error?.message}>
          {error.message}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default AppSelect;
