import { Input } from 'antd';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { styles } from './AppTextArea.styles';
import { css } from 'aphrodite';
import { TextAreaProps } from './types';

export const AppTextArea: FC<TextAreaProps> = props => {
  const { name, rules, rows, disabled, message, required = false } = props;
  const { control } = useFormContext();

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field }) => (
          <Input.TextArea
            {...field}
            rows={rows}
            disabled={disabled}
            className={css(styles.text)}
            value={message}
            required={required}
          />
        )}
      />
    </>
  );
};
