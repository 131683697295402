import { StyleSheet } from 'aphrodite';

export const styles = StyleSheet.create({
  contentContainer: {
    marginTop: 8,
  },
  titleTop: {
    padding: 12,
  },
});
