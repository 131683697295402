import { useCallback, useMemo, useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import { css } from 'aphrodite';
import { FormProvider, useForm } from 'react-hook-form';
import { useRouteMatch } from 'react-router-dom';
import { DialogType, SiteTab } from '../../../interfaces/enums';
import { PhotoType } from '../../../interfaces/tree';
import { colors, textColors } from '../../../styles/colors';
import { fontsWeights } from '../../../styles/variables';
import { useCurrentLanguage } from '../../../translations/languageSelector';
import { downloadBtnStyle, styles } from './TrunkTreePage.styles';
import TreePhotos from '../components/TreePhotos/TreePhotos';
import { useTreeDetailState } from '../TreeDetailProvider/TreeDetailStateProvider';
import AppAlert from '../components/Form/AppAlert/AppAlert';
import AppInput from '../components/Form/AppInput/AppInput';
import AppButton from '../components/Form/AppButton/AppButton';
import { TreeDetailActions } from '../TreeDetailProvider/types';
import Add from '@material-ui/icons/Add';
import Image from '@material-ui/icons/Image';

const TrunkTreePage = () => {
  const activeLang = useCurrentLanguage();

  const match = useRouteMatch<{ id: string }>();
  const {
    activeTree,
    trunkScanError,
    trunkScanResponse,
    trunkScanLoading,
    trunkScanImageData,
    trunkScanImageLoading,
    trunkScanImageError,
    sendTrunkScan,
    sendTrunkScanImage,
    updateTreeForm,
    formDataResponse,
    formDataError,
    dispatchTreeDetailState,
  } = useTreeDetailState();
  const { treeTop } = activeLang.treeDetailPage.treeTopPart;
  const isAnalysisEnabled =
    activeTree?.analysisLevel &&
    (activeTree.analysisLevel.toString() === '3' ||
      activeTree.analysisLevel.toString() === '4');
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDialog = (value: boolean) => {
    setIsDialogOpen(value);
  };

  const methods = useForm({
    defaultValues: {
      trunkScanHeight:
        activeTree?.tree_scanner_analysis_data?.trunkScanHeight || 0,
    },
  });
  const trunkScanLink = useMemo(() => {
    let link: string;

    if (trunkScanResponse) {
      link = trunkScanResponse.trunkScan.url;
    } else if (activeTree?.trunkScanUrl) {
      link = activeTree.trunkScanUrl;
    } else {
      link = '';
    }
    return link;
  }, [activeTree?.trunkScanUrl, trunkScanResponse]);

  const trunkScanName = useMemo(() => {
    let file = '';
    if (activeTree?.trunkScanUrl) {
      const index = activeTree.trunkScanUrl.lastIndexOf('/');
      file = activeTree.trunkScanUrl.slice(index + 1);
    }
    return file;
  }, [activeTree?.trunkScanUrl]);

  const trunkScanScreenUrl = useMemo(() => {
    let url = '';
    if (trunkScanImageData) {
      url = trunkScanImageData?.trunkScanScreen?.url || '';
    } else if (activeTree?.tree_scanner_analysis_data?.trunkScanScreen) {
      url = activeTree.tree_scanner_analysis_data.trunkScanScreen.url;
    }
    return url;
  }, [activeTree, trunkScanImageData]);

  const trunkScanScreenName = useMemo(() => {
    let name = '';
    if (trunkScanImageData) {
      name = trunkScanImageData?.trunkScanScreen?.name || '';
    } else if (activeTree?.tree_scanner_analysis_data?.trunkScanScreen) {
      name = activeTree.tree_scanner_analysis_data.trunkScanScreen.name;
    }
    return name;
  }, [activeTree, trunkScanImageData]);

  const onSubmit = useCallback(
    (values: { trunkScanHeight: number }) => {
      if (activeTree?.tree_scanner_analysis_data?.id) {
        updateTreeForm(
          { trunkScanHeight: values.trunkScanHeight },
          activeTree?.tree_scanner_analysis_data?.id!,
        );
      }
    },
    [activeTree?.tree_scanner_analysis_data?.id, updateTreeForm],
  );

  useEffect(() => {
    dispatchTreeDetailState({
      type: TreeDetailActions.SET_CURRENT_TAB,
      // @ts-ignore
      payload: SiteTab.TrunkAnalysis,
    });
  }, [dispatchTreeDetailState]);

  return (
    <Grid
      container
      className={css(styles.contentContainer)}
      justifyContent="space-between"
    >
      <AppAlert
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!trunkScanError}
        message={trunkScanError?.message}
        autoHideDuration={2000}
        color={colors.redPrimary}
        severity="error"
      />
      <AppAlert
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!formDataError}
        message={formDataError?.message}
        autoHideDuration={2000}
        color={colors.redPrimary}
        severity="error"
      />
      <AppAlert
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!trunkScanResponse}
        message="Sken kmene byl úspěšně nahrán."
        autoHideDuration={2000}
        color={colors.greenPrim}
        severity="success"
      />
      <AppAlert
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!trunkScanImageData}
        message="Snímek skenu kmene byl úspěšně nahrán."
        autoHideDuration={2000}
        color={colors.greenPrim}
        severity="success"
      />
      <AppAlert
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!trunkScanImageError}
        message={trunkScanImageError?.message}
        autoHideDuration={2000}
        color={colors.redPrimary}
        severity="error"
      />
      <AppAlert
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!formDataResponse}
        message="Výška skenu kmene byla úspěšně uložena."
        autoHideDuration={2000}
        color={colors.greenPrim}
        severity="success"
      />

      <Dialog open={isDialogOpen} onClose={() => handleDialog(false)}>
        <DialogContent>
          <Grid>
            <img
              className={css(styles.photo)}
              src={trunkScanScreenUrl}
              alt="trunkScanScreen"
            />
          </Grid>
        </DialogContent>
      </Dialog>

      <Grid container xs={12}>
        {isAnalysisEnabled && (
          <>
            <Typography
              variant="subtitle1"
              className={css(
                textColors.textDark,
                fontsWeights.bold,
                styles.titleTop,
              )}
            >
              {activeLang.treeDetailPage.trunkAnalysis.trunkScan}
            </Typography>
            <Grid container className={css(styles.card)}>
              {activeTree.trunkScanUrl && (
                <Grid container className={css(styles.trunScanFile)}>
                  <span>Aktuální soubor:</span>
                  <span> {trunkScanName}</span>
                </Grid>
              )}
              {trunkScanScreenName && (
                <Grid container className={css(styles.trunScanFile)}>
                  <span>Aktuální snímek skenu kmene: </span>
                  <span>{trunkScanScreenName}</span>
                </Grid>
              )}
              <Grid container className={css(styles.trunkScanGrid)}>
                <Button
                  variant="outlined"
                  component="a"
                  href={trunkScanLink}
                  // @ts-ignore
                  target="_blank"
                  className={css(
                    downloadBtnStyle(trunkScanLink !== '').downloadBtn,
                  )}
                  disabled={!trunkScanLink}
                  style={{
                    pointerEvents: trunkScanLink !== '' ? 'all' : 'none',
                  }}
                >
                  {
                    activeLang.treeDetailPage.photoEditing.form
                      .downloadTrunkScan
                  }
                </Button>
                <input
                  accept="application/zip"
                  style={{ display: 'none' }}
                  id="outlined-button-file"
                  disabled={trunkScanLoading}
                  type="file"
                  onChange={event => {
                    if (event.target.files && match.params.id) {
                      sendTrunkScan(event.target.files[0]);
                    }
                  }}
                />
                <label htmlFor="outlined-button-file">
                  <Button
                    variant="outlined"
                    component="span"
                    disabled={trunkScanLoading}
                    className={css(styles.button)}
                  >
                    <Add style={{ paddingRight: 4 }} />
                    {
                      activeLang.treeDetailPage.photoEditing.form
                        .uploadTrunkScan
                    }
                  </Button>
                </label>

                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="outlined-button-image"
                  disabled={trunkScanImageLoading}
                  type="file"
                  onChange={event => {
                    if (event.target.files) {
                      sendTrunkScanImage(event.target.files[0]);
                    }
                  }}
                />
                <label htmlFor="outlined-button-image">
                  <Button
                    variant="outlined"
                    component="span"
                    disabled={trunkScanImageLoading}
                    className={css(styles.button)}
                  >
                    <Image style={{ paddingRight: 4 }} />
                    {
                      activeLang.treeDetailPage.photoEditing.form
                        .uploadTrunkScanImage
                    }
                  </Button>
                </label>

                {(trunkScanImageData ||
                  activeTree.tree_scanner_analysis_data?.trunkScanScreen) && (
                    <Button variant="outlined" onClick={() => handleDialog(true)}>
                      Zobrazit snímek
                    </Button>
                  )}

                <FormProvider {...methods}>
                  <form
                    className={css(styles.formWrap)}
                    onSubmit={methods.handleSubmit(onSubmit)}
                  >
                    <AppInput
                      name="trunkScanHeight"
                      textFieldProps={{
                        label: activeLang.treeDetailPage.trunkAnalysis.height,
                      }}
                      size="small"
                      wrapped={false}
                    />
                    <AppButton
                      variant="contained"
                      color="primary"
                      type="submit"
                      size="small"
                    >
                      {activeLang.treeDetailPage.trunkAnalysis.save}
                    </AppButton>
                  </form>
                </FormProvider>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <Grid container className={css(styles.trunkGrid)}>
        <Typography
          variant="subtitle1"
          className={css(
            textColors.textDark,
            fontsWeights.bold,
            styles.titleTop,
          )}
        >
          {treeTop}
        </Typography>
        <TreePhotos photoType={PhotoType.Trunk} dialogType={DialogType.TRUNK} />
      </Grid>
    </Grid>
  );
};

export default TrunkTreePage;
