import {
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { colors } from '../../../styles/colors';

interface HeaderWithButtonProps {
  title: string;
  buttonText: string;
  disabled?: boolean;
  loading?: boolean;
  error?: unknown;
  onClick: () => void;
}

export interface StyleProps {
  isButtonError: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  headerButton: {
    backgroundColor: ({ isButtonError }) =>
      `${isButtonError ? colors.redPrimary : colors.white}`,
    color: ({ isButtonError }) => `${isButtonError ? colors.white : 'black'}`,
  },
}));

export const HeaderWithButton = ({
  title,
  buttonText,
  loading,
  disabled,
  error,
  onClick,
}: HeaderWithButtonProps) => {
  const stylesProps = {
    isButtonError: Boolean(error),
  };
  const classes = useStyles(stylesProps);

  return (
    <>
      <Grid container direction="row" spacing={2} alignItems="flex-end">
        <Grid item>
          <Typography variant="h4">{title}</Typography>
        </Grid>
        <Grid item>
          {loading ? (
            <CircularProgress />
          ) : (
            <Button
              disabled={disabled}
              variant="outlined"
              onClick={onClick}
              className={classes.headerButton}
            >
              {buttonText}
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};
