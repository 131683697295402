import { StyleSheet } from 'aphrodite';
import { colors } from '../../../../styles/colors';

export const styles = StyleSheet.create({
  inputGroup: {
    width: '100%',
    display: 'flex',
    marginBottom: 10,
  },
  selectGroup: {
    width: '100%',
    marginBottom: 30,
    display: 'flex',
  },
  radioGroup: {
    width: '100%',
    marginBottom: 30,
    display: 'flex',
  },
  refuseButton: {
    backgroundColor: colors.redPrimary,
    color: colors.white,
  },
  disabled: {
    backgroundColor: 'rgb(218 119 119)',
    color: colors.white,
  },
});
