import { StyleSheet } from 'aphrodite';

export const colors = {
  white: '#FFFFFF',
  greenPrimary: '#009577',
  greenSecondary: '#15AF0A',
  redPrimary: '#BC1313',
  containerBackground: '#F4F6FB',
  tableRow: '#ECF4F2',
  textDark: '#343434',
  grey: '#D5D5D5',
  grayBtn: '#bfbfbf',
  lightBlue: '#2476ec',
  tensilTest: {
    force: '#464646',
    meter1: '#2F80ED',
    meter2: '#F2994A',
    meter3: '#CF91F9',
    meter4: '#FF56C2',
  },
  mapElements: {
    loadDirectionPoint: '#14af09',
    loadDirection: '#fbfbfb',
    typeTrunk: '#0067f7',
    trunkPoint: '#434eab',
    typeCrown: '#df0001',
    crownPoint: '#d45549',
    structurePoint: '#f87211',
    structureLine: '#ef731b',
  },
  yellow: '#FFFF00',
  darkBlue: '#005495',
  purple: '#683F90',
  peach: '#FFEBD9',
  greenPrim: '#739E2E',
};

export const textColors = StyleSheet.create({
  primaryGreen: {
    color: colors.greenPrimary,
  },
  white: {
    color: colors.white,
  },
  textDark: {
    color: colors.textDark,
  },
  darkBlue: {
    color: colors.darkBlue,
  },
  primaryRed: {
    color: colors.redPrimary,
  },
});

export const buttonsColor = {
  defaultButton: {
    main: colors.greenPrimary,
    contrastText: colors.white,
  },
  secondaryButton: {
    main: colors.greenPrimary,
    contrastText: colors.white,
  },
  redButton: {
    main: colors.redPrimary,
    contrastText: colors.white,
  },
  error: {
    main: colors.redPrimary,
  },
};
