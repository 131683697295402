import { RcFile } from 'antd/lib/upload';
import { useCallback, useState } from 'react';
import { instance } from '../../helpers/apiHelper';
import { parseError } from '../../helpers/errorHelpers';
import { ErrorType, UseSendDataReturnType } from './types';

export const useSendData = <T = any>(): UseSendDataReturnType<T> => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ErrorType | null>(null);

  const postData = useCallback((url: string, dataToSend: T) => {
    setLoading(true);
    setData(null);
    setError(null);
    const sendData = async () => {
      try {
        const response = await instance.post<T>(url, dataToSend);
        setData(response.data);
      } catch (error) {
        setError(parseError(error));
      } finally {
        setLoading(false);
      }
    };
    sendData();
  }, []);

  const putData = useCallback((url: string, dataToSend: Partial<T>) => {
    setLoading(true);
    setData(null);
    setError(null);
    const sendData = async () => {
      try {
        const response = await instance.put<T>(url, dataToSend);
        setData(response.data);
      } catch (error) {
        setError(parseError(error));
      } finally {
        setLoading(false);
      }
    };
    sendData();
  }, []);

  const putFile = useCallback(
    (url: string, dataToSend: string | Blob | RcFile, filename: string) => {
      setLoading(true);
      setData(null);
      setError(null);
      const sendFile = async () => {
        const formData = new FormData();
        formData.append(`files.${filename}`, dataToSend);
        formData.append('data', JSON.stringify(''));
        try {
          const response = await instance.put<T>(url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          setData(response.data);
        } catch (error) {
          setError(parseError(error));
        } finally {
          setLoading(false);
        }
      };

      sendFile();
    },
    [],
  );
  const postFile = useCallback(
    (
      url: string,
      dataToSend: string | Blob | RcFile,
      filename: string,
      additionalData: any,
    ) => {
      setLoading(true);
      setData(null);
      setError(null);
      const sendFile = async () => {
        const formData = new FormData();
        formData.append(`files.${filename}`, dataToSend);
        formData.append('data', JSON.stringify(additionalData));
        try {
          const response = await instance.post<T>(url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          setData(response.data);
        } catch (error) {
          setError(parseError(error));
        } finally {
          setLoading(false);
        }
      };

      sendFile();
    },
    [],
  );

  return {
    data,
    loading,
    error,
    postData,
    putData,
    putFile,
    postFile,
  };
};
