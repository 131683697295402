import {
  ButtonGroup,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  makeStyles,
} from '@material-ui/core';
import { FC, useState } from 'react';
import { useCurrentLanguage } from '../../../../translations/languageSelector';
import AppButton from '../../../treeDetailPageNew/components/Form/AppButton/AppButton';
import { styles } from '../CalculationsTab.styles';
import { css } from 'aphrodite';
import { AppTextArea } from '../../../treeDetailPageNew/components/Form/AppTextArea/AppTextArea';

const useStyles = makeStyles({
  paper: {
    width: 420,
    position: 'fixed',
    margin: 0,
  },
});

type ConfirmationDialogProps = {
  handleClose: () => void;
  isOpen: boolean;
  isLoading: boolean;
};

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  handleClose,
  isOpen,
  isLoading,
}) => {
  const activeLang = useCurrentLanguage();
  const classes = useStyles();
  const [isConfirmed, setIsConfirmed] = useState(false);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle>
        {
          activeLang.treeDetailPage.calculations
            .sendToCustomerConfirmationDialogTitle
        }
      </DialogTitle>
      {!isConfirmed ? (
        <DialogContent className={css(styles.dialogContent)}>
          {
            activeLang.treeDetailPage.calculations
              .sendToCustomerConfirmationDialogText1
          }
        </DialogContent>
      ) : (
        <DialogContent className={css(styles.dialogContent)}>
          {
            activeLang.treeDetailPage.calculations
              .sendToCustomerConfirmationDialogText2
          }
          <AppTextArea rows={4} name="rejectedMessage" required />
        </DialogContent>
      )}

      <DialogActions>
        <ButtonGroup>
          <AppButton
            fullWidth
            color="primary"
            variant="contained"
            type="button"
            onClick={() => {
              handleClose();
              setIsConfirmed(false);
            }}
          >
            {activeLang.treeDetailPage.calculations.cancel}
          </AppButton>
          {!isConfirmed ? (
            <AppButton
              fullWidth
              variant="contained"
              type="button"
              className={css(styles.rejectButton)}
              onClick={event => {
                event.preventDefault();
                setIsConfirmed(true);
              }}
            >
              {activeLang.treeDetailPage.calculations.sendMessage}
            </AppButton>
          ) : (
            <AppButton
              fullWidth
              disabled={isLoading}
              variant="contained"
              type="submit"
              form="calculations-reject-message-form"
              className={css(styles.rejectButton)}
            >
              {activeLang.treeDetailPage.calculations.confirm}
            </AppButton>
          )}
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
};
