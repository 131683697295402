import { MuiThemeProvider } from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import 'antd/dist/antd.css';
import Cookies from 'js-cookie';
import React, { ErrorInfo, Fragment } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ConfirmEmail } from './components/confirmEmail/ConfirmEmail';
import { ForgotPassword } from './components/forgotPassword/ForgotPassword';
import { LoginPage } from './components/loginPage/LoginPage';
import { ProjectDetailCont } from './components/projectDetailPage/ProjectDetailCont';
import TreeDetailPage from './components/treeDetailPageNew/TreeDetailPage/TreeDetailPage';
import { TreeDetailStateProvider } from './components/treeDetailPageNew/TreeDetailProvider/TreeDetailStateProvider';
import { COOKIE_NAME } from './constants/constants';
import { UnreadNotificationsCountProvider } from './contexts/UnreadNotificationsCountContext';
import { LoadingState } from './interfaces/enums';
import { UserDTO } from './interfaces/user';
import { FinishedOrderListPage } from './pages/FinishedOrdersListPage/FinishedOrderListPage';
import { FinishedOrderDetailPage } from './pages/FinishedOrderDetailPage/FinishedOrderDetailPage';
import { defaultTheme } from './styles/palette';

const queryClient = new QueryClient();
export interface StateProps {
  loggedUser: UserDTO | undefined;
  requestState: LoadingState;
}
export interface DispatchProps {
  checkLogin: (userName: string, password: string) => void;
  logOut: () => void;
  callRefreshUser: () => void;
}

type AppProps = DispatchProps & StateProps;

export class App extends React.Component<AppProps> {
  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      scope.setExtra('componentStack', errorInfo.componentStack);
      Sentry.captureException(error);
    });
  }

  public componentDidMount() {
    if (Cookies.get(COOKIE_NAME)) this.props.callRefreshUser();
  }

  public render() {
    return (
      <div className="App">
        <QueryClientProvider client={queryClient} contextSharing={true}>
          <MuiThemeProvider theme={defaultTheme}>
            <Router>
              <Switch>
                {this.props.loggedUser ? (
                  <Fragment>
                    <UnreadNotificationsCountProvider>
                      <Route
                        exact={true}
                        path="/"
                        component={ProjectDetailCont}
                      />
                      <Route
                        path="/projectDetail/:id/"
                        component={ProjectDetailCont}
                        exact={true}
                      />
                      <TreeDetailStateProvider>
                        <Route
                          path="/treeDetail/:id/"
                          component={TreeDetailPage}
                        />
                      </TreeDetailStateProvider>
                    </UnreadNotificationsCountProvider>
                    <Route
                      path="/finished"
                      component={FinishedOrderListPage}
                      exact
                    />
                    <Route
                      path="/finished/:id"
                      component={FinishedOrderDetailPage}
                      exact
                    />
                  </Fragment>
                ) : (
                  <Route
                    path="/"
                    render={props => (
                      <LoginPage
                        {...props}
                        checkLogin={this.props.checkLogin}
                        requestState={this.props.requestState}
                        logOut={this.props.logOut}
                        loggedUser={this.props.loggedUser}
                      />
                    )}
                    exact={true}
                  />
                )}
                <Route
                  path="/forgot-password/:token"
                  component={ForgotPassword}
                />
                <Route path="/email-confirm" component={ConfirmEmail} />
              </Switch>
            </Router>
          </MuiThemeProvider>
        </QueryClientProvider>
      </div>
    );
  }
}
