import Konva from 'konva';
import { KonvaEventObject } from 'konva/types/Node';
import { FC, useCallback, useMemo, useRef } from 'react';
import { Circle, Group, Line } from 'react-konva';
import { isBaseAndTop } from '../../../../../helpers/canvasHelpers';
import { Position } from '../../../../../interfaces/canvas';
import {
  crownPoint,
  crownPointGenerated,
} from '../../../../../styles/canvasStyles';

type TrunkProps = {
  trunkPoints: Position[];
  zoom: number;
  setIsDraggingPoint: (isDraggingPoint: boolean) => void;
  dragTrunkPoint: (point: Position) => void;
  draggable?: boolean;
};

export const Trunk: FC<TrunkProps> = ({
  trunkPoints,
  zoom,
  setIsDraggingPoint,
  dragTrunkPoint,
  draggable,
}) => {
  const trunkPointsRef = useRef<Konva.Circle[]>([]);

  const onDragPoint = useCallback(
    (evt: KonvaEventObject<DragEvent>) => {
      const draggedPointRef = trunkPointsRef.current.find(
        ref => ref.id() === evt.target.attrs.id,
      );
      if (draggedPointRef) {
        const draggedTrunkPoint = trunkPoints.find(
          item => item.id === +draggedPointRef.id(),
        );
        if (
          draggedTrunkPoint?.key === 'base' ||
          draggedTrunkPoint?.key === 'top'
        ) {
          draggedPointRef.y(draggedTrunkPoint.y);
        }
        const parent = draggedPointRef.parent?.parent;

        if (draggedPointRef.x() <= 0) {
          draggedPointRef.setPosition({
            x: 0,
            y: draggedPointRef.y(),
            id: +draggedPointRef.id(),
          });
        } else if (parent && draggedPointRef.x() >= parent.width()) {
          draggedPointRef.setPosition({
            x: parent.width(),
            y: draggedPointRef.y(),
            id: +draggedPointRef.id(),
          });
        }

        if (draggedPointRef.y() <= 0) {
          draggedPointRef.setPosition({
            x: draggedPointRef.x(),
            y: 0,
            id: +draggedPointRef.id(),
          });
        } else if (parent && draggedPointRef.y() >= parent.height()) {
          draggedPointRef.setPosition({
            x: draggedPointRef.x(),
            y: parent.height(),
            id: +draggedPointRef.id(),
          });
        }

        dragTrunkPoint({
          x: draggedPointRef.x(),
          y: draggedPointRef.y(),
          id: +draggedPointRef.id(),
          key: draggedTrunkPoint?.key,
        });
      }
    },
    [dragTrunkPoint, trunkPoints],
  );

  const lines = useMemo(() => {
    return trunkPoints.map((item, index, arr) => {
      if (arr[index + 1]) {
        if (
          (arr[index + 1].key === 'base' && item.key === 'base') ||
          (arr[index + 1].key === 'top' && item.key === 'top')
        ) {
          return (
            <Line
              key={item.id}
              strokeWidth={2 / zoom}
              stroke="red"
              points={[item.x, item.y, arr[index + 1].x, arr[index + 1].y]}
            />
          );
        } else {
          return (
            <Line
              key={item.id}
              strokeWidth={2 / zoom}
              stroke="white"
              points={[item.x, item.y, arr[index + 1].x, arr[index + 1].y]}
            />
          );
        }
      } else if (isBaseAndTop(arr)) {
        return (
          <Line
            strokeWidth={2 / zoom}
            stroke="white"
            points={[item.x, item.y, arr[0].x, arr[0].y]}
          />
        );
      }
      return null;
    });
  }, [trunkPoints, zoom]);

  return (
    <Group>
      {lines}

      {trunkPoints.map((item, index) => (
        <Circle
          ref={ref =>
            ref &&
            !trunkPointsRef.current.includes(ref) &&
            trunkPointsRef.current.push(ref!)
          }
          draggable={draggable}
          onDragStart={() => setIsDraggingPoint(true)}
          id={`${item.id}`}
          key={`${item.id}`}
          onDragMove={onDragPoint}
          onDragEnd={() => setIsDraggingPoint(false)}
          radius={5 / zoom}
          strokeWidth={2 / zoom}
          stroke={
            index === trunkPoints.length - 1
              ? crownPointGenerated.stroke
              : crownPoint.stroke
          }
          fill={item.key === 'top' || item.key === 'base' ? 'red' : 'green'}
          x={item.x}
          y={item.y}
        />
      ))}
    </Group>
  );
};
