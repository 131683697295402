import { StyleSheet } from 'aphrodite';

export const styles = StyleSheet.create({
  container: {
    display: 'flex',
    // justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: 10,
  },
  select: {
    width: '100%',
    height: 40,
    paddingRight: 10,
  },
  button: {
    height: 56,
    padding: '0px 30px',
  },
});
