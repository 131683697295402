import { Position } from '../interfaces/canvas';
import { apiCoreInstance } from './apiHelper';

interface PutTrunkSliceProps {
  height: number;
  modelUrl: string;
}

export const putTrunkSlice = async (data: PutTrunkSliceProps) => {
  return apiCoreInstance.put('/trunk-slice', data);
};

export interface PutTomogramDeformationProps {
  sliceDescription: PutTrunkSliceProps;
  slicePoints: Position[];
  tomogramPoints: Position[];
  tomogramUrl: string;
  tomogramNorthPoint: number;
}

export const tomogramDeformation = async (
  data: PutTomogramDeformationProps,
) => {
  return apiCoreInstance.put('/tomogram-deformation', data);
};
